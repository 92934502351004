import i18nIsoCountries from "i18n-iso-countries"
import {Address, createResource, FullName, getResource, Phone, updateResource, uploadResource} from "./common"
import {removeEmptyObjectAttributes} from "../utilities/object"
import countriesList from "i18n-iso-countries/langs/en.json"
import * as Sentry from "@sentry/react"
import i18n from "../i18n"

export type ApplicationFormUrl = {
	attributes: {
		url: string
	}
}

export type CddEddBeneficialOwner = {
	id: string
	fullName: FullName
	nationality?: string
	occupation?: string
	annualIncome?: string
	sourceOfIncome?: string
}

type CddEddAttributes = {
	nationality?: string
	occupation?: string
	annualIncome?: string
	sourceOfIncome?: string
	businessVertical?: string
	annualRevenue?: string
	numberOfEmployees?: string
	yearOfIncorporation?: string
	stateOfIncorporation?: string
	countriesOfOperation?: string[]
	cashFlow?: string
	website?: string
	hasNoWebsite?: boolean

	officer?: {
		fullName: FullName
		nationality?: string
		occupation?: string
		annualIncome?: string
		sourceOfIncome?: string
	}

	beneficialOwners?: CddEddBeneficialOwner[]
}

export type ApplicationForm = {
	type: "applicationFormUi"
	id: string
	attributes: {
		cddEddAttributes?: CddEddAttributes
		customerRiskRate?: "high" | "medium" | "low"
		requireBusinessEntityType?: boolean
		email?: string
		url: string
		stage: ApplicationFormStage
		tags: {[key: string]: string}
		orgName: string
		applicantDetails?: ApplicantDetails
		applicationFormSettings: ApplicationFormSettings
		bankName: string
		status: ApplicationFormStatus
		applicationFormDocuments: Array<ApplicationDocument>
		allowedApplicationTypes?: Array<ApplicationFormType>
		lang: string
		settingsOverride?: SettingsOverride
		hideApplicationProgressTracker?: boolean
		forcePrefill?: boolean
		disableRegulatoryValidations?: boolean
		dataCollectionStatus?: "Created" | "Opened" | "Completed"
		state: {
			chooseBusinessOrIndividual?: {
				applicationFormType?: ApplicationFormType
			}
			enterIndividualInformation?: {
				fullName?: FullName
				ssn?: string
				passport?: string
				nationality?: string
				dateOfBirth?: string
				email?: string
				dba?: string
				ein?: string
				industry?: Industry
				address?: Address
				phone: Phone
				occupation?: string
				annualIncome?: string
				sourceOfIncome?: string
			}
			enterSoleProprietorshipBusinessInformation?: {
				businessVertical?: string
				annualRevenue?: string
				numberOfEmployees?: string
				dba?: string
				ein?: string
				website?: string
			}
			enterBusinessInformation: {
				businessName?: string
				stateOfIncorporation?: string
				entityType?: BusinessEntityType
				industry?: Industry
				website?: string
				dba?: string
				ein?: string
				address?: Address
				phone?: Phone
				businessVertical?: string
			}
			enterBusinessAdditionalInformation: {
				entityType?: BusinessEntityType
				yearOfIncorporation?: string
				stateOfIncorporation?: string
				hasNonUsEntities?: boolean
				annualRevenue?: string
				numberOfEmployees?: string
				countriesOfOperation?: string[]
				cashFlow?: string
				stockSymbol?: string
			}
			enterOfficerInformation?: {
				businessContact: {
					email: string
					fullName: FullName
					phone: Phone
				}
				officer: {
					address: Address
					dateOfBirth: string
					email: string
					fullName: FullName
					phone: Phone
					ssn?: string
					passport?: string
					nationality?: string
					title: string
					occupation?: string
					annualIncome?: string
					sourceOfIncome?: string
				}
				officerIsBeneficialOwner?: boolean
			}
		}
	}
	relationships: {
		org: {
			data: {
				type: "org"
				id: number
			}
		}
		application?: {
			data: {
				id: number
				type: "application"
			}
		}
	}
}

export enum ApplicationFormType {
	Individual = "Individual",
	SoleProprietorship = "SoleProprietorship",
	Business = "Business",
}

export enum ApplicationFormStage {
	Initial = "Initial",
	ChooseBusinessOrIndividual = "ChooseBusinessOrIndividual",

	EnterIndividualInformation = "EnterIndividualInformation",
	IndividualSubmit = "IndividualSubmit",
	IndividualApplicationCreated = "IndividualApplicationCreated",

	EnterSoleProprietorshipInformation = "EnterSoleProprietorshipInformation",
	EnterSoleProprietorshipBusinessInformation = "EnterSoleProprietorshipBusinessInformation",
	SoleProprietorshipApplicationCreated = "SoleProprietorshipApplicationCreated",

	EnterBusinessInformation = "EnterBusinessInformation",
	EnterBusinessAdditionalInformation = "EnterBusinessAdditionalInformation",
	EnterOfficerInformation = "EnterOfficerInformation",
	EnterBeneficialOwnersInformation = "EnterBeneficialOwnersInformation",
	BusinessSubmit = "BusinessSubmit",
	BusinessApplicationCreated = "BusinessApplicationCreated",
	IndividualPhoneVerification = "IndividualPhoneVerification",
	BusinessPhoneVerification = "BusinessPhoneVerification",
	SoleProprietorshipPhoneVerification = "SoleProprietorshipPhoneVerification",
}

export const ApplicationFormStageDetails: {
	[ApplicationFormStage: string]: {title: string; showInStagesIndicator: boolean; canGoBackTo: boolean}
} = {
	[ApplicationFormStage.Initial]: {
		title: "",
		showInStagesIndicator: false,
		canGoBackTo: false,
	},
	[ApplicationFormStage.ChooseBusinessOrIndividual]: {
		title: i18n.t("applicationType.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterIndividualInformation]: {
		title: i18n.t("individualInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterSoleProprietorshipInformation]: {
		title: i18n.t("individualInformation.soleProprietorInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterSoleProprietorshipBusinessInformation]: {
		title: i18n.t("soleProprietorshipBusinessInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterBusinessInformation]: {
		title: i18n.t("businessInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterBusinessAdditionalInformation]: {
		title: i18n.t("businessAdditionalInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterOfficerInformation]: {
		title: i18n.t("officerInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: true,
	},
	[ApplicationFormStage.EnterBeneficialOwnersInformation]: {
		title: i18n.t("beneficialOwnersInformation.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
	[ApplicationFormStage.IndividualSubmit]: {
		title: "Submit",
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
	[ApplicationFormStage.BusinessSubmit]: {
		title: "Submit",
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
	[ApplicationFormStage.IndividualApplicationCreated]: {
		title: i18n.t("applicationCreated.pageTitle"),
		showInStagesIndicator: false,
		canGoBackTo: false,
	},
	[ApplicationFormStage.SoleProprietorshipApplicationCreated]: {
		title: i18n.t("applicationCreated.pageTitle"),
		showInStagesIndicator: false,
		canGoBackTo: false,
	},
	[ApplicationFormStage.BusinessApplicationCreated]: {
		title: i18n.t("applicationCreated.pageTitle"),
		showInStagesIndicator: false,
		canGoBackTo: false,
	},
	[ApplicationFormStage.IndividualPhoneVerification]: {
		title: i18n.t("phoneVerification.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
	[ApplicationFormStage.SoleProprietorshipPhoneVerification]: {
		title: i18n.t("phoneVerification.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
	[ApplicationFormStage.BusinessPhoneVerification]: {
		title: i18n.t("phoneVerification.pageTitle"),
		showInStagesIndicator: true,
		canGoBackTo: false,
	},
}

export const individualFormStages = [
	ApplicationFormStage.ChooseBusinessOrIndividual,
	ApplicationFormStage.EnterIndividualInformation,
	// ApplicationFormStage.EnterIndividualAdditionalInformation,
	ApplicationFormStage.IndividualPhoneVerification,
	ApplicationFormStage.IndividualApplicationCreated,
]

export const soleProprietorshipFormStages = [
	ApplicationFormStage.ChooseBusinessOrIndividual,
	ApplicationFormStage.EnterSoleProprietorshipInformation,
	ApplicationFormStage.EnterSoleProprietorshipBusinessInformation,
	ApplicationFormStage.SoleProprietorshipPhoneVerification,
	ApplicationFormStage.SoleProprietorshipApplicationCreated,
]

export const businessFormStages = [
	ApplicationFormStage.ChooseBusinessOrIndividual,
	ApplicationFormStage.EnterBusinessInformation,
	ApplicationFormStage.EnterBusinessAdditionalInformation,
	ApplicationFormStage.EnterOfficerInformation,
	ApplicationFormStage.BusinessPhoneVerification,
	ApplicationFormStage.EnterBeneficialOwnersInformation,
	ApplicationFormStage.BusinessApplicationCreated,
]

export type BusinessEntityType =
	| "PubliclyTradedCorporation"
	| "PrivatelyHeldCorporation"
	| "Corporation"
	| "LLC"
	| "Partnership"
export type Industry =
	| "Retail"
	| "Wholesale"
	| "Restaurants"
	| "Hospitals"
	| "Construction"
	| "Insurance"
	| "Unions"
	| "RealEstate"
	| "FreelanceProfessional"
	| "OtherProfessionalServices"
	| "OnlineRetailer"
	| "OtherEducationServices"

export type BusinessContact = {
	fullName: FullName
	email: string
	phone: Phone
}

export type Officer = {
	fullName: FullName
	ssn?: string
	passport?: string
	nationality?: string
	address: Address
	dateOfBirth: string
	email: string
	phone: Phone
	title?: string
	occupation?: string
	annualIncome?: string
	sourceOfIncome?: string
}

export type BeneficialOwner = {
	fullName: FullName
	ssn?: string
	passport?: string
	nationality?: string
	address: Address
	dateOfBirth: string
	email: string
	phone: Phone
	percentage?: number
	occupation?: string
	annualIncome?: string
	sourceOfIncome?: string
}

export type ApplicantDetails = {
	applicationType?: ApplicationFormType
	fullName?: FullName
	ssn?: string
	nationality?: string
	passport?: string
	dateOfBirth?: string
	email?: string
	name?: string
	stateOfIncorporation?: string
	entityType?: BusinessEntityType
	industry?: Industry
	contact?: Partial<BusinessContact>
	officer?: Partial<Officer>
	beneficialOwners?: Partial<BeneficialOwner>[]
	website?: string
	dba?: string
	ein?: string
	address?: Address
	phone?: Phone
	officerIsBeneficialOwner?: boolean
	occupation?: string
	annualIncome?: string
	sourceOfIncome?: string
	businessVertical?: string
	annualRevenue?: string
	numberOfEmployees?: string
	cashFlow?: string
	yearOfIncorporation?: string
	countriesOfOperation?: string[]
	stockSymbol?: string
	hasNonUsEntities?: boolean
}

export type AdditionalDisclosure = {title: string; url: string}

export type ApplicationFormSettings = {
	applicationFormCustomCss?: string
	applicationFormLogo?: string
	applicationFormFavicon?: string
	applicationFormBrandName?: string
	applicationFormRedirectUrl?: string
	applicationFormRedirectButtonText?: string
	applicationFormPrivacyPolicyUrl?: string
	applicationFormElectronicDisclosuresUrl?: string
	applicationFormDepositTermsUrl?: string
	applicationFormClientTermsUrl?: string
	applicationFormCardholderTermsUrl?: string
	applicationFormCashAdvancedTermsUrl?: string
	applicationFormDebitCardDisclosureUrl?: string
	applicationFormContactUsEmail?: string
	applicationFormAdditionalDisclosures?: Array<AdditionalDisclosure>
	applicationFormValidatePhoneNumber?: boolean
}

export type SettingsOverride = {
	redirectUrl?: string
	redirectButtonText?: string
	privacyPolicyUrl?: string
	electronicDisclosuresUrl?: string
	depositTermsUrl?: string
	clientTermsUrl?: string
	cardholderTermsUrl?: string
	cashAdvancedTermsUrl?: string
	debitCardDisclosureUrl?: string
	additionalDisclosures?: Array<AdditionalDisclosure>
}

export const usStates: Record<string, string> = {
	AL: "Alabama",
	AK: "Alaska",
	AS: "American Samoa",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District Of Columbia",
	FM: "Federated States Of Micronesia",
	FL: "Florida",
	GA: "Georgia",
	GU: "Guam",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MH: "Marshall Islands",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	MP: "Northern Mariana Islands",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	PW: "Palau",
	PA: "Pennsylvania",
	PR: "Puerto Rico",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VI: "Virgin Islands",
	VA: "Virginia",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
}

export const caStates: Record<string, string> = {
	AB: "Alberta",
	BC: "British Columbia",
	MB: "Manitoba",
	NB: "New Brunswick",
	NL: "Newfoundland and Labrador",
	NT: "Northwest Territories",
	NS: "Nova Scotia",
	NU: "Nunavut",
	ON: "Ontario",
	PE: "Prince Edward Island",
	QC: "Quebec",
	SK: "Saskatchewan",
	YT: "Yukon Territory",
}

export const getOccupations = (): Record<string, string> => ({
	ArchitectOrEngineer: i18n.t("individualAdditionalInformation.form.occupationsList.architectOrEngineer"),
	BusinessAnalystAccountantOrFinancialAdvisor: i18n.t(
		"individualAdditionalInformation.form.occupationsList.businessAnalystAccountantOrFinancialAdvisor"
	),
	CommunityAndSocialServicesWorker: i18n.t(
		"individualAdditionalInformation.form.occupationsList.communityAndSocialServicesWorker"
	),
	ConstructionMechanicOrMaintenanceWorker: i18n.t(
		"individualAdditionalInformation.form.occupationsList.constructionMechanicOrMaintenanceWorker"
	),
	Doctor: i18n.t("individualAdditionalInformation.form.occupationsList.doctor"),
	Educator: i18n.t("individualAdditionalInformation.form.occupationsList.educator"),
	EntertainmentSportsArtsOrMedia: i18n.t(
		"individualAdditionalInformation.form.occupationsList.entertainmentSportsArtsOrMedia"
	),
	ExecutiveOrManager: i18n.t("individualAdditionalInformation.form.occupationsList.executiveOrManager"),
	FarmerFishermanForester: i18n.t("individualAdditionalInformation.form.occupationsList.farmerFishermanForester"),
	FoodServiceWorker: i18n.t("individualAdditionalInformation.form.occupationsList.foodServiceWorker"),
	GigWorker: i18n.t("individualAdditionalInformation.form.occupationsList.gigWorker"),
	HospitalityOfficeOrAdministrativeSupportWorker: i18n.t(
		"individualAdditionalInformation.form.occupationsList.hospitalityOfficeOrAdministrativeSupportWorker"
	),
	HouseholdManager: i18n.t("individualAdditionalInformation.form.occupationsList.householdManager"),
	JanitorHousekeeperLandscaper: i18n.t(
		"individualAdditionalInformation.form.occupationsList.janitorHousekeeperLandscaper"
	),
	Lawyer: i18n.t("individualAdditionalInformation.form.occupationsList.lawyer"),
	ManufacturingOrProductionWorker: i18n.t(
		"individualAdditionalInformation.form.occupationsList.manufacturingOrProductionWorker"
	),
	MilitaryOrPublicSafety: i18n.t("individualAdditionalInformation.form.occupationsList.militaryOrPublicSafety"),
	NurseHealthcareTechnicianOrHealthcareSupport: i18n.t(
		"individualAdditionalInformation.form.occupationsList.nurseHealthcareTechnicianOrHealthcareSupport"
	),
	PersonalCareOrServiceWorker: i18n.t(
		"individualAdditionalInformation.form.occupationsList.personalCareOrServiceWorker"
	),
	PilotDriverOperator: i18n.t("individualAdditionalInformation.form.occupationsList.pilotDriverOperator"),
	SalesRepresentativeBrokerAgent: i18n.t(
		"individualAdditionalInformation.form.occupationsList.salesRepresentativeBrokerAgent"
	),
	ScientistOrTechnologist: i18n.t("individualAdditionalInformation.form.occupationsList.scientistOrTechnologist"),
	Student: i18n.t("individualAdditionalInformation.form.occupationsList.student"),
})

export const getAnnualIncomes = (): Record<string, string> => ({
	UpTo10k: i18n.t("individualAdditionalInformation.form.annualIncomesList.upTo10k"),
	Between10kAnd25k: i18n.t("individualAdditionalInformation.form.annualIncomesList.between10kAnd25k"),
	Between25kAnd50k: i18n.t("individualAdditionalInformation.form.annualIncomesList.between25kAnd50k"),
	Between50kAnd100k: i18n.t("individualAdditionalInformation.form.annualIncomesList.between50kAnd100k"),
	Between100kAnd250k: i18n.t("individualAdditionalInformation.form.annualIncomesList.between100kAnd250k"),
	Over250k: i18n.t("individualAdditionalInformation.form.annualIncomesList.over250k"),
})

export const getSourcesOfIncome = (): Record<string, string> => ({
	EmploymentOrPayrollIncome: i18n.t(
		"individualAdditionalInformation.form.sourcesOfIncomeList.employmentOrPayrollIncome"
	),
	PartTimeOrContractorIncome: i18n.t(
		"individualAdditionalInformation.form.sourcesOfIncomeList.partTimeOrContractorIncome"
	),
	InheritancesAndGifts: i18n.t("individualAdditionalInformation.form.sourcesOfIncomeList.inheritancesAndGifts"),
	PersonalInvestments: i18n.t("individualAdditionalInformation.form.sourcesOfIncomeList.personalInvestments"),
	BusinessOwnershipInterests: i18n.t(
		"individualAdditionalInformation.form.sourcesOfIncomeList.businessOwnershipInterests"
	),
	GovernmentBenefits: i18n.t("individualAdditionalInformation.form.sourcesOfIncomeList.governmentBenefits"),
})

export const getBusinessVerticals = (): Record<string, string> => ({
	AdultEntertainmentDatingOrEscortServices: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.adultEntertainmentDatingOrEscortServices"
	),
	AgricultureForestryFishingOrHunting: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.agricultureForestryFishingOrHunting"
	),
	ArtsEntertainmentAndRecreation: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.artsEntertainmentAndRecreation"
	),
	BusinessSupportOrBuildingServices: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.businessSupportOrBuildingServices"
	),
	Cannabis: i18n.t("businessAdditionalInformation.form.businessVerticalsList.cannabis"),
	Construction: i18n.t("businessAdditionalInformation.form.businessVerticalsList.construction"),
	DirectMarketingOrTelemarketing: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.directMarketingOrTelemarketing"
	),
	EducationalServices: i18n.t("businessAdditionalInformation.form.businessVerticalsList.educationalServices"),
	FinancialServicesCryptocurrency: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.financialServicesCryptocurrency"
	),
	FinancialServicesDebitCollectionOrConsolidation: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.financialServicesDebitCollectionOrConsolidation"
	),
	FinancialServicesMoneyServicesBusinessOrCurrencyExchange: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.financialServicesMoneyServicesBusinessOrCurrencyExchange"
	),
	FinancialServicesOther: i18n.t("businessAdditionalInformation.form.businessVerticalsList.financialServicesOther"),
	FinancialServicesPaydayLending: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.financialServicesPaydayLending"
	),
	GamingOrGambling: i18n.t("businessAdditionalInformation.form.businessVerticalsList.gamingOrGambling"),
	HealthCareAndSocialAssistance: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.healthCareAndSocialAssistance"
	),
	HospitalityAccommodationOrFoodServices: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.hospitalityAccommodationOrFoodServices"
	),
	LegalAccountingConsultingOrComputerProgramming: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.legalAccountingConsultingOrComputerProgramming"
	),
	Manufacturing: i18n.t("businessAdditionalInformation.form.businessVerticalsList.manufacturing"),
	Mining: i18n.t("businessAdditionalInformation.form.businessVerticalsList.mining"),
	Nutraceuticals: i18n.t("businessAdditionalInformation.form.businessVerticalsList.nutraceuticals"),
	PersonalCareServices: i18n.t("businessAdditionalInformation.form.businessVerticalsList.personalCareServices"),
	PublicAdministration: i18n.t("businessAdditionalInformation.form.businessVerticalsList.publicAdministration"),
	RealEstate: i18n.t("businessAdditionalInformation.form.businessVerticalsList.realEstate"),
	ReligiousCivicAndSocialOrganizations: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.religiousCivicAndSocialOrganizations"
	),
	RepairAndMaintenance: i18n.t("businessAdditionalInformation.form.businessVerticalsList.repairAndMaintenance"),
	RetailTrade: i18n.t("businessAdditionalInformation.form.businessVerticalsList.retailTrade"),
	TechnologyMediaOrTelecom: i18n.t("businessAdditionalInformation.form.businessVerticalsList.technologyMediaOrTelecom"),
	TransportationOrWarehousing: i18n.t(
		"businessAdditionalInformation.form.businessVerticalsList.transportationOrWarehousing"
	),
	Utilities: i18n.t("businessAdditionalInformation.form.businessVerticalsList.utilities"),
	WholesaleTrade: i18n.t("businessAdditionalInformation.form.businessVerticalsList.wholesaleTrade"),
})

export const getSoleProprietorshipAnnualRevenues = (): Record<string, string> => ({
	UpTo50k: i18n.t("businessAdditionalInformation.form.soleProprietorshipAnnualRevenuesList.upTo50k"),
	Between50kAnd100k: i18n.t(
		"businessAdditionalInformation.form.soleProprietorshipAnnualRevenuesList.between50kAnd100k"
	),
	Between100kAnd200k: i18n.t(
		"businessAdditionalInformation.form.soleProprietorshipAnnualRevenuesList.between100kAnd200k"
	),
	Between200kAnd500k: i18n.t(
		"businessAdditionalInformation.form.soleProprietorshipAnnualRevenuesList.between200kAnd500k"
	),
	Over500k: i18n.t("businessAdditionalInformation.form.soleProprietorshipAnnualRevenuesList.over500k"),
})

export const getBusinessAnnualRevenues = (): Record<string, string> => ({
	UpTo250k: i18n.t("businessAdditionalInformation.form.businessAnnualRevenuesList.upTo250k"),
	Between250kAnd500k: i18n.t("businessAdditionalInformation.form.businessAnnualRevenuesList.between250kAnd500k"),
	Between500kAnd1m: i18n.t("businessAdditionalInformation.form.businessAnnualRevenuesList.between500kAnd1m"),
	Between1mAnd5m: i18n.t("businessAdditionalInformation.form.businessAnnualRevenuesList.between1mAnd5m"),
	Over5m: i18n.t("businessAdditionalInformation.form.businessAnnualRevenuesList.over5m"),
})

export const getSoleProprietorshipNumberOfEmployees = (): Record<string, string> => ({
	One: i18n.t("businessAdditionalInformation.form.soleProprietorshipNumberOfEmployeesList.one"),
	Between2And5: i18n.t("businessAdditionalInformation.form.soleProprietorshipNumberOfEmployeesList.between2And5"),
	Between5And10: i18n.t("businessAdditionalInformation.form.soleProprietorshipNumberOfEmployeesList.between5And10"),
	Over10: i18n.t("businessAdditionalInformation.form.soleProprietorshipNumberOfEmployeesList.over10"),
})

export const getBusinessNumberOfEmployees = (): Record<string, string> => ({
	UpTo10: i18n.t("businessAdditionalInformation.form.businessNumberOfEmployeesList.upTo10"),
	Between10And50: i18n.t("businessAdditionalInformation.form.businessNumberOfEmployeesList.between10And50"),
	Between50And100: i18n.t("businessAdditionalInformation.form.businessNumberOfEmployeesList.between50and100"),
	Between100And500: i18n.t("businessAdditionalInformation.form.businessNumberOfEmployeesList.between100and500"),
	Over500: i18n.t("businessAdditionalInformation.form.businessNumberOfEmployeesList.over500"),
})

export const getCashFlowOptions = (): Record<string, string> => ({
	Unpredictable: i18n.t("businessAdditionalInformation.form.cashFlowList.unpredictable"),
	Predictable: i18n.t("businessAdditionalInformation.form.cashFlowList.predictable"),
})

i18nIsoCountries.registerLocale(countriesList)
export const countries: {[countryCode: string]: string} = i18nIsoCountries.getNames("en")

export const hotjarSetup = {id: 2517028, version: 6}

export type AddressType = "Individual" | "Business"

export enum ApplicationFormStatus {
	Entering = "Entering",
	AwaitingDocuments = "AwaitingDocuments",
	PendingReview = "PendingReview",
	Approved = "Approved",
	Denied = "Denied",
	Canceled = "Canceled",
	Pending = "Pending",
}

export type ApplicationDocument = {
	type: "document"
	id: string
	attributes: {
		documentType: string
		status: ApplicationDocumentStatus
		passport: string
		ssn: string
		nationality: string
		name: string
		address: Address
		dateOfIncorporation: Date
		ein: string
		dateOfBirth: Date
		frontDocumentId?: string
		backDocumentId?: string
		evaluationId?: string
		reason?: string
		reasonCode?: string
		description?: string
	}
}

export enum ApplicationDocumentSide {
	Front,
	Back,
}

export enum ApplicationDocumentStatus {
	Required = "Required",
	ReceivedBack = "ReceivedBack",
	ReceivedFront = "ReceivedFront",
	Invalid = "Invalid",
	Approved = "Approved",
	PendingReview = "PendingReview",
}

export type CustomerTokenVerification = {
	type: "customerTokenVerification"
	id: string
	attributes: {
		verificationToken: string
	}
}

export async function getApplicationForm(token: string) {
	return await getResource<ApplicationForm>(`application-forms/t/${token}`, "", {})
}

function getDeviceFingerprint() {
	if (typeof ioGetBlackbox === "function") {
		try {
			const iovation = ioGetBlackbox()
			return [
				{
					provider: "iovation",
					value: iovation?.blackbox,
				},
			]
		} catch (e) {
			Sentry.captureException(e)
			return null
		}
	}
	return null
}

export async function updateApplicationForm(requestType = "applicationForm", token: string, state: object = {}) {
	const skipFields = []
	if (requestType == "applicationFormCddEddAttributes") {
		skipFields.push("website")
	}
	state = removeEmptyObjectAttributes(state, skipFields)
	const deviceFingerprints = getDeviceFingerprint()
	state = deviceFingerprints ? Object.assign({deviceFingerprints}, state) : state

	return await updateResource<ApplicationForm>(`application-forms/t/${token}`, "", {
		type: requestType,
		attributes: {
			...state,
		},
	})
}

export async function uploadDocument(
	token: string,
	documentId: string,
	documentFile: any,
	documentType: string | null,
	documentSide: ApplicationDocumentSide
) {
	return await uploadResource<any>(
		`application-forms/t/${token}/upload-document/${documentId}${
			documentSide == ApplicationDocumentSide.Back ? "/back" : ""
		}`,
		"",
		documentFile,
		documentType
	)
}

export async function sendVerification(token: string) {
	return await createResource<CustomerTokenVerification>("application-forms/sendVerification", "", {
		token,
	})
}

export async function createDefaultApplicationForm() {
	return await createResource<ApplicationFormUrl>("application-forms/new", "", {})
}

export async function markDataCollectionViewed(token: string) {
	return await createResource<undefined>(`application-forms/data-collection/${token}/viewed`, "", {})
}
