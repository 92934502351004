import React from "react"
import {
	ApplicantDetails,
	ApplicationForm,
	usStates,
	countries,
	getBusinessAnnualRevenues,
	getCashFlowOptions,
	getBusinessNumberOfEmployees,
} from "../../resources/applicationForm"
import {useTranslation} from "react-i18next"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import Dropdown from "../../components/Dropdown"
import Button from "../../components/Button"
import VisaNotice from "../../components/ApplicationForm/VisaNotice"
import Textfield from "../../components/Textfield"
import {US_COUNTRY_CODE} from "../../resources/common"
import MultiSelectionCombobox, {MultiSelectionComboboxOption} from "../../components/MultiSelectionCombobox"
import SingleSelection from "../../components/SingleSelection"
import {yearRegex} from "../../utilities/validation"
import {isUndefined} from "lodash"

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	isRequestInProgress: boolean
	applicantDetails?: ApplicantDetails
}

export default function BusinessAdditionalInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	applicantDetails,
}: Props) {
	const {t} = useTranslation()
	const businessAnnualRevenues = getBusinessAnnualRevenues()
	const cashFlowOptions = getCashFlowOptions()
	const businessNumberOfEmployees = getBusinessNumberOfEmployees()
	const requireBusinessEntityType = applicationForm.attributes?.requireBusinessEntityType ?? true

	const validationSchema = Yup.object().shape({
		entityType: Yup.string().when([], {
			is: () => requireBusinessEntityType,
			then: Yup.string()
				.oneOf(
					[
						"Corporation",
						"PubliclyTradedCorporation",
						"PrivatelyHeldCorporation",
						"LLC",
						"Partnership",
						"NotForProfitOrganization",
					],
					t("validationErrorMessages.businessEntityType.invalid")
				)
				.required(t("validationErrorMessages.businessEntityType.required")),
		}),
		annualRevenue: Yup.string()
			.oneOf(Object.keys(businessAnnualRevenues), t("validationErrorMessages.annualRevenue.invalid"))
			.when("hasNonUsEntities", {
				is: (hasNonUsEntities: boolean) => hasNonUsEntities && !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.annualRevenue.required")),
			}),
		numberOfEmployees: Yup.string()
			.oneOf(Object.keys(businessNumberOfEmployees), t("validationErrorMessages.numberOfEmployees.invalid"))
			.when("hasNonUsEntities", {
				is: (hasNonUsEntities: boolean) => hasNonUsEntities && !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.numberOfEmployees.required")),
			}),
		cashFlow: Yup.string().when("hasNonUsEntities", {
			is: (hasNonUsEntities: boolean) => hasNonUsEntities && !applicationForm.attributes.disableRegulatoryValidations,
			then: Yup.string().required(t("validationErrorMessages.cashFlow.required")),
		}),
		yearOfIncorporation: Yup.string()
			.matches(yearRegex, t("validationErrorMessages.yearOfIncorporation.invalid"))
			.when([], {
				is: () => !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.yearOfIncorporation.required")),
			}),
		hasNonUsEntities: Yup.boolean().required(t("validationErrorMessages.hasNonUsEntities.required")),
		countriesOfOperation: Yup.array()
			.of(Yup.string())
			.when("hasNonUsEntities", {
				is: (hasNonUsEntities: boolean) => hasNonUsEntities && !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.array()
					.required(t("validationErrorMessages.countriesOfOperation.required"))
					.min(1, t("validationErrorMessages.countriesOfOperation.required")),
			}),
		// stockSymbol: Yup.string().required(t("validationErrorMessages.stockSymbol.required")),
		stateOfIncorporation: Yup.string().when("country", {
			is: US_COUNTRY_CODE,
			then: Yup.string().required(t("validationErrorMessages.stateOfIncorporation.required")),
		}),
	})

	const stateBusinessAdditionalInformation = applicationForm.attributes.state?.enterBusinessAdditionalInformation

	const annualRevenuePrefill =
		applicantDetails?.annualRevenue && applicantDetails.annualRevenue in businessAnnualRevenues
			? applicantDetails?.annualRevenue
			: undefined
	const numberOfEmployeesPrefill =
		applicantDetails?.numberOfEmployees && applicantDetails.numberOfEmployees in businessNumberOfEmployees
			? applicantDetails.numberOfEmployees
			: undefined

	const initialFormValues = {
		entityType: stateBusinessAdditionalInformation?.entityType ?? applicantDetails?.entityType ?? "",
		stockSymbol: stateBusinessAdditionalInformation?.stockSymbol ?? applicantDetails?.stockSymbol ?? "",
		hasNonUsEntities: stateBusinessAdditionalInformation?.hasNonUsEntities ?? applicantDetails?.hasNonUsEntities ?? "",
		yearOfIncorporation:
			stateBusinessAdditionalInformation?.yearOfIncorporation ?? applicantDetails?.yearOfIncorporation ?? "",
		annualRevenue: stateBusinessAdditionalInformation?.annualRevenue ?? annualRevenuePrefill ?? "",
		numberOfEmployees: stateBusinessAdditionalInformation?.numberOfEmployees ?? numberOfEmployeesPrefill ?? "",
		cashFlow: stateBusinessAdditionalInformation?.cashFlow ?? applicantDetails?.cashFlow ?? "",
		countriesOfOperation:
			stateBusinessAdditionalInformation?.countriesOfOperation ?? applicantDetails?.countriesOfOperation ?? [],
		stateOfIncorporation:
			stateBusinessAdditionalInformation?.stateOfIncorporation ?? applicantDetails?.stateOfIncorporation ?? "",
	}

	const prefillExistsForField = {
		entityType: !isUndefined(applicantDetails?.entityType),
		stockSymbol: !isUndefined(applicantDetails?.stockSymbol),
		hasNonUsEntities: !isUndefined(applicantDetails?.hasNonUsEntities),
		yearOfIncorporation: !isUndefined(applicantDetails?.yearOfIncorporation),
		annualRevenue: !isUndefined(applicantDetails?.annualRevenue),
		numberOfEmployees: !isUndefined(applicantDetails?.numberOfEmployees),
		cashFlow: !isUndefined(applicantDetails?.cashFlow),
		countriesOfOperation: !isUndefined(applicantDetails?.countriesOfOperation),
		stateOfIncorporation: !isUndefined(applicantDetails?.stateOfIncorporation),
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormBusinessAdditionalInformation", {
			entityType: requireBusinessEntityType ? values.entityType : undefined,
			yearOfIncorporation: values.yearOfIncorporation || null,
			hasNonUsEntities: values.hasNonUsEntities,
			stateOfIncorporation: values.stateOfIncorporation,
			...(values.entityType === "PubliclyTradedCorporation"
				? {
						stockSymbol: values.stockSymbol,
				  }
				: {}),
			...(values.hasNonUsEntities
				? {
						annualRevenue: values.annualRevenue || null,
						numberOfEmployees: values.numberOfEmployees || null,
						countriesOfOperation: values.countriesOfOperation.length > 0 ? values.countriesOfOperation : null,
						cashFlow: values.cashFlow || null,
				  }
				: {}),
		})
	}

	return (
		<div className="individual-additional-information">
			<h1>{t("businessAdditionalInformation.pageTitle")}</h1>
			<p>{t("businessAdditionalInformation.pageDescription")}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleChange, errors, isValid, handleBlur, setFieldValue}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<Textfield
							name="yearOfIncorporation"
							label={t("businessAdditionalInformation.form.yearOfIncorporation")}
							value={values.yearOfIncorporation}
							onChange={handleChange}
							maxLength={4}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.yearOfIncorporation}
							disabled={isForcePrefill && prefillExistsForField.yearOfIncorporation}
						/>

						<Dropdown
							name="stateOfIncorporation"
							label={t("businessAdditionalInformation.form.stateOfIncorporation")}
							value={values.stateOfIncorporation}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.stateOfIncorporation}
							disabled={isForcePrefill && prefillExistsForField.stateOfIncorporation}
						>
							<option value=""></option>
							{Object.keys(usStates).map((state) => (
								<option key={state} value={state}>
									{usStates[state]}
								</option>
							))}
						</Dropdown>
						{requireBusinessEntityType ? (
							<>
								<Dropdown
									name="entityType"
									label={t("businessInformation.form.entityType")}
									value={values.entityType}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.entityType}
									disabled={isForcePrefill && prefillExistsForField.entityType}
								>
									<option key="default" defaultValue="" />
									<option value="PrivatelyHeldCorporation">
										{t("businessInformation.form.entities.privatelyHeldCorporation")}
									</option>
									<option value="PubliclyTradedCorporation">
										{t("businessInformation.form.entities.publiclyTradedCorporation")}
									</option>
									<option value="LLC">{t("businessInformation.form.entities.llc")}</option>
									<option value="NotForProfitOrganization">
										{t("businessInformation.form.entities.notForProfitOrganization")}
									</option>
									<option value="Partnership">{t("businessInformation.form.entities.partnership")}</option>
								</Dropdown>

								{values.entityType === "PubliclyTradedCorporation" && (
									<Textfield
										name="stockSymbol"
										label={t("businessAdditionalInformation.form.stockSymbol")}
										value={values.stockSymbol}
										onChange={handleChange}
										onBlur={handleBlur}
										alwaysShowMask={false}
										maxLength={5}
										hasPrefill={prefillExistsForField.stockSymbol}
										disabled={isForcePrefill && prefillExistsForField.stockSymbol}
									/>
								)}
							</>
						) : undefined}

						<Dropdown
							name="hasNonUsEntities"
							label={t("businessAdditionalInformation.form.hasNonUsEntities")}
							value={values.hasNonUsEntities.toString()}
							onChange={(e) => {
								setFieldValue("hasNonUsEntities", e.target.value === "true")
							}}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.hasNonUsEntities}
							disabled={isForcePrefill && prefillExistsForField.hasNonUsEntities}
						>
							<option value=""></option>
							<option value={"false"}>{t("no")}</option>
							<option value={"true"}>{t("yes")}</option>
						</Dropdown>

						{values.hasNonUsEntities && (
							<>
								<Dropdown
									name={"cashFlow"}
									label={t("businessAdditionalInformation.form.cashFlow")}
									value={values.cashFlow}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.cashFlow}
									disabled={isForcePrefill && prefillExistsForField.cashFlow}
								>
									<option value=""></option>
									{Object.keys(cashFlowOptions).map((cashFlow) => (
										<option key={cashFlow} value={cashFlow}>
											{cashFlowOptions[cashFlow]}
										</option>
									))}
								</Dropdown>

								<SingleSelection
									name={"annualRevenue"}
									label={t("businessAdditionalInformation.form.annualRevenue")}
									value={values.annualRevenue}
									setFieldValue={setFieldValue}
									options={businessAnnualRevenues}
									hasPrefill={prefillExistsForField.annualRevenue}
									disabled={isForcePrefill && prefillExistsForField.annualRevenue}
								/>

								<SingleSelection
									name={"numberOfEmployees"}
									label={t("businessAdditionalInformation.form.numberOfEmployees")}
									value={values.numberOfEmployees}
									setFieldValue={setFieldValue}
									options={businessNumberOfEmployees}
									hasPrefill={prefillExistsForField.numberOfEmployees}
									disabled={isForcePrefill && prefillExistsForField.numberOfEmployees}
								/>

								<MultiSelectionCombobox
									name={"countriesOfOperation"}
									label={t("businessAdditionalInformation.form.countriesOfOperation")}
									value={values.countriesOfOperation}
									options={Object.keys(countries).map((countryKey) => {
										return {value: countryKey, label: countries[countryKey], isFixed: false}
									})}
									setFieldValue={(selectedOptions: MultiSelectionComboboxOption[]) => {
										setFieldValue(
											"countriesOfOperation",
											selectedOptions.map((option) => option.value)
										)
									}}
									hasPrefill={prefillExistsForField.countriesOfOperation}
									disabled={isForcePrefill && prefillExistsForField.countriesOfOperation}
								/>
							</>
						)}

						<Button type="submit" isLoading={isRequestInProgress}>
							{t("businessAdditionalInformation.form.continue")}
						</Button>
						{applicationForm.attributes.applicationFormSettings?.applicationFormDebitCardDisclosureUrl ? (
							<VisaNotice applicationForm={applicationForm} />
						) : null}
					</form>
				)}
			</Formik>
		</div>
	)
}
