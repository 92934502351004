import React from "react"
import {ApplicationForm, ApplicationFormStageDetails} from "../../resources/applicationForm"
import {applicationFormStages, allowedOneApplicationType} from "../../resources/common"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import {useTranslation} from "react-i18next"

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	validatePhoneNumber: boolean
}

export default function BackButton({applicationForm, updateApplicationForm, validatePhoneNumber}: Props) {
	const {t} = useTranslation()
	const applicationFormStage = applicationForm.attributes.stage
	const stages = applicationFormStages(applicationForm, validatePhoneNumber)
	const currentStageIndex = stages.indexOf(applicationFormStage)
	const isFirstStep = currentStageIndex <= 0
	const isLastStep = currentStageIndex == stages.length - 1
	const isSecondStepAndAllowedOneApplicationType = currentStageIndex == 1 && allowedOneApplicationType(applicationForm)
	const isDisabled = isFirstStep || isLastStep || isSecondStepAndAllowedOneApplicationType

	function returnToPreviousPage() {
		if (isDisabled) {
			return
		}

		for (let i = currentStageIndex - 1; i >= 0; i--) {
			if (ApplicationFormStageDetails[stages[i]].canGoBackTo) {
				updateApplicationForm("applicationFormUpdateStage", {stage: stages[i]})
				return
			}
		}
	}

	return (
		<button className={classNames("back-button", isDisabled && "disabled")} onClick={returnToPreviousPage}>
			<span className="icon">
				<FontAwesomeIcon icon={faArrowLeft} />
			</span>
			<span>{t("previousStep")}</span>
		</button>
	)
}
