import React, {Dispatch, InputHTMLAttributes, SetStateAction} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import ReactTooltip from "react-tooltip"
import OtpInput from "@unit-finance/react-otp-input"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	value: string
	onChange: Dispatch<SetStateAction<any>>
}

export default function Verification({name, value, onChange}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames("textfield", touched.hasOwnProperty(name) && errors.hasOwnProperty(name) && "error")

	return (
		<div className={className}>
			<OtpInput
				value={value}
				onChange={onChange}
				numInputs={6}
				containerStyle="verification-container"
				inputStyle="verification-input"
				isInputNum
			/>
			<ErrorMessage name={name} component="div" className="form-error" />
			<ReactTooltip effect={"solid"} className={"tooltip"} />
		</div>
	)
}
