import React, {Dispatch, InputHTMLAttributes, PropsWithChildren} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import {flattenObject} from "../utilities/object"

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
	name: string
	label: string
	value: string
	onChange: Dispatch<any>
	hasPrefill?: boolean
}

export default function Dropdown({
	name,
	label,
	value,
	onChange,
	children,
	hasPrefill = false,
	...inputProps
}: PropsWithChildren<Props>) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"dropdown",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className} data-name={name} {...(hasPrefill && {"data-has-prefill": true})}>
			<label>
				<span className="label-text">{label}</span>
				<select name={name} value={value} onChange={onChange} {...inputProps}>
					{children}
				</select>
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
