import React, {ButtonHTMLAttributes, PropsWithChildren} from "react"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	isLoading?: boolean
}

export default function Button({isLoading, children, ...buttonProps}: PropsWithChildren<Props>) {
	const className = classNames("button")

	return (
		<button className={className} {...buttonProps} disabled={isLoading}>
			{isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
		</button>
	)
}
