import React, {PropsWithChildren} from "react"
import Textfield from "../../components/Textfield"
import Dropdown from "../../components/Dropdown"
import Address from "../../components/Address"
import {ApplicantDetails, ApplicationForm, getBusinessVerticals} from "../../resources/applicationForm"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import {addCustomValidationMethods, einInputMask, einRegex, urlRegex, usPostalCode} from "../../utilities/validation"
import {parsePhoneNumber} from "libphonenumber-js"
import {sanitizeNumberFormat} from "../../utilities/form"
import {formatPhoneNumber, toPhoneNumber, US_COUNTRY_CODE} from "../../resources/common"
import Button from "../../components/Button"
import {Trans, useTranslation} from "react-i18next"
import {isUndefined} from "lodash"

addCustomValidationMethods()

interface Props {
	updateApplicationForm: Function
	isRequestInProgress: boolean
	applicantDetails?: ApplicantDetails
	applicationForm: ApplicationForm
}

export default function BusinessInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	children,
	applicantDetails,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const businessVerticals = getBusinessVerticals()
	const settingsOverride = applicationForm.attributes.settingsOverride
	const applicationFormSettings = applicationForm.attributes.applicationFormSettings

	const validationSchema = Yup.object().shape({
		businessName: Yup.string().required(t("validationErrorMessages.businessName.required")),
		// dba:
		street: Yup.string().required(t("validationErrorMessages.street.required")),
		// street2: '',
		city: Yup.string().required(t("validationErrorMessages.city.required")),
		country: Yup.string().required(t("validationErrorMessages.country.required")),
		state: Yup.string().when("country", {
			is: US_COUNTRY_CODE,
			then: Yup.string().required(t("validationErrorMessages.state.required")),
		}),
		postalCode: Yup.string()
			.required(t("validationErrorMessages.postalCode.required"))
			.when("country", {
				is: US_COUNTRY_CODE,
				then: Yup.string().matches(usPostalCode, t("validationErrorMessages.postalCode.usInvalid")),
			}),
		phone: Yup.string()
			.required(t("validationErrorMessages.phone.required"))
			.phoneNumber(t("validationErrorMessages.phone.invalid")),
		ein: Yup.string()
			.matches(einRegex, t("validationErrorMessages.ein.invalid"))
			.required(t("validationErrorMessages.ein.required")),
		website: Yup.string().matches(urlRegex, t("validationErrorMessages.website.invalid")),
		businessVertical: Yup.string()
			.oneOf(Object.keys(businessVerticals), t("validationErrorMessages.businessVertical.invalid"))
			.when([], {
				is: () => !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.businessVertical.required")),
			}),
	})

	const stateBusinessInformation = applicationForm.attributes.state?.enterBusinessInformation
	const phone = stateBusinessInformation?.phone ?? applicantDetails?.phone
	const initialFormValues = {
		businessName: stateBusinessInformation?.businessName ?? applicantDetails?.name ?? "",
		dba: stateBusinessInformation?.dba ?? applicantDetails?.dba ?? "",

		// Address
		street: stateBusinessInformation?.address?.street ?? applicantDetails?.address?.street ?? "",
		street2: stateBusinessInformation?.address?.street2 ?? applicantDetails?.address?.street2 ?? "",
		city: stateBusinessInformation?.address?.city ?? applicantDetails?.address?.city ?? "",
		country: stateBusinessInformation?.address?.country ?? applicantDetails?.address?.country ?? US_COUNTRY_CODE,
		state: stateBusinessInformation?.address?.state ?? applicantDetails?.address?.state ?? "",
		postalCode: stateBusinessInformation?.address?.postalCode ?? applicantDetails?.address?.postalCode ?? "",

		phone: phone ? toPhoneNumber(phone) : "",
		ein: stateBusinessInformation?.ein ?? applicantDetails?.ein ?? "",
		website: stateBusinessInformation?.website ?? applicantDetails?.website ?? "",
		businessVertical: stateBusinessInformation?.businessVertical ?? applicantDetails?.businessVertical ?? "",
	}

	const prefillExistsForField = {
		businessName: !isUndefined(applicantDetails?.name),
		dba: !isUndefined(applicantDetails?.dba),
		address: !isUndefined(applicantDetails?.address),
		phone: !isUndefined(applicantDetails?.phone),
		ein: !isUndefined(applicantDetails?.ein),
		website: !isUndefined(applicantDetails?.website),
		businessVertical: !isUndefined(applicantDetails?.businessVertical),
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormBusinessInformation", {
			businessName: values.businessName,
			dba: values.dba,
			phone: {
				countryCode: parsePhoneNumber(values.phone, US_COUNTRY_CODE).countryCallingCode,
				number: parsePhoneNumber(values.phone, US_COUNTRY_CODE).nationalNumber,
			},
			ein: sanitizeNumberFormat(values.ein),
			businessVertical: values.businessVertical || null,
			address: {
				street: values.street,
				street2: values.street2,
				city: values.city,
				country: values.country,
				state: values.state,
				postalCode: values.postalCode,
			},
			website: values.website,
		})
	}

	const privacyPolicyUrl = settingsOverride?.privacyPolicyUrl ?? applicationFormSettings.applicationFormPrivacyPolicyUrl

	return (
		<div className="business-information">
			<h1>{t("businessInformation.pageTitle")}</h1>
			<p>{t("businessInformation.pageDescription")}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, isValid}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<Textfield
							name="businessName"
							label={t("businessInformation.form.businessName")}
							value={values.businessName}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.businessName}
							disabled={isForcePrefill && prefillExistsForField.businessName}
						/>
						<Textfield
							name="dba"
							label={t("businessInformation.form.dba")}
							value={values.dba}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.dba}
							disabled={isForcePrefill && prefillExistsForField.dba}
						/>
						<Textfield
							name="ein"
							label={t("businessInformation.form.ein")}
							value={values.ein}
							onChange={handleChange}
							onBlur={handleBlur}
							inputMask={einInputMask}
							hasPrefill={prefillExistsForField.ein}
							disabled={isForcePrefill && prefillExistsForField.ein}
						/>
						<Dropdown
							name={"businessVertical"}
							label={t("businessInformation.form.businessVertical")}
							value={values.businessVertical}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.businessVertical}
							disabled={isForcePrefill && prefillExistsForField.businessVertical}
						>
							<option key="default" defaultValue="" />
							{Object.keys(businessVerticals).map((businessVertical) => (
								<option key={businessVertical} value={businessVertical}>
									{businessVerticals[businessVertical]}
								</option>
							))}
						</Dropdown>
						<Textfield
							name="phone"
							label={t("businessInformation.form.phone")}
							type={"tel"}
							value={formatPhoneNumber(values.phone)}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.phone}
							disabled={isForcePrefill && prefillExistsForField.phone}
						/>
						<Address
							addressType={"Business"}
							street={values.street}
							street2={values.street2}
							city={values.city}
							country={values.country}
							state={values.state}
							postalCode={values.postalCode}
							isUsOnlyAddress={true}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							hasPrefill={prefillExistsForField.address}
							disabled={isForcePrefill && prefillExistsForField.address}
						/>
						<Textfield
							name="website"
							label={t("businessInformation.form.website")}
							value={values.website}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.website}
							disabled={isForcePrefill && prefillExistsForField.website}
						/>
						{privacyPolicyUrl && (
							<p>
								<Trans
									i18nKey="businessInformation.form.privacyNotice"
									components={{
										url: <a className={"no-before-unload-warning"} href={privacyPolicyUrl} />,
									}}
								/>
							</p>
						)}
						<Button type="submit" isLoading={isRequestInProgress}>
							{t("businessInformation.form.submit")}
						</Button>
					</form>
				)}
			</Formik>
			{children}
		</div>
	)
}
