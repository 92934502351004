import React, {PropsWithChildren, useEffect} from "react"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import {addCustomValidationMethods} from "../../utilities/validation"
import {AsYouType} from "libphonenumber-js"
import {ApplicationForm, ApplicationFormStage, sendVerification} from "../../resources/applicationForm"
import {Phone, toPhoneNumber, US_COUNTRY_CODE} from "../../resources/common"
import Button from "../../components/Button"
import Verification from "../../components/Verification"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {Trans, useTranslation} from "react-i18next"

addCustomValidationMethods()

interface Props {
	updateApplicationForm: Function
	isRequestInProgress: boolean
	applicationForm: ApplicationForm
}

export default function PhoneVerification({
	updateApplicationForm,
	isRequestInProgress,
	applicationForm,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const validationSchema = Yup.object().shape({
		verification: Yup.string()
			.required(t("validationErrorMessages.phoneVerificationNumber.required"))
			.matches(/^[0-9]{6}$/, t("validationErrorMessages.phoneVerificationNumber.invalid")),
	})
	const [sendVerificationState, sendVerificationFunction] = useAsyncResultIdle(sendVerification)
	const token = window.location.pathname.substring(1)
	const initialFormValues = {
		verification: "",
	}
	const submitForm = (values: FormikValues) => {
		sendVerificationState.map((t) =>
			updateApplicationForm("applicationFormPhoneVerification", {
				verificationCode: values.verification,
				verificationToken: t.data.attributes.verificationToken,
			})
		)
	}
	let rawPhone: Phone | undefined
	switch (applicationForm.attributes.stage) {
		case ApplicationFormStage.IndividualPhoneVerification:
		case ApplicationFormStage.SoleProprietorshipPhoneVerification:
			rawPhone = applicationForm.attributes?.state?.enterIndividualInformation?.phone
			break
		case ApplicationFormStage.BusinessPhoneVerification:
			rawPhone = applicationForm.attributes?.state?.enterOfficerInformation?.businessContact?.phone
			break
	}
	const phoneNumber = rawPhone ? new AsYouType(US_COUNTRY_CODE).input(toPhoneNumber(rawPhone)) : ""

	useEffect(() => {
		sendVerificationFunction(token)
	}, [])
	return (
		<div className="phone-verification">
			<h1>{t("phoneVerification.pageTitle")}</h1>
			<br />
			<p>
				<Trans i18nKey="phoneVerification.pageDescription">
					{t("legalAgreement.bankNotice")} {{phoneNumber}}
				</Trans>
			</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleBlur, setFieldValue, errors, isValid}) => (
					<form
						autoComplete="off"
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<Verification
							name="verification"
							value={values.verification}
							onChange={(v) => {
								setFieldValue("verification", v)
							}}
							onBlur={handleBlur}
						/>
						{sendVerificationState.match(
							() => (
								<Button type="submit" disabled>
									{t("phoneVerification.form.submit")}
								</Button>
							),
							() => (
								<Button type="submit" isLoading>
									{t("phoneVerification.form.submit")}
								</Button>
							),
							() => (
								<Button type="submit" isLoading={isRequestInProgress}>
									{t("phoneVerification.form.submit")}
								</Button>
							),
							() => (
								<Button type="submit" disabled>
									{t("phoneVerification.form.submit")}
								</Button>
							)
						)}
					</form>
				)}
			</Formik>
			<br />
			{sendVerificationState.isErr() ? (
				<div className="form-error">
					{sendVerificationState.error.errors[0].title}, {t("phoneVerification.tryToResendMessage")}
				</div>
			) : undefined}
			<p>
				<a
					className=""
					href="#"
					onClick={(e) => {
						e.preventDefault()
						sendVerificationState.match(
							() => {
								sendVerificationFunction(token)
							},
							() => undefined,
							() => {
								sendVerificationFunction(token)
							},
							() => {
								sendVerificationFunction(token)
							}
						)
					}}
				>
					{t("phoneVerification.form.resend")}
				</a>
			</p>
		</div>
	)
}
