import React from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import {flattenObject} from "../utilities/object"

interface Props {
	name: string
	label: string
	value: string
	options: Record<string, string>
	setFieldValue: Function
	disabled?: boolean
	hasPrefill?: boolean
}

export default function SingleSelection({
	name,
	label,
	value,
	options,
	setFieldValue,
	disabled = false,
	hasPrefill = false,
}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"single-selection",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error",
		disabled && "disabled"
	)

	return (
		<div className={className} data-name={name} {...(hasPrefill && {"data-has-prefill": true})}>
			<label>
				<span className="label-text">{label}</span>
			</label>
			<div className="single-selection-options">
				{Object.keys(options).map((optionKey) => {
					const optionValue = options[optionKey]
					const optionClassNames = classNames("option", value === optionKey && "selected")
					return (
						<div
							key={optionKey}
							className={optionClassNames}
							onClick={() => {
								if (!disabled) {
									setFieldValue(name, optionKey)
								}
							}}
						>
							{optionValue}
						</div>
					)
				})}
			</div>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
