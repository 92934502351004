import React from "react"
import ApplicationStatus from "../../components/ApplicationForm/ApplicationStatus"
import {ApplicationForm} from "../../resources/applicationForm"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
}

export default function BusinessApplicationCreated({applicationForm, fetchApplicationForm}: Props) {
	return (
		<div className="individual-application-created">
			<ApplicationStatus applicationForm={applicationForm} fetchApplicationForm={fetchApplicationForm} />
		</div>
	)
}
