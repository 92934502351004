export type AsyncResultIdleRequestState<T, E> = Idle<T, E> | Pending<T, E> | Ok<T, E> | Err<T, E>

export const AsyncResultIdle = {
	idle: <T, E>(): Idle<T, E> => new Idle(),
	pending: <T, E>(): Pending<T, E> => new Pending(),
	ok: <T, E>(value: T): Ok<T, E> => new Ok(value),
	err: <T, E>(err: E): Err<T, E> => new Err(err),
}

export class Idle<T, E> {
	isOk(): this is Ok<T, E> {
		return false
	}

	isPending(): this is Pending<T, E> {
		return true
	}

	isErr(): this is Err<T, E> {
		return false
	}

	iter(_fn: (value: T) => void) {
		// only ok implements iter
	}

	iterErr(_fn: (err: E) => void) {
		// only error implements iterErr
	}

	map<A>(_f: (t: T) => A): AsyncResultIdleRequestState<A, E> {
		return AsyncResultIdle.idle()
	}

	mapErr<U>(_f: (e: E) => U): AsyncResultIdleRequestState<T, U> {
		return AsyncResultIdle.idle()
	}

	match = <A>(idle: () => A, _pending: () => A, _ok: (t: T) => A, _err: (e: E) => A): A => {
		return idle()
	}
}

export class Pending<T, E> {
	isOk(): this is Ok<T, E> {
		return false
	}

	isPending(): this is Pending<T, E> {
		return true
	}

	isErr(): this is Err<T, E> {
		return false
	}

	iter(_fn: (value: T) => void) {
		// only ok implements iter
	}

	iterErr(_fn: (err: E) => void) {
		// only error implements iterErr
	}

	map<A>(_f: (t: T) => A): AsyncResultIdleRequestState<A, E> {
		return AsyncResultIdle.pending()
	}

	mapErr<U>(_f: (e: E) => U): AsyncResultIdleRequestState<T, U> {
		return AsyncResultIdle.pending()
	}

	match = <A>(_idle: () => A, pending: () => A, _ok: (t: T) => A, _err: (e: E) => A): A => {
		return pending()
	}
}

export class Ok<T, E> {
	constructor(readonly value: T) {}

	isOk(): this is Ok<T, E> {
		return true
	}

	isPending(): this is Pending<T, E> {
		return false
	}

	isErr(): this is Err<T, E> {
		return false
	}

	iter(fn: (value: T) => void) {
		fn(this.value)
	}

	iterErr(_fn: (err: E) => void) {
		// only error implements iterErr
	}

	map<A>(f: (t: T) => A): AsyncResultIdleRequestState<A, E> {
		return AsyncResultIdle.ok(f(this.value))
	}

	mapErr<U>(_f: (e: E) => U): AsyncResultIdleRequestState<T, U> {
		return AsyncResultIdle.ok(this.value)
	}

	match = <A>(_idle: () => A, _pending: () => A, ok: (t: T) => A, _err: (e: E) => A): A => {
		return ok(this.value)
	}
}

export class Err<T, E> {
	constructor(readonly error: E) {}

	isOk(): this is Ok<T, E> {
		return false
	}

	isErr(): this is Err<T, E> {
		return !this.isOk()
	}

	iter(_fn: (value: T) => void) {
		// only ok implements iter
	}

	iterErr(fn: (err: E) => void) {
		fn(this.error)
	}

	map<A>(_f: (t: T) => A): AsyncResultIdleRequestState<A, E> {
		return AsyncResultIdle.err(this.error)
	}

	mapErr<U>(f: (e: E) => U): AsyncResultIdleRequestState<T, U> {
		return AsyncResultIdle.err(f(this.error))
	}

	match = <A>(_idle: () => A, _pending: () => A, _ok: (t: T) => A, err: (e: E) => A): A => {
		return err(this.error)
	}
}
