import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import en from "./translations/en.json"
import es from "./translations/es.json"
import creativeJuice from "./translations/custom/creative_juice.json"
import wix from "./translations/custom/wix.json"
import customSwitch from "./translations/custom/switch.json"
import mastercard from "./translations/custom/mastercard.json"

const resources = {
	en,
	es,
	creativeJuice,
	wix,
	switch: customSwitch,
	mastercard,
}

export const availableLanguages = Object.keys(resources)

i18n.use(initReactI18next).init({
	resources,
	defaultNS: "applicationForm",
	fallbackLng: "en",
})

export default i18n
