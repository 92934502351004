{
  "applicationForm": {
    "previousStep": "Previous Step",
    "step": "Step",
    "submit": "Continue",
    "yes": "Yes",
    "no": "No",
    "applicationType": {
      "introHeadline": "Let's get started.",
      "intro": "To begin your application, please provide a few additional pieces of information below.",
      "pageTitle": "Application Type",
      "pageDescription": "Please choose the option below that matches your intended account use.",
      "contactUsLink": "If you’re not sure, <url> contact us </url> and we’ll be happy to help.",
      "form": {
        "individual": {
          "title": "Individual",
          "description": "Choose this if the account will be used for your personal finances"
        },
        "soleProprietorship": {
          "title": "Sole Proprietorship",
          "description": "Choose this if you are an individual in full ownership of a business"
        },
        "business": {
          "title": "Business",
          "description": "Choose this if you are applying on behalf of a corporation, LLC or partnership"
        }
      }
    },
    "individualInformation": {
      "pageTitle": "Individual Information",
      "pageDescription": "To begin your application, please provide a few additional pieces of information below.",
      "soleProprietorInformation": {
        "pageTitle": "Individual Information",
        "pageDescription": "To begin your application, please provide a few additional pieces of information below.",
        "form": {
          "dba": "Doing Business As (Optional)",
          "ein": "Employer Identification Number (Optional)"
        }
      },
      "form": {
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify your identity, we’ll need to know your full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "passportTooltip": "To verify your identity, we’ll need to know your full Passport.",
        "physicalAddress": "Physical Address",
        "physicalAddressTooltip": "PO boxes cannot be used as an address",
        "privacyNotice": "By clicking Agree & Continue you agree to Wix <url>Privacy Policy</url>.",
        "continue": "Agree & Continue",
        "submit": "Submit"
      }
    },
    "individualAdditionalInformation": {
      "pageTitle": "Individual Additional Information",
      "pageDescription": "Please fill in additional Information.",
      "form": {
        "occupation": "Occupation",
        "annualIncome": "Annual Income",
        "sourceOfIncome": "Source Of Income",
        "submit": "Submit",
        "occupationsList": {
          "architectOrEngineer": "Architect or Engineer",
          "businessAnalystAccountantOrFinancialAdvisor": "Business Analyst, Accountant or Financial Advisor",
          "communityAndSocialServicesWorker": "Community and Social Services Worker",
          "constructionMechanicOrMaintenanceWorker": "Construction, Mechanic or Maintenance Worker",
          "doctor": "Doctor",
          "educator": "Educator",
          "entertainmentSportsArtsOrMedia": "Entertainment, Sports, Arts or Media",
          "executiveOrManager": "Executive or Manager",
          "farmerFishermanForester": "Farmer, Fisherman, Forester",
          "foodServiceWorker": "Food Service Worker",
          "gigWorker": "Gig Worker (e.g. ride-hail driver or other freelancer)",
          "hospitalityOfficeOrAdministrativeSupportWorker": "Hospitality, Office or Administrative Support Worker",
          "householdManager": "Household Manager",
          "janitorHousekeeperLandscaper": "Janitor, Housekeeper, Landscaper",
          "lawyer": "Lawyer",
          "manufacturingOrProductionWorker": "Manufacturing or Production Worker",
          "militaryOrPublicSafety": "Military or Public Safety",
          "nurseHealthcareTechnicianOrHealthcareSupport": "Nurse, Healthcare Technician or Healthcare Support",
          "personalCareOrServiceWorker": "Personal Care or Service Worker",
          "pilotDriverOperator": "Pilot, Driver, Operator",
          "salesRepresentativeBrokerAgent": "Sales Representative, Broker, Agent",
          "scientistOrTechnologist": "Scientist or Technologist",
          "student": "Student"
        },
        "annualIncomesList": {
          "upTo10k": "$0 - $10,000",
          "between10kAnd25k": "$10,000 - $25,000",
          "between25kAnd50k": "$25,000 - $50,000",
          "between50kAnd100k": "$50,000 - $100,000",
          "between100kAnd250k": "$100,000 - $250,000",
          "over250k": "$250,000+"
        },
        "sourcesOfIncomeList": {
          "employmentOrPayrollIncome": "Employment or payroll income",
          "partTimeOrContractorIncome": "Part-time or contractor income",
          "inheritancesAndGifts": "Inheritances and gifts",
          "personalInvestments": "Personal investments",
          "businessOwnershipInterests": "Business ownership interests",
          "governmentBenefits": "Government Benefits"
        }
      }
    },
    "soleProprietorshipBusinessInformation": {
      "pageTitle": "Business Information",
      "pageDescription": "Please tell us more about your business.",
      "form": {
        "businessVertical": "Business Vertical",
        "annualRevenue": "Annual Revenue",
        "numberOfEmployees": "Number Of Employees",
        "dba": "Doing Business As (Optional)",
        "ein": "Employer Identification Number",
        "website": "Website",
        "submit": "Submit"
      }
    },
    "businessInformation": {
      "pageTitle": "Business Information",
      "pageDescription": "We need to collect some basic information about your business.",
      "form": {
        "businessVertical": "Business Vertical",
        "entityType": "Entity Type",
        "industryType": "Industry",
        "entities": {
          "corporation": "Corporation",
          "publiclyTradedCorporation": "Publicly Traded Corporation",
          "privatelyHeldCorporation": "Privately Held Corporation",
          "llc": "Limited Liability Company (LLC)",
          "partnership": "Partnership",
          "notForProfitOrganization": "Non-Profit"
        },
        "industry": {
          "retail": "Retail",
          "wholesale": "Wholesale",
          "restaurants": "Restaurants",
          "hospitals": "Hospitals",
          "construction": "Construction",
          "insurance": "Insurance",
          "unions": "Unions",
          "realEstate": "Real Estate",
          "freelanceProfessional": "Freelance Professional",
          "otherProfessionalServices": "Other Professional Services",
          "onlineRetailer": "Online Retailer",
          "otherEducationServices": "Other Education Services"
        },
        "businessName": "Legal Business Name",
        "dba": "Doing Business As (Optional)",
        "ein": "Employer Identification Number",
        "phone": "Phone Number",
        "stateOfIncorporation": "State of Incorporation",
        "website": "Business Website (Optional)",
        "physicalAddress": "Physical Address",
        "physicalAddressTooltip": "PO boxes cannot be used as an address",
        "privacyNotice": "By clicking Agree & Continue you agree to Wix <url>Privacy Policy</url>.",
        "submit": "Agree & Continue"
      }
    },
    "businessAdditionalInformation": {
      "pageTitle": "Additional Business Information",
      "pageDescription": "Please tell us more about your business",
      "form": {
        "annualRevenue": "Annual Revenue",
        "numberOfEmployees": "Number Of Employees",
        "cashFlow": "Cash Flow",
        "yearOfIncorporation": "Year Of Incorporation",
        "countriesOfOperation": "Countries Of Operation",
        "stockSymbol": "Stock Symbol",
        "stateOfIncorporation": "State Of Incorporation",
        "hasNonUsEntities": "Is the Officer or any of the owners associated with this business a non-US national?",
        "continue": "Continue",
        "businessVerticalsList": {
          "adultEntertainmentDatingOrEscortServices":  "Adult Entertainment, Dating or Escort Services",
          "agricultureForestryFishingOrHunting": "Agriculture, Forestry, Fishing or Hunting",
          "artsEntertainmentAndRecreation":  "Arts, Entertainment and Recreation",
          "businessSupportOrBuildingServices": "Business Support or Building Services",
          "cannabis":  "Cannabis",
          "construction": "Construction",
          "directMarketingOrTelemarketing":  "Direct Marketing/Telemarketing",
          "educationalServices": "Educational Services",
          "financialServicesCryptocurrency":  "Financial Services - Cryptocurrency",
          "financialServicesDebitCollectionOrConsolidation": "Financial Services - Debt Collection or Consolidation",
          "financialServicesMoneyServicesBusinessOrCurrencyExchange":  "Financial Services - Money Services Business or Currency Exchange",
          "financialServicesOther": "Financial Services - Other",
          "financialServicesPaydayLending":  "Financial Services - Payday Lending",
          "gamingOrGambling": "Gaming/Gambling",
          "healthCareAndSocialAssistance":  "Health Care and Social Assistance",
          "hospitalityAccommodationOrFoodServices": "Hospitality, Accommodation or Food Services",
          "legalAccountingConsultingOrComputerProgramming":  "Legal, Accounting, Consulting or Computer Programming",
          "manufacturing": "Manufacturing",
          "mining":  "Mining",
          "nutraceuticals": "Nutraceuticals",
          "personalCareServices":  "Personal Care Services",
          "publicAdministration": "Public Administration",
          "realEstate":  "Real Estate",
          "religiousCivicAndSocialOrganizations": "Religious, Civic and Social Organizations",
          "repairAndMaintenance":  "Repair and Maintenance",
          "retailTrade": "Retail Trade",
          "technologyMediaOrTelecom":  "Technology, Media or Telecom",
          "transportationOrWarehousing": "Transportation or Warehousing",
          "utilities":  "Utilities",
          "wholesaleTrade": "Wholesale Trade"
        },
        "soleProprietorshipAnnualRevenuesList": {
          "upTo50k": "$0 - $50,000",
          "between50kAnd100k": "$50,000 - $100,000",
          "between100kAnd200k":  "$100,000 - $200,000",
          "between200kAnd500k": "$200,000 - $500,000",
          "over500k":  "$500,000+"
        },
        "businessAnnualRevenuesList": {
          "upTo250k": "$0 - $250,000",
          "between250kAnd500k": "$250,000 - $500,000",
          "between500kAnd1m":  "$500,000 - $1,000,000",
          "between1mAnd5m": "$1,000,000 - $5,000,000",
          "over5m":  "$5,000,000+"
        },
        "soleProprietorshipNumberOfEmployeesList": {
          "one": "1 (only myself)",
          "between2And5": "2 - 5",
          "between5And10": "5 - 10",
          "over10": "10+"
        },
        "businessNumberOfEmployeesList": {
          "upTo10": "Up to 10",
          "between10And50": "10 - 50",
          "between50and100":  "50 - 100",
          "between100and500": "100 - 500",
          "over500":  "500+"
        },
        "cashFlowList": {
          "unpredictable": "Unpredictable",
          "predictable": "Predictable"
        }
      }
    },
    "officerInformation": {
      "pageTitle": "Officer Information",
      "pageDescription": "Next, we will need information about one officer at the business",
      "form": {
        "firstName": "First Name",
        "lastName": "Last Name",
        "title": "Title",
        "titlesList": {
          "ceo": "CEO",
          "coo": "COO",
          "cfo": "CFO",
          "president": "President",
          "benefitsAdministrationOfficer": "Benefits",
          "cio": "CIO",
          "vp": "VP",
          "avp": "AVP",
          "treasurer": "Treasurer",
          "secretary": "Secretary",
          "controller": "Controller",
          "manager": "Manager",
          "partner": "Partner",
          "member": "Member"
        },
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify the identity, we’ll need to know the officer's full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "primaryPointOfContact": "This officer is also the business's primary point of contact",
        "businessContact": "Business contact",
        "submit": "Continue",
        "officerIsBeneficialOwner": "This officer is also a beneficial owner",
        "officerIsBeneficialOwnerTooltip": "A beneficial owner is anyone who holds more than 25% of the company"
      }
    },
    "beneficialOwnersInformation": {
      "pageTitle": "Beneficial Owners Information",
      "pageDescription": "Please also fill out the following information pertaining to any owners who hold 25% or more of your organization",
      "form": {
        "numberOfBeneficialOwners": "How Many Owners Hold 25% or More?",
        "numberOfBeneficialOwnersList": {
          "zero": "Zero",
          "one": "One",
          "two": "Two",
          "three": "Three",
          "four": "Four"
        },
        "owner": "Owner",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify your identity, we’ll need to know your full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "percentage": "Percentage",
        "beneficialOwnersAgreement": "By providing the information above, I hereby certify, to the best of my knowledge, that the information provided above is complete and correct.",
        "submit": "Submit"
      }
    },
    "phoneVerification": {
      "pageTitle": "Phone Verification",
      "pageDescription": "We sent you an SMS verification code to: {{phoneNumber}}",
      "form": {
        "submit": "Verify",
        "resend": "Resend"
      },
      "tryToResendMessage": "Please try to resend or try again later"
    },
    "applicationCreated": {
      "pageTitle": "Application Created"
    },
    "addressForm": {
      "street": "Street",
      "apartment": "Apartment, unit, suite, or floor # (Optional)",
      "city": "City",
      "country": "Country",
      "state": "State",
      "zip": "Zip Code"
    },
    "legalAgreement": {
      "privacyPolicy": "Privacy Policy",
      "consentToElectronicDisclosures": "Consent to Electronic Disclosures",
      "depositTermsConditions": "Deposit Terms & Conditions",
      "clientTermsOfService": "Client Terms of Service",
      "cardholderTermsConditions": "Cardholder Terms and Conditions",
      "cashAdvanceTermsConditions": "Cash Advance Terms and Conditions",
      "agreement": "I agree to the",
      "bankNotice": "Wix is not a bank. Banking products and services are provided by Piermont Bank, Member FDIC. The Wix Visa Debit Card is issued by Piermont Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted.",
      "visaNotice": "The {{clientName}} Visa® Debit Card is issued by the sponsor bank as named in the Debit Cardholder agreement pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted.",
      "debitCardDisclosure": "Debit Card Disclosure"
    },
    "applicationDocuments": {
      "documentTypes": {
        "idDocument": {
          "title": "ID",
          "description": "Please provide a copy of your unexpired government issued photo ID which would include Drivers License or State ID."
        },
        "passport": {
          "shortKey": "Passport",
          "title": "Passport",
          "description": "Please provide a copy of your unexpired passport."
        },
        "addressVerification": {
          "shortKey": "Address",
          "title": "Address Verification",
          "description": "Please provide a document to verify your address. Document may be a utility bill, bank statement, lease agreement or current pay stub."
        },
        "ssn": {
          "shortKey": "SSN",
          "title": "Social Security Card",
          "description": "Please provide a document verifying your Social Security Number (SSN) such as a tax return or social security card."
        },
        "socialSecurityCard": {
          "shortKey": "SSN",
          "title": "Social Security Card",
          "description": "Please provide a document verifying your Social Security Number (SSN) such as a tax return or social security card."
        },
        "certificateOfIncorporation": {
          "title": "Certificate Of Incorporation",
          "description": "For Corporation: Please provide a certified copy of the Articles of Incorporation or Certificate of Incorporation. For Partnership: Please provide a copy of the Partnership Agreement. For LLC: Please provide a certified copy of the Articles of Organization."
        },
        "employerIdentificationNumberConfirmation": {
          "shortKey": "EIN",
          "title": "Employer Identification Number Confirmation",
          "description": "Please provide a document verifying your Federal EIN such as a business tax return, IRS 147c letter or the IRS Form CP 575."
        }
      },
      "form": {
        "required": "required for {{clientName}}",
        "uploadDocument": "Upload Document:",
        "uploadFront": "Upload Document's Front Side:",
        "uploadBack": "Upload Document's Back Side",
        "uploadButton": "Upload File",
        "cameraButton": "Open Camera"
      },
      "documentStatuses": {
        "approved": "Document Approved",
        "pendingReview": "Pending review",
        "invalid": "Invalid document"
      },
      "formStatuses": {
        "approved": {
          "pageTitle": "Approved Application",
          "pageDescription": "The application has been approved"
        },
        "denied": {
          "pageTitle": "Denied Application",
          "pageDescription": "The application has been denied"
        },
        "canceled": {
          "pageTitle": "Canceled Application",
          "pageDescription": "The application has been canceled"
        },
        "pending": {
          "pageTitle": "Processing Application",
          "pageDescription": "Your application is currently being processed. \n The evaluation may take up to a minute, please do not close the window while the application is in progress. \n Thank you for your patience.",
          "delayMessage": "Evaluating your application takes longer than expected, and may require offline processing. You may try again, or click “continue” to leave the application and come back at another time.",
          "loading": "Please wait",
          "reTry": "Try Again",
          "contactSupport": "If the problem persists, contact <url> support </url>"
        },
        "pendingReview": {
          "pageTitle": "Pending Review",
          "pageDescription": "The application has been submitted and is currently being evaluated. Once the evaluation is complete, you will receive the application result by e-mail."
        },
        "awaitingDocuments": {
          "pageTitle": "Upload Additional Documents",
          "pageDescription": "In order to verify your information and make a decision on your application, you would need to provide additional documentation."
        }
      }
    },
    "validationErrorMessages": {
      "error": "An error has occurred",
      "firstName": {
        "required": "Please enter your first name"
      },
      "lastName": {
        "required": "Please enter your last name"
      },
      "phone": {
        "required": "Please enter your phone number",
        "invalid": "The phone number you entered is invalid"
      },
      "email": {
        "required": "Please enter your email address",
        "invalid": "The email address you entered is invalid"
      },
      "ssn": {
        "required": "Please enter your full, 9-digit Social Security Number",
        "invalid": "The Social Security Number you have provided is invalid (format is xxx-xx-xxxx)"
      },
      "nationality": {
        "required": "Please select the nationality"
      },
      "passport": {
        "required": "Please enter your full passport"
      },
      "street": {
        "required": "Please enter the street name"
      },
      "city": {
        "required": "Please enter your city of residence"
      },
      "state": {
        "required": "Please select the state of residence"
      },
      "postalCode": {
        "required": "Please enter your zip code (must be 5 or 9 digits long)",
        "usInvalid": "The zip code you have provided is invalid (US zip codes must be 5 or 9 digits long)",
        "caInvalid": "The zip code you have provided is invalid (CA zip codes are in a A1A 1A1 format, where A is a letter and 1 is a digit)"
      },
      "country": {
        "required": "Please select the country of residence"
      },
      "dateOfBirth": {
        "required": "Please enter the date of birth",
        "max": "You must be at least 18 years old to submit an application",
        "invalid": "Date of birth is invalid"
      },
      "businessName": {
        "required": "Please enter your legal business name"
      },
      "stateOfIncorporation": {
        "required": "Please select the Business’s state of incorporation"
      },
      "ein": {
        "required": "Please enter the 9-digit employer identification number",
        "invalid": "The EIN you have entered is invalid (must be 9 digits long)"
      },
      "website": {
        "invalid": "The website URL you have provided is invalid"
      },
      "agreement": {
        "required": "Checking this field is required"
      },
      "businessEntityType": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "industry": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "beneficialOwnershipPercentage": {
        "required": "Please enter the beneficial owner’s holding (%) in the business",
        "range": "Range must be between 25 and 100"
      },
      "phoneVerificationNumber": {
        "required": "Please enter the verification code",
        "invalid": "Please enter a digit"
      },
      "occupation": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "annualIncome": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "sourceOfIncome": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "businessVertical": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "annualRevenue": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "numberOfEmployees": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "cashFlow": {
        "required": "This field is required"
      },
      "yearOfIncorporation": {
        "required": "This field is required",
        "invalid": "Please enter a valid year"
      },
      "hasNonUsEntities": {
        "required": "This field is required"
      },
      "countriesOfOperation": {
        "required": "This field is required"
      },
      "stockSymbol": {
        "required": "This field is required"
      }
    }
  }
}
