import React, {PropsWithChildren, useEffect, useState} from "react"
import {
	ApplicationForm,
	ApplicationFormSettings,
	ApplicationFormStage,
	ApplicationFormStatus,
	ApplicationFormType,
	createDefaultApplicationForm,
	getApplicationForm,
	hotjarSetup,
	updateApplicationForm,
} from "../../resources/applicationForm"
import FormTypeSelection from "./FormTypeSelection"
import IndividualInformation from "./IndividualInformation"
import BusinessInformation from "./BusinessInformation"
import OfficerInformation from "./OfficerInformation"
import FormCurrentStageIndicator from "../../components/ApplicationForm/FormCurrentStageIndicator"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import IndividualApplicationCreated from "./IndividualApplicationCreated"
import BusinessApplicationCreated from "./BusinessApplicationCreated"
import {useToasts} from "react-toast-notifications"
import BeneficialOwnersInformation from "./BeneficialOwnersInformation"
import {scrollToTop} from "../../utilities/dom"
import UnitLogo from "../DefaultLayout/logo.png"
import PhoneVerification from "./PhoneVerification"
import BackButton from "../../components/ApplicationForm/BackButton"
import "../../i18n"
import {useTranslation} from "react-i18next"
import i18n from "i18next"
import {hotjar} from "react-hotjar"
import classNames from "classnames"
import SoleProprietorshipBusinessInformation from "./SoleProprietorshipBusinessInformation"
import BusinessAdditionalInformation from "./BusinessAdditionalInformation"
import {urlRegex, validateSVG} from "../../utilities/validation"
import DataCollection from "./DataCollection/DataCollection"
import {isUndefined} from "lodash"
import DataCollectionCompleted from "./DataCollection/IndividualApplicationCompleted"

function beforeUnloadListener(event: BeforeUnloadEvent) {
	if (document.activeElement?.classList.contains("no-before-unload-warning")) {
		return
	}

	// Notice that custom text is not supported anymore so probably the browser's default text will be displayed and not
	// the custom one.
	// We are setting returnValue and returning the text to support as many browsers versions that we can.
	const unloadWarning =
		"The application process has not been completed. Are you sure you want to leave the application form?"
	event.preventDefault()
	event.returnValue = unloadWarning
	return unloadWarning
}

function setCustomCss(applicationFormSettings?: ApplicationFormSettings) {
	if (applicationFormSettings?.applicationFormCustomCss) {
		const customStyle = document.createElement("style")
		customStyle.innerText = applicationFormSettings.applicationFormCustomCss
		document.head.appendChild(customStyle)
	}
}

function setFavicon(applicationFormSettings?: ApplicationFormSettings) {
	if (applicationFormSettings?.applicationFormFavicon) {
		document.querySelector("#favicon")?.setAttribute("href", applicationFormSettings.applicationFormFavicon)
	}
}

function setTitle(applicationFormSettings?: ApplicationFormSettings) {
	if (applicationFormSettings?.applicationFormBrandName) {
		document.title = `${applicationFormSettings.applicationFormBrandName} - Onboarding`
	}
}

export function businessApplicationHasNonUsEntities(applicationForm: ApplicationForm) {
	const cddEddAttributes = applicationForm.attributes.cddEddAttributes

	return (
		(cddEddAttributes?.officer?.nationality && cddEddAttributes?.officer.nationality !== "US") ||
		(cddEddAttributes?.beneficialOwners &&
			cddEddAttributes?.beneficialOwners.some((bo) => bo.nationality && bo.nationality !== "US"))
	)
}

function isWebsiteRequired(website?: string, hasNoWebsite?: boolean) {
	return (isUndefined(hasNoWebsite) || hasNoWebsite === false) && (website ? !urlRegex.test(website) : true)
}

function isDataCollectionRequired(applicationForm: ApplicationForm) {
	if (applicationForm.attributes.status !== "Approved") {
		return false
	}

	const applicationDtoAttributes = applicationForm.attributes.cddEddAttributes
	const isHighRisk = applicationForm.attributes.customerRiskRate == "high"

	if (applicationForm.attributes.stage === ApplicationFormStage.IndividualApplicationCreated) {
		const nationality = applicationDtoAttributes?.nationality ?? "US"
		const requiredCddEddAttributes = [applicationDtoAttributes?.occupation]
		const isEddRequired = (nationality && nationality !== "US") || isHighRisk
		if (isEddRequired) {
			requiredCddEddAttributes.push(applicationDtoAttributes?.sourceOfIncome)
			requiredCddEddAttributes.push(applicationDtoAttributes?.annualIncome)
		}

		// Data collection is required if any of the required attributes are undefined
		return requiredCddEddAttributes.some((attr) => isUndefined(attr))
	} else if (applicationForm.attributes.stage === ApplicationFormStage.SoleProprietorshipApplicationCreated) {
		const nationality = applicationDtoAttributes?.nationality ?? "US"
		const isEddRequired = (nationality && nationality !== "US") || isHighRisk
		const requiredCddEddAttributes = [applicationDtoAttributes?.businessVertical]
		if (isEddRequired) {
			requiredCddEddAttributes.push(applicationDtoAttributes?.annualRevenue)
			requiredCddEddAttributes.push(applicationDtoAttributes?.numberOfEmployees)
		}

		// Data collection is required if any of the required attributes are undefined
		return (
			requiredCddEddAttributes.some((attr) => isUndefined(attr)) ||
			isWebsiteRequired(applicationDtoAttributes?.website, applicationDtoAttributes?.hasNoWebsite)
		)
	} else if (applicationForm.attributes.stage === ApplicationFormStage.BusinessApplicationCreated) {
		const businessHasNonUsEntities = businessApplicationHasNonUsEntities(applicationForm)
		const isEddRequired = businessHasNonUsEntities || isHighRisk

		// Business check
		const requiredBusinessCddEddAttributes: (string | string[] | undefined)[] = [
			applicationDtoAttributes?.businessVertical,
			applicationDtoAttributes?.yearOfIncorporation,
		]
		if (isEddRequired) {
			requiredBusinessCddEddAttributes.push(applicationDtoAttributes?.annualRevenue)
			requiredBusinessCddEddAttributes.push(applicationDtoAttributes?.numberOfEmployees)
			requiredBusinessCddEddAttributes.push(applicationDtoAttributes?.cashFlow)
			requiredBusinessCddEddAttributes.push(applicationDtoAttributes?.countriesOfOperation)
		}

		if (
			requiredBusinessCddEddAttributes.some((attr) => isUndefined(attr)) ||
			isWebsiteRequired(applicationDtoAttributes?.website, applicationDtoAttributes?.hasNoWebsite)
		) {
			return true
		}

		// Officer check
		if (isEddRequired) {
			const requiredOfficerCddEddAttributes = [
				applicationDtoAttributes?.officer?.occupation,
				applicationDtoAttributes?.officer?.sourceOfIncome,
				applicationDtoAttributes?.officer?.annualIncome,
			]

			if (requiredOfficerCddEddAttributes.some((attr) => isUndefined(attr))) {
				return true
			}

			// BOs check
			if (applicationDtoAttributes?.beneficialOwners) {
				for (let i = 0; i < applicationDtoAttributes?.beneficialOwners.length; i++) {
					const bo = applicationDtoAttributes?.beneficialOwners[i]
					const requiredBoCddEddAttributes = [bo.occupation, bo.sourceOfIncome, bo.annualIncome]

					if (requiredBoCddEddAttributes.some((attr) => isUndefined(attr))) {
						return true
					}
				}
			}
		}
	}

	return false
}

const unloadOptionalNoWarningStages = Object.values(ApplicationFormStage).filter((stage) =>
	stage.includes("ApplicationCreated")
)
const unloadNoWarningStatuses = Object.values(ApplicationFormStatus).filter(
	(status) => !status.includes(ApplicationFormStatus.AwaitingDocuments)
)

export default function ApplicationFormMain({}: PropsWithChildren<{}>) {
	const {t} = useTranslation()
	const [applicationForm, setApplicationForm] = useState<ApplicationForm>()
	const [applicationFormStage, setApplicationFormStage] = useState<ApplicationFormStage>(ApplicationFormStage.Initial)
	const token = window.location.pathname.substring(1)
	const [applicationFormInitialRequest, sendApplicationFormInitialRequest] = useAsyncResultIdle(() =>
		getApplicationForm(token)
	)

	const [createState, create] = useAsyncResultIdle(createDefaultApplicationForm)
	const [updateApplicationFormState, updateApplicationFormFunction] = useAsyncResultIdle(updateApplicationForm)
	const {addToast} = useToasts()
	const [isRequestInProgress, setIsRequestInProgress] = useState(false)
	const [selectedFormType, setSelectedFormType] = useState<ApplicationFormType>(ApplicationFormType.Individual)
	const [validatePhoneNumber, setValidatePhoneNumber] = useState(true)

	useEffect(() => {
		hotjar.initialize(hotjarSetup.id, hotjarSetup.version)
	}, [])

	useEffect(() => {
		if (token == "") {
			create()
		} else {
			sendApplicationFormInitialRequest()
		}
	}, [])

	useEffect(() => {
		createState.match(
			() => null,
			() => null,
			(a) => {
				window.location.href = a.data.attributes.url
			},
			() => {
				sendApplicationFormInitialRequest()
			}
		)
	}, [createState])

	useEffect(() => {
		window.removeEventListener("beforeunload", beforeUnloadListener)

		if (
			!(
				unloadOptionalNoWarningStages.includes(applicationFormStage) &&
				applicationForm &&
				unloadNoWarningStatuses.includes(applicationForm.attributes.status)
			)
		) {
			window.addEventListener("beforeunload", beforeUnloadListener)
		}
	}, [applicationFormStage, applicationForm?.attributes.status])

	const updateApplicationFormInternal = async (requestType: string, state: object) => {
		setIsRequestInProgress(true)
		const res = await updateApplicationFormFunction(requestType, token, state)
		res.match(
			(applicationForm) => {
				setApplicationForm(applicationForm.data)
				setApplicationFormStage(applicationForm.data.attributes.stage)
			},
			() => null
		)
		setIsRequestInProgress(false)
		return res
	}

	const fetchApplicationForm = async () => {
		setIsRequestInProgress(true)
		const result = await getApplicationForm(token)

		result.match(
			(applicationForm) => {
				setApplicationForm(applicationForm.data)
				setApplicationFormStage(applicationForm.data.attributes.stage)
			},
			() => null
		)
		setIsRequestInProgress(false)
	}

	useEffect(() => {
		scrollToTop()
		hotjar.event(applicationFormStage)
	}, [applicationFormStage])

	useEffect(() => {
		applicationFormInitialRequest.match(
			() => null,
			() => null,
			(applicationForm) => {
				const applicationType = applicationForm.data.attributes.applicantDetails?.applicationType
				const stateApplicationType =
					applicationForm.data.attributes.state.chooseBusinessOrIndividual?.applicationFormType
				const applicationFormLang = applicationForm.data.attributes?.lang
				setApplicationForm(applicationForm.data)
				setApplicationFormStage(applicationForm.data.attributes.stage)
				if (applicationFormLang) {
					i18n.changeLanguage(applicationFormLang)
				}
				hotjar.identify(applicationForm.data.id, applicationForm.data.attributes.tags)

				if (stateApplicationType) {
					setSelectedFormType(stateApplicationType)
				} else if (applicationForm.data.attributes.allowedApplicationTypes) {
					if (applicationType && applicationForm.data.attributes.allowedApplicationTypes.includes(applicationType)) {
						setSelectedFormType(applicationType)
					} else {
						setSelectedFormType(applicationForm.data.attributes.allowedApplicationTypes[0])
					}
				} else {
					if (applicationType) {
						setSelectedFormType(applicationType)
					}
				}

				setCustomCss(applicationForm.data.attributes.applicationFormSettings)
				setFavicon(applicationForm.data.attributes.applicationFormSettings)
				setTitle(applicationForm.data.attributes.applicationFormSettings)
			},
			(err) => {
				addToast(err.errors ? err.errors[0].title : "An error has occurred", {appearance: "error"})
			}
		)
	}, [applicationFormInitialRequest])

	useEffect(() => {
		if (
			applicationForm &&
			selectedFormType &&
			!applicationForm.attributes.applicationFormSettings?.applicationFormValidatePhoneNumber
		) {
			switch (selectedFormType) {
				case ApplicationFormType.Individual: {
					setValidatePhoneNumber(!applicationForm?.attributes.applicantDetails?.phone)
					break
				}
				case ApplicationFormType.SoleProprietorship: {
					setValidatePhoneNumber(!applicationForm?.attributes.applicantDetails?.phone)
					break
				}
				case ApplicationFormType.Business: {
					setValidatePhoneNumber(!applicationForm?.attributes.applicantDetails?.contact?.phone)
					break
				}
				default: {
				}
			}
		}
	}, [selectedFormType, applicationForm])
	useEffect(() => {
		updateApplicationFormState.match(
			() => null,
			() => null,
			(applicationForm) => setApplicationFormStage(applicationForm.data.attributes.stage),
			(err) => {
				addToast(err.errors ? err.errors[0].title : "An error has occurred", {appearance: "error"})
			}
		)
	}, [updateApplicationFormState])

	let contentComponent
	const currentStageIndicatorComponent = (
		<FormCurrentStageIndicator
			applicationForm={applicationForm}
			applicationFormStage={applicationFormStage}
			updateApplicationForm={updateApplicationFormInternal}
			validatePhoneNumber={validatePhoneNumber}
		/>
	)

	if (!applicationForm) {
		return null
	}

	switch (applicationFormStage) {
		// Type selection
		case ApplicationFormStage.ChooseBusinessOrIndividual: {
			contentComponent = (
				<FormTypeSelection
					applicationForm={applicationForm as ApplicationForm}
					updateApplicationForm={updateApplicationFormInternal}
					selectedFormType={selectedFormType}
					setSelectedFormType={setSelectedFormType}
					isRequestInProgress={isRequestInProgress}
				/>
			)
			break
		}

		// Individual / sole proprietor flow
		case ApplicationFormStage.EnterIndividualInformation: {
			contentComponent = (
				<IndividualInformation
					applicationForm={applicationForm}
					stageTitle={t("individualInformation.pageTitle")}
					stageDescription={t("individualInformation.pageDescription")}
					soleProprietorship={false}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
					validatePhoneNumber={validatePhoneNumber}
				/>
			)
			break
		}

		case ApplicationFormStage.EnterSoleProprietorshipBusinessInformation: {
			contentComponent = (
				<SoleProprietorshipBusinessInformation
					applicationForm={applicationForm}
					stageTitle={t("soleProprietorshipBusinessInformation.pageTitle")}
					stageDescription={t("soleProprietorshipBusinessInformation.pageDescription")}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
				/>
			)
			break
		}

		case ApplicationFormStage.EnterSoleProprietorshipInformation: {
			contentComponent = (
				<IndividualInformation
					applicationForm={applicationForm}
					stageTitle={t("individualInformation.soleProprietorInformation.pageTitle")}
					stageDescription={t("individualInformation.soleProprietorInformation.pageDescription")}
					soleProprietorship={true}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
					validatePhoneNumber={validatePhoneNumber}
				/>
			)
			break
		}

		case ApplicationFormStage.IndividualApplicationCreated:
		case ApplicationFormStage.SoleProprietorshipApplicationCreated: {
			contentComponent = (
				<IndividualApplicationCreated applicationForm={applicationForm} fetchApplicationForm={fetchApplicationForm} />
			)
			break
		}

		case ApplicationFormStage.BusinessPhoneVerification:
		case ApplicationFormStage.IndividualPhoneVerification:
		case ApplicationFormStage.SoleProprietorshipPhoneVerification: {
			contentComponent = (
				<PhoneVerification
					isRequestInProgress={isRequestInProgress}
					updateApplicationForm={updateApplicationFormInternal}
					applicationForm={applicationForm}
				/>
			)
			break
		}

		// Business flow
		case ApplicationFormStage.EnterBusinessInformation: {
			contentComponent = (
				<BusinessInformation
					applicationForm={applicationForm}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
				/>
			)
			break
		}
		case ApplicationFormStage.EnterBusinessAdditionalInformation: {
			contentComponent = (
				<BusinessAdditionalInformation
					applicationForm={applicationForm}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
				/>
			)
			break
		}
		case ApplicationFormStage.EnterOfficerInformation: {
			contentComponent = (
				<OfficerInformation
					applicationForm={applicationForm}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
					validatePhoneNumber={validatePhoneNumber}
				/>
			)
			break
		}
		case ApplicationFormStage.EnterBeneficialOwnersInformation: {
			contentComponent = (
				<BeneficialOwnersInformation
					updateApplicationForm={updateApplicationFormInternal}
					applicationForm={applicationForm}
					isRequestInProgress={isRequestInProgress}
					applicantDetails={applicationForm?.attributes.applicantDetails}
				/>
			)
			break
		}
		case ApplicationFormStage.BusinessApplicationCreated: {
			contentComponent = (
				<BusinessApplicationCreated applicationForm={applicationForm} fetchApplicationForm={fetchApplicationForm} />
			)
			break
		}
	}

	const dataCollectionStatus = applicationForm.attributes.dataCollectionStatus
	if (dataCollectionStatus) {
		if (dataCollectionStatus === "Completed" || !isDataCollectionRequired(applicationForm)) {
			contentComponent = <DataCollectionCompleted />
		} else {
			const stageDescription = `${t("dataCollection.pageDescription")} ${
				applicationForm.attributes.applicationFormSettings.applicationFormContactUsEmail
					? t("dataCollection.pageDescriptionContactUs").replace(
							"{email}",
							applicationForm.attributes.applicationFormSettings.applicationFormContactUsEmail
					  )
					: ""
			}`
			contentComponent = (
				<DataCollection
					applicationForm={applicationForm}
					fetchApplicationForm={fetchApplicationForm}
					updateApplicationForm={updateApplicationFormInternal}
					isRequestInProgress={isRequestInProgress}
					stageTitle={t("dataCollection.pageTitle")}
					stageDescription={stageDescription}
				/>
			)
		}
	}

	const applicationFormSettings = applicationForm.attributes.applicationFormSettings

	const isValidLogo = applicationFormSettings?.applicationFormLogo
		? validateSVG(applicationFormSettings.applicationFormLogo)
		: false
	const logoElement = isValidLogo && (
		<img
			src={isValidLogo ? applicationFormSettings?.applicationFormLogo : UnitLogo}
			alt={applicationFormSettings?.applicationFormBrandName ?? "Unit"}
		/>
	)

	return (
		<>
			<div className="layout-header">{logoElement}</div>
			<div className="layout-content">
				<div
					className={classNames(
						"application-form",
						applicationForm.attributes.hideApplicationProgressTracker && "application-form-central-container"
					)}
				>
					<div className="application-form-container">
						<BackButton
							applicationForm={applicationForm}
							updateApplicationForm={updateApplicationFormInternal}
							validatePhoneNumber={validatePhoneNumber}
						/>
						{contentComponent}
					</div>
					{!applicationForm.attributes.hideApplicationProgressTracker && currentStageIndicatorComponent}
				</div>
			</div>
		</>
	)
}
