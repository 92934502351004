import React, {Dispatch, SetStateAction} from "react"
import classNames from "classnames"
import {ErrorMessage, useFormikContext} from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import {isDateInputSupported} from "../utilities/dom"
import {flattenObject} from "../utilities/object"

interface Props {
	name: string
	label: string
	value: string
	setFieldValue: Function
	onBlur: Dispatch<SetStateAction<any>>
	disabled?: boolean
	hasPrefill?: boolean
}

export default function Datefield({
	name,
	label,
	value,
	setFieldValue,
	onBlur,
	disabled = false,
	hasPrefill = false,
}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"textfield",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className} data-name={name} {...(hasPrefill && {"data-has-prefill": true})}>
			<label>
				<span className="label-text">{label}</span>
				{isDateInputSupported() ? (
					<input
						name={name}
						type="date"
						value={value}
						onBlur={onBlur}
						max={moment().format("YYYY-MM-DD")}
						min="1900-01-01"
						disabled={disabled}
						onChange={(input) => {
							if (input.currentTarget.value) {
								setFieldValue(name, input.currentTarget.value)
							}
						}}
					/>
				) : (
					<DatePicker
						name={name}
						maxDate={new Date()}
						selected={value === "" ? null : moment(value, "YYYY-MM-DD").toDate()}
						onChange={(date: Date) => {
							if (date) {
								setFieldValue(name, moment(date).format("YYYY-MM-DD"))
							}
						}}
						popperModifiers={[
							{
								name: "preventOverflow",
								options: {
									mainAxis: false,
								},
							},
						]}
						onBlur={onBlur}
						showMonthDropdown
						showYearDropdown
						disabled={disabled}
						dropdownMode="select"
					/>
				)}
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
