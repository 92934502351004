import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import {Integrations} from "@sentry/tracing"
import App from "./App"

export const envName = process.env.ENV_NAME

if (envName && envName !== "Local") {
	Sentry.init({
		dsn: "https://7036c65345974fe38243fcfe835f30c3@o1071400.ingest.sentry.io/6068805",
		integrations: [new Integrations.BrowserTracing()],
		environment: envName,
	})
}

ReactDOM.render(<App />, document.getElementById("app"))

if (envName != null) document.title = document.title + " - " + envName

if (module.hot) module.hot.accept()
