import React, {useState} from "react"
import {ApplicationDocument, ApplicationForm} from "../../resources/applicationForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckCircle, faHourglassStart, faTimesCircle} from "@fortawesome/free-solid-svg-icons"
import UploadDocument from "./UploadDocument"
import {lowerFirst} from "lodash"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import ReactTooltip from "react-tooltip"
import {Trans, useTranslation} from "react-i18next"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
}

export default function ApplicationDocuments({applicationForm, fetchApplicationForm}: Props) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}
	const [documents, setDocuments] = useState(applicationForm.attributes.applicationFormDocuments)

	if (!applicationForm.attributes.applicationFormDocuments) {
		return null
	}

	function updateDocument(document: ApplicationDocument) {
		const updatedDocuments = documents.map((doc) => {
			if (doc.id === document.id) {
				return {...doc, ...document}
			}
			return doc
		})
		setDocuments(updatedDocuments)

		// Re-fetching the application form when there are no more documents required to upload since the application
		// status is very likely to change (will at least be "PendingReview" instead of "AwaitingDocuments")
		if (updatedDocuments.filter((doc) => doc.attributes.status === "Required").length === 0) {
			fetchApplicationForm()
		}
	}

	function documentRequiredForComponents(document: ApplicationDocument) {
		const clientName = document.attributes.name
		const requiredForTitle = (
			<Trans i18nKey="applicationDocuments.form.required">
				{t("applicationDocuments.form.required")} {{clientName}}
			</Trans>
		)

		let requiredForDetails

		switch (document.attributes.documentType) {
			case "IdDocument":
			case "SocialSecurityCard": {
				requiredForDetails = `${t(
					"applicationDocuments.documentTypes.ssn.shortKey"
				)}: ${document.attributes.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3")}`
				break
			}
			case "AddressVerification": {
				requiredForDetails = `${t("applicationDocuments.documentTypes.addressVerification.shortKey")}: ${[
					document.attributes.address.street,
					document.attributes.address.street2,
					document.attributes.address.city,
					document.attributes.address.state,
					document.attributes.address.postalCode,
				]
					.filter((item) => item)
					.join(", ")}`
				break
			}

			case "EmployerIdentificationNumberConfirmation": {
				requiredForDetails = `${t(
					"applicationDocuments.documentTypes.employerIdentificationNumberConfirmation.shortKey"
				)}: ${document.attributes.ein.replace(/(\d{2})(\d{7})/, "$1-$2")}`
				break
			}
			case "Passport": {
				requiredForDetails = `${t("applicationDocuments.documentTypes.passport.shortKey")}: ${
					document.attributes.passport
				}`
				break
			}
		}

		return {requiredForTitle, requiredForDetails}
	}

	const documentComponents = documents.map((doc) => {
		const uploadComponent =
			doc.attributes.documentType === "IdDocument" ? (
				<>
					<UploadDocument
						document={doc}
						title={t("applicationDocuments.form.uploadFront")}
						isBackDocument={false}
						updateDocument={updateDocument}
					/>
					<UploadDocument
						document={doc}
						title={t("applicationDocuments.form.uploadBack")}
						isBackDocument={true}
						updateDocument={updateDocument}
					/>
				</>
			) : (
				<>
					<UploadDocument
						document={doc}
						title={t("applicationDocuments.form.uploadDocument")}
						isBackDocument={false}
						updateDocument={updateDocument}
					/>
				</>
			)

		let docStatusComponent
		switch (doc.attributes.status) {
			case "Approved": {
				docStatusComponent = (
					<div className="document-approved">
						<FontAwesomeIcon className={"icon"} icon={faCheckCircle} />{" "}
						{t("applicationDocuments.documentStatuses.approved")}
					</div>
				)
				break
			}
			case "PendingReview": {
				docStatusComponent = (
					<div className="document-pending-review">
						<FontAwesomeIcon className={"icon"} icon={faHourglassStart} />{" "}
						{t("applicationDocuments.documentStatuses.pendingReview")}
					</div>
				)
				break
			}
			case "Invalid": {
				docStatusComponent = (
					<div className="document-invalid">
						<FontAwesomeIcon className={"icon"} icon={faTimesCircle} />{" "}
						{t("applicationDocuments.documentStatuses.invalid")}
						<div className="reason">{`${doc.attributes.reason}`}</div>
						{uploadComponent}
					</div>
				)
				break
			}
			case "ReceivedBack":
			case "ReceivedFront":
			case "Required": {
				docStatusComponent = uploadComponent
				break
			}
		}

		const requiredForComponents = documentRequiredForComponents(doc)
		const docType = doc.attributes.documentType

		return (
			<div key={docType} className="doc">
				<div className="doc-details">
					<div className="doc-type">
						{t(`applicationDocuments.documentTypes.${lowerFirst(docType)}.title`)}{" "}
						{requiredForComponents.requiredForTitle}
						{requiredForComponents.requiredForDetails ? (
							<>
								{" "}
								<FontAwesomeIcon
									className={"tooltip-icon"}
									data-tip={requiredForComponents.requiredForDetails}
									icon={faQuestionCircle}
								/>
							</>
						) : null}
					</div>
					<div className="description">
						{t(`applicationDocuments.documentTypes.${lowerFirst(docType)}.description`)}
					</div>
				</div>
				<div className="doc-status">{docStatusComponent}</div>
			</div>
		)
	})

	return (
		<div className="application-documents">
			{documentComponents}
			<ReactTooltip effect={"solid"} className={"tooltip"} />
		</div>
	)
}
