import React from "react"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {IconDefinition} from "@fortawesome/fontawesome-svg-core"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"

interface Props {
	type: "is-warning" | "is-danger" | "is-info" | "is-security-info"
	message?: string | React.ReactNode
	title?: string
	icon?: IconDefinition
}

export default function Admonition({type, message, title = "", icon = faInfoCircle}: Props) {
	return (
		<article className={classNames("admonition", type)}>
			<div className="message-body admonition-message-body">
				<div className="admonition-message-body-title">
					<div className="admonition-message-body-title-icon">
						<FontAwesomeIcon icon={icon} />
					</div>
					<div className="admonition-message-body-title-text"> {title} </div>
				</div>
				<div className="admonition-message-body-container">{message}</div>
			</div>
		</article>
	)
}
