type AnyObject = Record<string, any>

export function flattenObject(obj: AnyObject, prefix = "", result: AnyObject = {}) {
	// Preserve empty objects and arrays, they are lost otherwise
	if (prefix && typeof obj === "object" && obj !== null && Object.keys(obj).length === 0) {
		result[prefix] = Array.isArray(obj) ? [] : {}
		return result
	}

	prefix = prefix ? prefix + "." : ""

	for (const i in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, i)) {
			if (typeof obj[i] === "object" && obj[i] !== null) {
				// Recursion on deeper objects
				flattenObject(obj[i], prefix + i, result)
			} else {
				result[prefix + i] = obj[i]
			}
		}
	}
	return result
}

function insertToObject(obj: AnyObject, key: string, value: any): AnyObject {
	obj[key] = value
	return obj
}

export function removeEmptyObjectAttributes(obj: object, skipFields: string[]): object {
	if (Array.isArray(obj)) {
		return obj
			.map((v) => (v && typeof v === "object" ? removeEmptyObjectAttributes(v, skipFields) : v))
			.filter((v) => !(v == null))
	} else {
		return Object.entries(obj)
			.map(([k, v]) => [k, v && typeof v === "object" ? removeEmptyObjectAttributes(v, skipFields) : v])
			.reduce((a: AnyObject, [k, v]) => (!skipFields.includes(k) && v == null ? a : insertToObject(a, k, v)), {})
	}
}

export function filterNotUrlValuesFromMap(map: Map<string, string | undefined>): Map<string, string> {
	const filteredMap = new Map<string, string>()
	const urlPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/

	Array.from(map).forEach(([key, value]) => {
		if (value && urlPattern.test(value)) {
			filteredMap.set(key, value)
		}
	})

	return filteredMap
}
