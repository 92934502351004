import React, {PropsWithChildren} from "react"
import {ApplicationForm, ApplicationFormType} from "../../resources/applicationForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faUser} from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import {isUndefined, camelCase} from "lodash"
import ApplicationTypeButtonText from "./ApplicationTypeButtonText"

interface Props {
	applicationForm: ApplicationForm
	selectedFormType?: ApplicationFormType
	setSelectedFormType: Function
	applicationFormType: ApplicationFormType
}

export default function ApplicationTypeButton({
	applicationForm,
	selectedFormType,
	setSelectedFormType,
	applicationFormType,
}: PropsWithChildren<Props>) {
	const isAllowedApplicationTypesExist = isUndefined(applicationForm.attributes.allowedApplicationTypes)
	const isApplicationTypeInAllowedApplicationTypes =
		applicationForm.attributes.allowedApplicationTypes?.includes(applicationFormType)

	return (
		<>
			{isAllowedApplicationTypesExist || isApplicationTypeInAllowedApplicationTypes ? (
				<label
					className={classNames(
						"form-type",
						camelCase(applicationFormType),
						selectedFormType === applicationFormType && "selected"
					)}
					onClick={() => setSelectedFormType(applicationFormType)}
				>
					<div className="icon">
						<FontAwesomeIcon icon={faUser} />
					</div>
					<ApplicationTypeButtonText applicationFormType={applicationFormType} />
				</label>
			) : null}
		</>
	)
}
