import React, {useEffect, useState} from "react"
import {ApplicationForm, ApplicationFormStatus} from "../../resources/applicationForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
	faCheckCircle,
	faCircleNotch,
	faFileUpload,
	faHourglassStart,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import ApplicationDocuments from "./ApplicationDocuments"
import Button from "../Button"
import {useInterval} from "react-use"
import {useTranslation, Trans} from "react-i18next"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
}

const MAX_PENDING_STATUS_CHECK_ATTEMPTS = 12
const PENDING_STATUS_CHECK_INTERVAL = 5000

export default function ApplicationStatus({applicationForm, fetchApplicationForm}: Props) {
	const {t} = useTranslation()
	const [pendingStatusCheckActive, setPendingStatusCheckActive] = useState(
		applicationForm.attributes.status === ApplicationFormStatus.Pending
	)
	const [pendingStatusCheckAttempts, setPendingStatusCheckAttempts] = useState(0)

	let status
	let backToSiteButton
	const redirectUrl =
		applicationForm.attributes.settingsOverride?.redirectUrl ??
		applicationForm.attributes.applicationFormSettings.applicationFormRedirectUrl
	const redirectButtonText = applicationForm.attributes.applicationFormSettings.applicationFormRedirectButtonText

	if (redirectUrl) {
		backToSiteButton = (
			<a className={"form-button"} href={redirectUrl} target="_self" type="submit">
				{redirectButtonText ?? "Continue"}
			</a>
		)
	}

	function initPendingStatusCheck() {
		setPendingStatusCheckAttempts(0)
		setPendingStatusCheckActive(true)
	}

	useInterval(
		() => {
			if (pendingStatusCheckAttempts >= MAX_PENDING_STATUS_CHECK_ATTEMPTS) {
				setPendingStatusCheckActive(false)
			} else {
				fetchApplicationForm()
				setPendingStatusCheckAttempts(pendingStatusCheckAttempts + 1)
			}
		},
		pendingStatusCheckActive ? PENDING_STATUS_CHECK_INTERVAL : null
	)

	useEffect(() => {
		if (applicationForm.attributes.status === ApplicationFormStatus.Pending) {
			initPendingStatusCheck()
		}
	}, [])

	useEffect(() => {
		if (applicationForm.attributes.status !== ApplicationFormStatus.Pending) {
			setPendingStatusCheckActive(false)
		}
	}, [applicationForm.attributes.status])

	useEffect(() => {
		window.parent.postMessage(`applicationForm${applicationForm.attributes.status}`, "*")
	}, [applicationForm.attributes.status])

	switch (applicationForm.attributes.status) {
		case ApplicationFormStatus.Approved: {
			status = (
				<div className={"approved"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faCheckCircle} />{" "}
						{t("applicationDocuments.formStatuses.approved.pageTitle")}
					</h1>
					<p>{t("applicationDocuments.formStatuses.approved.pageDescription")}</p>
					{backToSiteButton}
				</div>
			)
			break
		}
		case ApplicationFormStatus.Denied: {
			status = (
				<div className={"denied"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faTimesCircle} />{" "}
						{t("applicationDocuments.formStatuses.denied.pageTitle")}
					</h1>
					<p>{t("applicationDocuments.formStatuses.denied.pageDescription")}</p>
					{backToSiteButton}
				</div>
			)

			break
		}
		case ApplicationFormStatus.Canceled: {
			status = (
				<div className={"denied"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faTimesCircle} />{" "}
						{t("applicationDocuments.formStatuses.canceled.pageTitle")}
					</h1>
					<p>{t("applicationDocuments.formStatuses.canceled.pageDescription")}</p>
					{backToSiteButton}
				</div>
			)

			break
		}
		case ApplicationFormStatus.Pending: {
			status = (
				<div className={"pending"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faHourglassStart} />{" "}
						{t("applicationDocuments.formStatuses.pending.pageTitle")}
					</h1>
					<p>
						{pendingStatusCheckActive ? (
							<>{t("applicationDocuments.formStatuses.pending.pageDescription")}</>
						) : (
							<>
								{t("applicationDocuments.formStatuses.pending.delayMessage")}
								{applicationForm.attributes.applicationFormSettings.applicationFormContactUsEmail ? (
									<>
										<Trans
											i18nKey="applicationDocuments.formStatuses.pending.contactSupport"
											components={{
												url: (
													<a
														href={`mailto:${applicationForm.attributes.applicationFormSettings.applicationFormContactUsEmail}`}
													/>
												),
											}}
										/>
									</>
								) : null}
							</>
						)}
					</p>
					{pendingStatusCheckActive ? (
						<div className="loading">
							<div>{t("applicationDocuments.formStatuses.pending.loading")}</div>
							<FontAwesomeIcon icon={faCircleNotch} spin />
						</div>
					) : (
						<div className="buttons">
							<Button className={"try-again-button"} type="submit" onClick={initPendingStatusCheck}>
								{" "}
								{t("applicationDocuments.formStatuses.pending.reTry")}
							</Button>
							{backToSiteButton}
						</div>
					)}
				</div>
			)

			break
		}
		case ApplicationFormStatus.PendingReview: {
			status = (
				<div className={"pending-review"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faHourglassStart} />{" "}
						{t("applicationDocuments.formStatuses.pendingReview.pageTitle")}
					</h1>
					<p>{t("applicationDocuments.formStatuses.pendingReview.pageDescription")}</p>
					<ApplicationDocuments applicationForm={applicationForm} fetchApplicationForm={fetchApplicationForm} />
					{backToSiteButton}
				</div>
			)

			break
		}
		case ApplicationFormStatus.AwaitingDocuments: {
			status = (
				<div className={"awaiting-documents"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faFileUpload} />{" "}
						{t("applicationDocuments.formStatuses.awaitingDocuments.pageTitle")}
					</h1>
					<p>{t("applicationDocuments.formStatuses.awaitingDocuments.pageDescription")}</p>
					<ApplicationDocuments applicationForm={applicationForm} fetchApplicationForm={fetchApplicationForm} />
				</div>
			)
		}
	}

	return <form className="application-status">{status}</form>
}
