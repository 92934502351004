import React from "react"
import {focusFirstInvalidField} from "../../../utilities/dom"
import Dropdown from "../../../components/Dropdown"
import {US_COUNTRY_CODE} from "../../../resources/common"
import SingleSelection from "../../../components/SingleSelection"
import Button from "../../../components/Button"
import {Formik, FormikValues} from "formik"
import {
	ApplicationForm,
	getBusinessVerticals,
	getSoleProprietorshipAnnualRevenues,
	getSoleProprietorshipNumberOfEmployees,
} from "../../../resources/applicationForm"
import * as Yup from "yup"
import {useTranslation} from "react-i18next"
import {urlRegex} from "../../../utilities/validation"
import Textfield from "../../../components/Textfield"
import Checkbox from "../../../components/Checkbox"
import {isUndefined} from "lodash"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
	updateApplicationForm: Function
	isRequestInProgress: boolean
}

export default function DataCollectionSoleProp({
	applicationForm,
	updateApplicationForm,
	fetchApplicationForm,
	isRequestInProgress,
}: Props) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}
	const soleProprietorshipAnnualRevenues = getSoleProprietorshipAnnualRevenues()
	const businessVerticals = getBusinessVerticals()
	const soleProprietorshipNumberOfEmployees = getSoleProprietorshipNumberOfEmployees()
	const cddEddAttributes = applicationForm.attributes.cddEddAttributes
	const solePropNationality = cddEddAttributes?.nationality ?? "US"
	const isHighRisk = applicationForm.attributes.customerRiskRate == "high"
	const isEddRequired = solePropNationality !== US_COUNTRY_CODE || isHighRisk

	const validationSchema = Yup.object().shape(
		{
			businessVertical: Yup.string()
				.required(t("validationErrorMessages.businessVertical.required"))
				.oneOf(Object.keys(businessVerticals), t("validationErrorMessages.businessVertical.invalid")),
			annualRevenue: Yup.string()
				.oneOf(Object.keys(soleProprietorshipAnnualRevenues), t("validationErrorMessages.annualRevenue.invalid"))
				.when([], {
					is: () => isEddRequired,
					then: Yup.string().required(t("validationErrorMessages.annualRevenue.required")),
				}),
			numberOfEmployees: Yup.string()
				.oneOf(Object.keys(soleProprietorshipNumberOfEmployees), t("validationErrorMessages.numberOfEmployees.invalid"))
				.when([], {
					is: () => isEddRequired,
					then: Yup.string().required(t("validationErrorMessages.numberOfEmployees.required")),
				}),
			website: Yup.string().when("hasNoWebsite", {
				is: (hasNoWebsite?: boolean) => !hasNoWebsite,
				then: Yup.string().matches(urlRegex, t("validationErrorMessages.website.invalid")),
			}),
			hasNoWebsite: Yup.boolean().when("website", {
				is: (website?: string) => isUndefined(website),
				then: Yup.boolean().oneOf([true], t("validationErrorMessages.hasNoWebsite.required")),
			}),
		},
		[["website", "hasNoWebsite"]]
	) // takes care of cyclic dependency

	const initialFormValues = {
		businessVertical: cddEddAttributes?.businessVertical ?? "",
		annualRevenue: cddEddAttributes?.annualRevenue ?? "",
		numberOfEmployees: cddEddAttributes?.numberOfEmployees ?? "",
		website: cddEddAttributes?.website ?? "",
		hasNoWebsite: !cddEddAttributes?.website,
	}

	const isInitialWebsiteValueValid = () => {
		return Yup.reach(validationSchema, "website").isValidSync(cddEddAttributes?.website)
	}

	const submitForm = async (values: FormikValues) => {
		await updateApplicationForm("applicationFormCddEddAttributes", {
			businessVertical: values.businessVertical || null,
			website: !values.hasNoWebsite && values.website ? values.website : null,
			...(isEddRequired
				? {
						annualRevenue: values.annualRevenue || null,
						numberOfEmployees: values.numberOfEmployees || null,
				  }
				: {}),
		})
		fetchApplicationForm()
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validateOnMount={true}
			validationSchema={validationSchema}
			onSubmit={(values) => submitForm(values)}
		>
			{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
				<form
					noValidate
					onSubmit={(e) => {
						e.preventDefault()
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
				>
					{isUndefined(cddEddAttributes?.businessVertical) && (
						<Dropdown
							name={"businessVertical"}
							label={t("soleProprietorshipBusinessInformation.form.businessVertical")}
							value={values.businessVertical}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							<option key="default" defaultValue="" />
							{Object.keys(businessVerticals).map((businessVertical) => (
								<option key={businessVertical} value={businessVertical}>
									{businessVerticals[businessVertical]}
								</option>
							))}
						</Dropdown>
					)}

					{isEddRequired && (
						<>
							{isUndefined(cddEddAttributes?.annualRevenue) && (
								<SingleSelection
									name={"annualRevenue"}
									label={t("soleProprietorshipBusinessInformation.form.annualRevenue")}
									value={values.annualRevenue}
									setFieldValue={setFieldValue}
									options={soleProprietorshipAnnualRevenues}
								/>
							)}

							{isUndefined(cddEddAttributes?.numberOfEmployees) && (
								<SingleSelection
									name={"numberOfEmployees"}
									label={t("soleProprietorshipBusinessInformation.form.numberOfEmployees")}
									value={values.numberOfEmployees}
									setFieldValue={setFieldValue}
									options={soleProprietorshipNumberOfEmployees}
								/>
							)}
						</>
					)}

					{(!cddEddAttributes?.website || !isInitialWebsiteValueValid()) && (
						<>
							<Textfield
								name="website"
								label={t("soleProprietorshipBusinessInformation.form.website")}
								value={values.website}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={values.hasNoWebsite}
							/>
							<Checkbox
								name="hasNoWebsite"
								checked={values.hasNoWebsite}
								onChange={handleChange}
								onBlur={handleBlur}
								text={t("soleProprietorshipBusinessInformation.form.hasNoWebsite")}
							/>
						</>
					)}

					<Button type="submit" isLoading={isRequestInProgress}>
						{t("dataCollection.submit")}
					</Button>
				</form>
			)}
		</Formik>
	)
}
