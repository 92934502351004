import React, {PropsWithChildren} from "react"
import Textfield from "../../components/Textfield"
import Address from "../../components/Address"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import {
	addCustomValidationMethods,
	caPostalCode,
	ssnInputMask,
	ssnRegex,
	usPostalCode,
} from "../../utilities/validation"
import {parsePhoneNumber} from "libphonenumber-js"
import {sanitizeNumberFormat} from "../../utilities/form"
import {
	ApplicantDetails,
	ApplicationForm,
	countries,
	getAnnualIncomes,
	getOccupations,
	getSourcesOfIncome,
} from "../../resources/applicationForm"
import {CA_COUNTRY_CODE, formatPhoneNumber, toPhoneNumber, US_COUNTRY_CODE} from "../../resources/common"
import Button from "../../components/Button"
import Datefield from "../../components/Datefield"
import Dropdown from "../../components/Dropdown"
import {Trans, useTranslation} from "react-i18next"
import VisaNotice from "../../components/ApplicationForm/VisaNotice"
import SingleSelection from "../../components/SingleSelection"
import AgreementCheckbox from "../../components/ApplicationForm/AgreementCheckbox"
import {isUndefined} from "lodash"
import moment from "moment"

addCustomValidationMethods()

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	isRequestInProgress: boolean
	stageTitle: string
	stageDescription: string
	soleProprietorship: boolean
	applicantDetails?: ApplicantDetails
	validatePhoneNumber?: boolean
}

export default function IndividualInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	stageTitle,
	stageDescription,
	soleProprietorship,
	children,
	applicantDetails,
	validatePhoneNumber,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const annualIncomes = getAnnualIncomes()
	const occupations = getOccupations()
	const sourcesOfIncome = getSourcesOfIncome()

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required(t("validationErrorMessages.firstName.required")),
		lastName: Yup.string().required(t("validationErrorMessages.lastName.required")),
		ssn: Yup.string().when("nationality", {
			is: US_COUNTRY_CODE,
			then: Yup.string()
				.required(t("validationErrorMessages.ssn.required"))
				.matches(ssnRegex, t("validationErrorMessages.ssn.invalid")),
		}),
		nationality: Yup.string().required(t("validationErrorMessages.nationality.required")),
		passport: Yup.string().when("nationality", {
			is: (nationality: string) => nationality !== US_COUNTRY_CODE,
			then: Yup.string().required(t("validationErrorMessages.passport.required")),
		}),
		street: Yup.string().required(t("validationErrorMessages.street.required")),
		// street2:
		city: Yup.string().required(t("validationErrorMessages.city.required")),
		state: Yup.string().when("country", {
			is: (country: string) => [US_COUNTRY_CODE, CA_COUNTRY_CODE].includes(country),
			then: Yup.string().required(t("validationErrorMessages.state.required")),
		}),
		postalCode: Yup.string()
			.required(t("validationErrorMessages.postalCode.required"))
			.when("country", {
				is: US_COUNTRY_CODE,
				then: Yup.string().matches(usPostalCode, t("validationErrorMessages.postalCode.usInvalid")),
			})
			.when("country", {
				is: CA_COUNTRY_CODE,
				then: Yup.string().matches(caPostalCode, t("validationErrorMessages.postalCode.caInvalid")),
			}),
		country: Yup.string().required(t("validationErrorMessages.country.required")),
		dateOfBirth: Yup.date()
			.required(t("validationErrorMessages.dateOfBirth.required"))
			.max(moment().subtract(18, "years"), t("validationErrorMessages.dateOfBirth.max"))
			.test("is-valid-year", t("validationErrorMessages.dateOfBirth.invalid"), function (value) {
				const year = moment(value).year()

				//matches ^([1-9][0-9]{0,3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$
				return year >= 1000 && year <= 9999
			}),
		email: Yup.string()
			.email(t("validationErrorMessages.email.invalid"))
			.required(t("validationErrorMessages.email.required")),
		phone: Yup.string()
			.required(t("validationErrorMessages.phone.required"))
			.phoneNumber(t("validationErrorMessages.phone.invalid")),
		occupation: Yup.string()
			.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid"))
			.when([], {
				is: () => !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.occupation.required")),
			}),
		annualIncome: Yup.string()
			.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
			.when("nationality", {
				is: (nationality: string) =>
					nationality !== US_COUNTRY_CODE && !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
			}),
		sourceOfIncome: Yup.string()
			.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
			.when("nationality", {
				is: (nationality: string) =>
					nationality !== US_COUNTRY_CODE && !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
			}),
		agreement: Yup.boolean().when({
			is: () => !soleProprietorship,
			then: Yup.boolean().oneOf([true], t("validationErrorMessages.agreement.required")),
		}),
	})

	const settingsOverride = applicationForm.attributes.settingsOverride
	const applicationFormSettings = applicationForm.attributes.applicationFormSettings
	const privacyPolicyUrl = settingsOverride?.privacyPolicyUrl ?? applicationFormSettings.applicationFormPrivacyPolicyUrl

	const stateIndividualInformation = applicationForm.attributes.state?.enterIndividualInformation
	const phone = stateIndividualInformation?.phone ?? applicantDetails?.phone
	const initialFormValues = {
		firstName: stateIndividualInformation?.fullName?.first ?? applicantDetails?.fullName?.first ?? "",
		lastName: stateIndividualInformation?.fullName?.last ?? applicantDetails?.fullName?.last ?? "",
		phone: phone ? toPhoneNumber(phone) : "",
		email: stateIndividualInformation?.email ?? applicantDetails?.email ?? "",
		dateOfBirth: stateIndividualInformation?.dateOfBirth ?? applicantDetails?.dateOfBirth ?? "",
		nationality: stateIndividualInformation?.nationality ?? applicantDetails?.nationality ?? US_COUNTRY_CODE,
		ssn: stateIndividualInformation?.ssn ?? applicantDetails?.ssn ?? "",
		passport: stateIndividualInformation?.passport ?? applicantDetails?.passport ?? "",
		street: stateIndividualInformation?.address?.street ?? applicantDetails?.address?.street ?? "",
		street2: stateIndividualInformation?.address?.street2 ?? applicantDetails?.address?.street2 ?? "",
		city: stateIndividualInformation?.address?.city ?? applicantDetails?.address?.city ?? "",
		country: stateIndividualInformation?.address?.country ?? applicantDetails?.address?.country ?? US_COUNTRY_CODE,
		state: stateIndividualInformation?.address?.state ?? applicantDetails?.address?.state ?? "",
		postalCode: stateIndividualInformation?.address?.postalCode ?? applicantDetails?.address?.postalCode ?? "",
		occupation: stateIndividualInformation?.occupation ?? applicantDetails?.occupation ?? "",
		annualIncome: stateIndividualInformation?.annualIncome ?? applicantDetails?.annualIncome ?? "",
		sourceOfIncome: stateIndividualInformation?.sourceOfIncome ?? applicantDetails?.sourceOfIncome ?? "",
		agreement: false,
	}

	const prefillExistsForField = {
		fullName: !isUndefined(applicantDetails?.fullName),
		phone: !isUndefined(applicantDetails?.phone),
		email: !isUndefined(applicantDetails?.email),
		dateOfBirth: !isUndefined(applicantDetails?.dateOfBirth),
		nationality: !isUndefined(applicantDetails?.nationality),
		ssn: !isUndefined(applicantDetails?.ssn),
		passport: !isUndefined(applicantDetails?.passport),
		address: !isUndefined(applicantDetails?.address),
		occupation: !isUndefined(applicantDetails?.occupation),
		annualIncome: !isUndefined(applicantDetails?.annualIncome),
		sourceOfIncome: !isUndefined(applicantDetails?.sourceOfIncome),
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormIndividualInformation", {
			fullName: {
				first: values.firstName,
				last: values.lastName,
			},
			ssn: values.nationality === US_COUNTRY_CODE ? sanitizeNumberFormat(values.ssn) : null,
			nationality: values.nationality,
			passport: values.nationality !== US_COUNTRY_CODE ? values.passport : null,
			address: {
				street: values.street,
				street2: values.street2,
				city: values.city,
				state: values.state,
				postalCode: values.postalCode,
				country: values.country,
			},
			dateOfBirth: values.dateOfBirth,
			email: values.email,
			phone: {
				countryCode: parsePhoneNumber(values.phone, US_COUNTRY_CODE).countryCallingCode,
				number: parsePhoneNumber(values.phone, US_COUNTRY_CODE).nationalNumber,
			},
			soleProprietorship: soleProprietorship,
			occupation: values.occupation || null,
			...(values.nationality !== US_COUNTRY_CODE
				? {
						annualIncome: values.annualIncome || null,
						sourceOfIncome: values.sourceOfIncome || null,
				  }
				: {}),
		})
	}

	return (
		<div className="individual-information">
			<h1>{stageTitle}</h1>
			<p>{stageDescription}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<div className="form-row two">
							<Textfield
								name="firstName"
								label={t("individualInformation.form.firstName")}
								value={values.firstName}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.fullName}
								disabled={isForcePrefill && prefillExistsForField.fullName}
							/>
							<Textfield
								name="lastName"
								label={t("individualInformation.form.lastName")}
								value={values.lastName}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.fullName}
								disabled={isForcePrefill && prefillExistsForField.fullName}
							/>
						</div>
						<Textfield
							name="phone"
							label={t("individualInformation.form.phone")}
							type={"tel"}
							value={formatPhoneNumber(values.phone)}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.phone}
							disabled={!validatePhoneNumber || (isForcePrefill && prefillExistsForField.phone)}
							infoTooltipText={
								"This phone number is the primary contact number, and will occasionally be used to verify your identity and protect your account"
							}
							showInfoTooltip={!validatePhoneNumber}
						/>
						<Textfield
							name="email"
							label={t("individualInformation.form.email")}
							type={"email"}
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.email}
							disabled={isForcePrefill && prefillExistsForField.email}
						/>
						<div className="form-row two">
							<Datefield
								name="dateOfBirth"
								label={t("individualInformation.form.dateOfBirth")}
								value={values.dateOfBirth}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.dateOfBirth}
								disabled={isForcePrefill && prefillExistsForField.dateOfBirth}
							/>
							<Dropdown
								name={"nationality"}
								label={t("individualInformation.form.nationality")}
								value={values.nationality}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.nationality}
								disabled={isForcePrefill && (prefillExistsForField.ssn || prefillExistsForField.nationality)}
							>
								{Object.keys(countries).map((countryCode) => (
									<option key={countryCode} value={countryCode}>
										{countries[countryCode]}
									</option>
								))}
							</Dropdown>
						</div>
						<div className="form-row two">
							{values.nationality === US_COUNTRY_CODE ? (
								<Textfield
									name="ssn"
									label={t("individualInformation.form.ssn")}
									value={values.ssn}
									inputMask={ssnInputMask}
									onChange={handleChange}
									onBlur={handleBlur}
									showInfoTooltip={true}
									infoTooltipText={t("individualInformation.form.ssnTooltip")}
									hasPrefill={prefillExistsForField.ssn}
									disabled={isForcePrefill && prefillExistsForField.ssn}
								/>
							) : (
								<Textfield
									name="passport"
									label={t("individualInformation.form.passport")}
									value={values.passport}
									onChange={handleChange}
									onBlur={handleBlur}
									showInfoTooltip={true}
									infoTooltipText={t("individualInformation.form.passportTooltip")}
									hasPrefill={prefillExistsForField.passport}
									disabled={isForcePrefill && prefillExistsForField.passport}
								/>
							)}
						</div>
						<Address
							addressType={"Individual"}
							street={values.street}
							street2={values.street2}
							city={values.city}
							country={values.country}
							state={values.state}
							postalCode={values.postalCode}
							isUsOnlyAddress={true}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							hasPrefill={prefillExistsForField.address}
							disabled={isForcePrefill && prefillExistsForField.address}
						/>

						<Dropdown
							name={"occupation"}
							label={t("individualAdditionalInformation.form.occupation")}
							value={values.occupation}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.occupation}
							disabled={isForcePrefill && prefillExistsForField.occupation}
						>
							<option key="default" defaultValue="" />
							{Object.keys(occupations).map((occupation) => (
								<option key={occupation} value={occupation}>
									{occupations[occupation]}
								</option>
							))}
						</Dropdown>
						{values.nationality !== US_COUNTRY_CODE && (
							<>
								<SingleSelection
									name={"annualIncome"}
									label={t("individualAdditionalInformation.form.annualIncome")}
									value={values.annualIncome}
									setFieldValue={setFieldValue}
									options={annualIncomes}
									hasPrefill={prefillExistsForField.annualIncome}
									disabled={isForcePrefill && prefillExistsForField.annualIncome}
								/>

								<Dropdown
									name={"sourceOfIncome"}
									label={t("individualAdditionalInformation.form.sourceOfIncome")}
									value={values.sourceOfIncome}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.sourceOfIncome}
									disabled={isForcePrefill && prefillExistsForField.sourceOfIncome}
								>
									<option key="default" defaultValue="" />
									{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
										<option key={sourceOfIncome} value={sourceOfIncome}>
											{sourcesOfIncome[sourceOfIncome]}
										</option>
									))}
								</Dropdown>
							</>
						)}

						{privacyPolicyUrl && (
							<p>
								<Trans
									i18nKey="individualInformation.form.privacyNotice"
									components={{
										url: <a className={"no-before-unload-warning"} href={privacyPolicyUrl} />,
									}}
								/>
							</p>
						)}

						{!soleProprietorship && (
							<AgreementCheckbox
								setFieldValue={setFieldValue}
								applicationForm={applicationForm}
								agreement={values.agreement}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						)}

						<Button type="submit" isLoading={isRequestInProgress}>
							{soleProprietorship ? t("individualInformation.form.continue") : t("individualInformation.form.submit")}
						</Button>

						{applicationFormSettings?.applicationFormDebitCardDisclosureUrl ? (
							<VisaNotice applicationForm={applicationForm} />
						) : null}
					</form>
				)}
			</Formik>
			{children}
		</div>
	)
}
