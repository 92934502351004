import React, {PropsWithChildren} from "react"
import {FieldArray, Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import Dropdown from "../../components/Dropdown"
import Textfield from "../../components/Textfield"
import Address from "../../components/Address"
import {
	addCustomValidationMethods,
	caPostalCode,
	ssnInputMask,
	ssnRegex,
	usPostalCode,
} from "../../utilities/validation"
import {parsePhoneNumber} from "libphonenumber-js"
import {sanitizeNumberFormat} from "../../utilities/form"
import {
	ApplicantDetails,
	ApplicationForm,
	countries,
	getAnnualIncomes,
	getOccupations,
	getSourcesOfIncome,
} from "../../resources/applicationForm"
import {CA_COUNTRY_CODE, formatPhoneNumber, toPhoneNumber, US_COUNTRY_CODE} from "../../resources/common"
import AgreementCheckbox from "../../components/ApplicationForm/AgreementCheckbox"
import Button from "../../components/Button"
import Datefield from "../../components/Datefield"
import VisaNotice from "../../components/ApplicationForm/VisaNotice"
import {useTranslation} from "react-i18next"
import SingleSelection from "../../components/SingleSelection"
import {isUndefined} from "lodash"
import moment from "moment"

addCustomValidationMethods()

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	isRequestInProgress: boolean
	applicantDetails?: ApplicantDetails
}

interface BeneficialOwner {
	firstName: string
	lastName: string
	ssn: string
	nationality: string
	passport: string
	street: string
	street2: string
	city: string
	state: string
	postalCode: string
	country: string
	dateOfBirth: string
	email: string
	phone: string
	percentage: number
	occupation: string
	annualIncome: string
	sourceOfIncome: string
}

const emptyBeneficialOwnerObject = {
	firstName: "",
	lastName: "",
	ssn: "",
	nationality: US_COUNTRY_CODE,
	passport: "",
	street: "",
	street2: "",
	city: "",
	state: "",
	postalCode: "",
	country: US_COUNTRY_CODE,
	dateOfBirth: "",
	email: "",
	phone: "",
	percentage: "",
	occupation: "",
	annualIncome: "",
	sourceOfIncome: "",
}

export default function BeneficialOwnersInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	applicantDetails,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const occupations = getOccupations()
	const annualIncomes = getAnnualIncomes()
	const sourcesOfIncome = getSourcesOfIncome()

	const stateBusinessAdditionalInformation = applicationForm.attributes.state?.enterBusinessAdditionalInformation || {}
	const stateOfficerInformation = applicationForm.attributes.state?.enterOfficerInformation

	const validationSchema = Yup.object().shape({
		beneficialOwners: Yup.array().of(
			Yup.object().shape({
				firstName: Yup.string().required(t("validationErrorMessages.firstName.required")),
				lastName: Yup.string().required(t("validationErrorMessages.lastName.required")),
				ssn: Yup.string().when("nationality", {
					is: US_COUNTRY_CODE,
					then: Yup.string()
						.required(t("validationErrorMessages.ssn.required"))
						.matches(ssnRegex, t("validationErrorMessages.ssn.invalid")),
				}),
				nationality: Yup.string().required(t("validationErrorMessages.nationality.required")),
				passport: Yup.string().when("nationality", {
					is: (nationality: string) => nationality !== US_COUNTRY_CODE,
					then: Yup.string().required(t("validationErrorMessages.passport.required")),
				}),
				street: Yup.string().required(t("validationErrorMessages.street.required")),
				// street2:
				city: Yup.string().required(t("validationErrorMessages.city.required")),
				state: Yup.string().when("country", {
					is: (country: string) => [US_COUNTRY_CODE, CA_COUNTRY_CODE].includes(country),
					then: Yup.string().required(t("validationErrorMessages.state.required")),
				}),
				postalCode: Yup.string()
					.required(t("validationErrorMessages.postalCode.required"))
					.when("country", {
						is: US_COUNTRY_CODE,
						then: Yup.string().matches(usPostalCode, t("validationErrorMessages.postalCode.usInvalid")),
					})
					.when("country", {
						is: CA_COUNTRY_CODE,
						then: Yup.string().matches(caPostalCode, t("validationErrorMessages.postalCode.caInvalid")),
					}),
				country: Yup.string().required(t("validationErrorMessages.country.required")),
				dateOfBirth: Yup.date()
					.required(t("validationErrorMessages.dateOfBirth.required"))
					.max(moment().subtract(18, "years"), t("validationErrorMessages.dateOfBirth.max"))
					.test("is-valid-year", t("validationErrorMessages.dateOfBirth.invalid"), function (value) {
						const year = moment(value).year()
						return year >= 1900
					}),
				email: Yup.string()
					.email(t("validationErrorMessages.email.invalid"))
					.required(t("validationErrorMessages.email.required")),
				phone: Yup.string()
					.required(t("validationErrorMessages.phone.required"))
					.phoneNumber(t("validationErrorMessages.phone.invalid")),
				percentage: Yup.number()
					.required(t("validationErrorMessages.beneficialOwnershipPercentage.required"))
					.min(25, t("validationErrorMessages.beneficialOwnershipPercentage.range"))
					.max(100, t("validationErrorMessages.beneficialOwnershipPercentage.range")),
				occupation: Yup.string()
					.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid"))
					.when([], {
						is: () => !applicationForm.attributes.disableRegulatoryValidations,
						then: Yup.string().required(t("validationErrorMessages.occupation.required")),
					}),
				annualIncome: Yup.string()
					.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
					.when([], {
						is: () =>
							stateBusinessAdditionalInformation.hasNonUsEntities &&
							!applicationForm.attributes.disableRegulatoryValidations,
						then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
					}),
				sourceOfIncome: Yup.string()
					.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
					.when([], {
						is: () =>
							stateBusinessAdditionalInformation.hasNonUsEntities &&
							!applicationForm.attributes.disableRegulatoryValidations,
						then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
					}),
			})
		),
		agreement: Yup.boolean().oneOf([true], t("validationErrorMessages.agreement.required")),
	})

	const officerIsBeneficialOwner = applicationForm.attributes.state?.enterOfficerInformation?.officerIsBeneficialOwner
	const phone = stateOfficerInformation?.officer.phone ?? applicantDetails?.officer?.phone
	const beneficialOwnersAgreement = (
		<>
			{t("beneficialOwnersInformation.form.beneficialOwnersAgreement")}
			<br />
			<br />
		</>
	)
	const beneficialOwnersInitialFormValues = applicantDetails?.beneficialOwners
		? applicantDetails?.beneficialOwners.map((bo) => {
				return {
					firstName: bo.fullName?.first ?? "",
					lastName: bo.fullName?.last ?? "",
					ssn: bo.ssn ?? "",
					nationality: bo.nationality ?? US_COUNTRY_CODE,
					passport: bo.passport ?? "",
					street: bo.address?.street ?? "",
					street2: bo.address?.street2 ?? "",
					city: bo.address?.city ?? "",
					state: bo.address?.state ?? "",
					postalCode: bo.address?.postalCode ?? "",
					country: bo.address?.country ?? US_COUNTRY_CODE,
					dateOfBirth: bo.dateOfBirth ?? "",
					email: bo.email ?? "",
					phone: bo.phone ? toPhoneNumber(bo.phone) : "",
					percentage: bo.percentage ?? "",
					occupation: bo.occupation ?? "",
					annualIncome: bo.annualIncome ?? "",
					sourceOfIncome: bo.sourceOfIncome ?? "",
				}
		  })
		: officerIsBeneficialOwner
		? [
				{
					firstName: stateOfficerInformation?.officer?.fullName?.first ?? "",
					lastName: stateOfficerInformation?.officer?.fullName?.last ?? "",
					phone: phone ? toPhoneNumber(phone) : "",
					email: stateOfficerInformation?.officer?.email ?? "",
					dateOfBirth: stateOfficerInformation?.officer?.dateOfBirth ?? "",
					ssn: stateOfficerInformation?.officer?.ssn ?? "",
					nationality: stateOfficerInformation?.officer?.nationality ?? US_COUNTRY_CODE,
					passport: stateOfficerInformation?.officer?.passport ?? "",
					street: stateOfficerInformation?.officer?.address.street ?? "",
					street2: stateOfficerInformation?.officer?.address.street2 ?? "",
					city: stateOfficerInformation?.officer?.address.city ?? "",
					country: stateOfficerInformation?.officer?.address.country ?? US_COUNTRY_CODE,
					state: stateOfficerInformation?.officer?.address.state ?? "",
					postalCode: stateOfficerInformation?.officer?.address.postalCode ?? "",
					title: stateOfficerInformation?.officer?.title ?? "CEO",
					percentage: "",
					occupation: stateOfficerInformation?.officer?.occupation ?? "",
					annualIncome: stateOfficerInformation?.officer?.annualIncome ?? "",
					sourceOfIncome: stateOfficerInformation?.officer?.sourceOfIncome ?? "",
				},
		  ]
		: ([] as BeneficialOwner[])

	const initialFormValues = {
		numberOfBeneficialOwners: applicantDetails?.beneficialOwners?.length ?? (officerIsBeneficialOwner ? 1 : 0),
		beneficialOwners: beneficialOwnersInitialFormValues,
		agreement: false,
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormBeneficialOwnersInformation", {
			beneficialOwners: values.beneficialOwners.map((item: BeneficialOwner) => {
				return {
					ssn: item.nationality === US_COUNTRY_CODE ? sanitizeNumberFormat(item.ssn) : null,
					nationality: item.nationality !== US_COUNTRY_CODE ? item.nationality : null,
					passport: item.nationality !== US_COUNTRY_CODE ? item.passport : null,
					dateOfBirth: item.dateOfBirth,
					email: item.email,
					percentage: item.percentage,
					fullName: {
						first: item.firstName,
						last: item.lastName,
					},
					address: {
						street: item.street,
						street2: item.street2,
						city: item.city,
						state: item.state,
						postalCode: item.postalCode,
						country: item.country,
					},
					phone: {
						countryCode: parsePhoneNumber(item.phone, US_COUNTRY_CODE).countryCallingCode,
						number: parsePhoneNumber(item.phone, US_COUNTRY_CODE).nationalNumber,
					},
					occupation: item.occupation || null,
					...(stateBusinessAdditionalInformation.hasNonUsEntities
						? {
								annualIncome: item.annualIncome || null,
								sourceOfIncome: item.sourceOfIncome || null,
						  }
						: {}),
				}
			}),
		})
	}

	const onChangeNumberOfBeneficialOwners = (
		e: React.ChangeEvent<HTMLInputElement>,
		values: FormikValues,
		setValues: Function
	) => {
		const beneficialOwners = [...values.beneficialOwners]
		const numberOfBeneficialOwners = parseInt(e.target.value) || 0
		const previousNumber = values.numberOfBeneficialOwners

		if (previousNumber < numberOfBeneficialOwners) {
			for (let i = previousNumber; i < numberOfBeneficialOwners; i++) {
				beneficialOwners.push(emptyBeneficialOwnerObject)
			}
		} else {
			for (let i = previousNumber; i >= numberOfBeneficialOwners; i--) {
				beneficialOwners.splice(i, 1)
			}
		}

		setValues({...values, numberOfBeneficialOwners, beneficialOwners})
	}

	return (
		<div className="beneficial-owners">
			<h1>{t("beneficialOwnersInformation.pageTitle")}</h1>
			<p>{t("beneficialOwnersInformation.pageDescription")}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({setValues, values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, isValid}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<Dropdown
							name={"numberOfBeneficialOwners"}
							label={t("beneficialOwnersInformation.form.numberOfBeneficialOwners")}
							value={values.numberOfBeneficialOwners.toString()}
							onChange={(e) => onChangeNumberOfBeneficialOwners(e, values, setValues)}
							hasPrefill={!isUndefined(applicantDetails?.beneficialOwners)}
							disabled={isForcePrefill && !isUndefined(applicantDetails?.beneficialOwners)}
						>
							<option value="0">{t("beneficialOwnersInformation.form.numberOfBeneficialOwnersList.zero")}</option>
							<option value="1">{t("beneficialOwnersInformation.form.numberOfBeneficialOwnersList.one")}</option>
							<option value="2">{t("beneficialOwnersInformation.form.numberOfBeneficialOwnersList.two")}</option>
							<option value="3">{t("beneficialOwnersInformation.form.numberOfBeneficialOwnersList.three")}</option>
							<option value="4">{t("beneficialOwnersInformation.form.numberOfBeneficialOwnersList.four")}</option>
						</Dropdown>
						<FieldArray name="beneficialOwners">
							{() =>
								values.beneficialOwners.map((beneficialOwner, i) => {
									const beneficialOwnerPrefill = applicantDetails?.beneficialOwners?.[i]
									const prefillExistsForField = {
										fullName: !isUndefined(beneficialOwnerPrefill?.fullName),
										phone: !isUndefined(beneficialOwnerPrefill?.phone),
										email: !isUndefined(beneficialOwnerPrefill?.email),
										dateOfBirth: !isUndefined(beneficialOwnerPrefill?.dateOfBirth),
										nationality: !isUndefined(beneficialOwnerPrefill?.nationality),
										ssn: !isUndefined(beneficialOwnerPrefill?.ssn),
										passport: !isUndefined(beneficialOwnerPrefill?.passport),
										address: !isUndefined(beneficialOwnerPrefill?.address),
										percentage: !isUndefined(beneficialOwnerPrefill?.percentage),
										occupation: !isUndefined(beneficialOwnerPrefill?.occupation),
										annualIncome: !isUndefined(beneficialOwnerPrefill?.annualIncome),
										sourceOfIncome: !isUndefined(beneficialOwnerPrefill?.sourceOfIncome),
									}

									return (
										<div className="beneficial-owner" key={i}>
											<div className="beneficial-owner-title">
												<h5>
													{t("beneficialOwnersInformation.form.owner")} {i + 1}
												</h5>
											</div>
											<div className="beneficial-owner-form">
												<div className="form-row two">
													<Textfield
														name={`beneficialOwners.${i}.firstName`}
														label={t("beneficialOwnersInformation.form.firstName")}
														value={values.beneficialOwners[i].firstName}
														onChange={handleChange}
														onBlur={handleBlur}
														hasPrefill={prefillExistsForField.fullName}
														disabled={isForcePrefill && prefillExistsForField.fullName}
													/>
													<Textfield
														name={`beneficialOwners.${i}.lastName`}
														label={t("beneficialOwnersInformation.form.lastName")}
														value={values.beneficialOwners[i].lastName}
														onChange={handleChange}
														onBlur={handleBlur}
														hasPrefill={prefillExistsForField.fullName}
														disabled={isForcePrefill && prefillExistsForField.fullName}
													/>
												</div>
												<Textfield
													name={`beneficialOwners.${i}.phone`}
													label={t("beneficialOwnersInformation.form.phone")}
													type={"tel"}
													value={formatPhoneNumber(values.beneficialOwners[i].phone)}
													onChange={handleChange}
													onBlur={handleBlur}
													hasPrefill={prefillExistsForField.phone}
													disabled={isForcePrefill && prefillExistsForField.phone}
												/>
												<Textfield
													name={`beneficialOwners.${i}.email`}
													label={t("beneficialOwnersInformation.form.email")}
													type={"email"}
													value={values.beneficialOwners[i].email}
													onChange={handleChange}
													onBlur={handleBlur}
													hasPrefill={prefillExistsForField.email}
													disabled={isForcePrefill && prefillExistsForField.email}
												/>
												<div className="form-row two">
													<Datefield
														name={`beneficialOwners.${i}.dateOfBirth`}
														label={t("beneficialOwnersInformation.form.dateOfBirth")}
														value={values.beneficialOwners[i].dateOfBirth}
														setFieldValue={setFieldValue}
														onBlur={handleBlur}
														hasPrefill={prefillExistsForField.dateOfBirth}
														disabled={isForcePrefill && prefillExistsForField.dateOfBirth}
													/>
													<Dropdown
														name={`beneficialOwners.${i}.nationality`}
														label={t("beneficialOwnersInformation.form.nationality")}
														value={values.beneficialOwners[i].nationality}
														onChange={handleChange}
														onBlur={handleBlur}
														hasPrefill={prefillExistsForField.nationality}
														disabled={
															isForcePrefill && (prefillExistsForField.ssn || prefillExistsForField.nationality)
														}
													>
														{Object.keys(countries).map((countryCode) => (
															<option key={countryCode} value={countryCode}>
																{countries[countryCode]}
															</option>
														))}
													</Dropdown>
												</div>
												<div className="form-row two">
													{values.beneficialOwners[i].nationality === US_COUNTRY_CODE ? (
														<Textfield
															name={`beneficialOwners.${i}.ssn`}
															label={t("beneficialOwnersInformation.form.ssn")}
															value={values.beneficialOwners[i].ssn}
															onChange={handleChange}
															onBlur={handleBlur}
															inputMask={ssnInputMask}
															showInfoTooltip={true}
															infoTooltipText={t("beneficialOwnersInformation.form.ssnTooltip")}
															hasPrefill={prefillExistsForField.ssn}
															disabled={isForcePrefill && prefillExistsForField.ssn}
														/>
													) : (
														<Textfield
															name={`beneficialOwners.${i}.passport`}
															label={t("beneficialOwnersInformation.form.passport")}
															value={values.beneficialOwners[i].passport}
															onChange={handleChange}
															onBlur={handleBlur}
															hasPrefill={prefillExistsForField.passport}
															disabled={isForcePrefill && prefillExistsForField.passport}
														/>
													)}
												</div>
												<Address
													addressType={"Individual"}
													street={values.beneficialOwners[i].street}
													street2={values.beneficialOwners[i].street2}
													city={values.beneficialOwners[i].city}
													country={values.beneficialOwners[i].country}
													state={values.beneficialOwners[i].state}
													postalCode={values.beneficialOwners[i].postalCode}
													isUsOnlyAddress={false}
													handleChange={handleChange}
													handleBlur={handleBlur}
													setFieldValue={setFieldValue}
													namePrefix={`beneficialOwners.${i}.`}
													hasPrefill={prefillExistsForField.address}
													disabled={isForcePrefill && prefillExistsForField.address}
												/>
												<Textfield
													name={`beneficialOwners.${i}.percentage`}
													label={t("beneficialOwnersInformation.form.percentage")}
													value={values.beneficialOwners[i].percentage}
													type="number"
													min="0"
													max="100"
													onChange={handleChange}
													onBlur={handleBlur}
													hasPrefill={prefillExistsForField.percentage}
													disabled={isForcePrefill && prefillExistsForField.percentage}
												/>
												<Dropdown
													name={`beneficialOwners.${i}.occupation`}
													label={t("individualAdditionalInformation.form.occupation")}
													value={values.beneficialOwners[i].occupation}
													onChange={handleChange}
													onBlur={handleBlur}
													hasPrefill={prefillExistsForField.occupation}
													disabled={isForcePrefill && prefillExistsForField.occupation}
												>
													<option key="default" defaultValue="" />
													{Object.keys(occupations).map((occupation) => (
														<option key={occupation} value={occupation}>
															{occupations[occupation]}
														</option>
													))}
												</Dropdown>

												{stateBusinessAdditionalInformation.hasNonUsEntities && (
													<>
														<SingleSelection
															name={`beneficialOwners.${i}.annualIncome`}
															label={t("individualAdditionalInformation.form.annualIncome")}
															value={values.beneficialOwners[i].annualIncome}
															setFieldValue={setFieldValue}
															options={annualIncomes}
															hasPrefill={prefillExistsForField.annualIncome}
															disabled={isForcePrefill && prefillExistsForField.annualIncome}
														/>

														<Dropdown
															name={`beneficialOwners.${i}.sourceOfIncome`}
															label={t("individualAdditionalInformation.form.sourceOfIncome")}
															value={values.beneficialOwners[i].sourceOfIncome}
															onChange={handleChange}
															onBlur={handleBlur}
															hasPrefill={prefillExistsForField.sourceOfIncome}
															disabled={isForcePrefill && prefillExistsForField.sourceOfIncome}
														>
															<option key="default" defaultValue="" />
															{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
																<option key={sourceOfIncome} value={sourceOfIncome}>
																	{sourcesOfIncome[sourceOfIncome]}
																</option>
															))}
														</Dropdown>
													</>
												)}
											</div>
										</div>
									)
								})
							}
						</FieldArray>
						<AgreementCheckbox
							setFieldValue={setFieldValue}
							prefix={beneficialOwnersAgreement}
							applicationForm={applicationForm}
							agreement={values.agreement}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<Button type="submit" isLoading={isRequestInProgress}>
							{t("beneficialOwnersInformation.form.submit")}
						</Button>

						{applicationForm.attributes.applicationFormSettings?.applicationFormDebitCardDisclosureUrl ? (
							<VisaNotice applicationForm={applicationForm} />
						) : null}
					</form>
				)}
			</Formik>
		</div>
	)
}
