import React, {PropsWithChildren} from "react"
import {ApplicationForm, ApplicationFormStage, ApplicationFormStageDetails} from "../../resources/applicationForm"
import {allowedOneApplicationType, applicationFormStages} from "../../resources/common"
import {useTranslation} from "react-i18next"
import {camelCase} from "lodash"
import classNames from "classnames"

interface Props {
	applicationFormStage: ApplicationFormStage
	applicationForm?: ApplicationForm
	updateApplicationForm: (requestType: string, state: object) => void
	validatePhoneNumber: boolean
}

export default function FormCurrentStageIndicator({
	applicationFormStage,
	applicationForm,
	updateApplicationForm,
	validatePhoneNumber,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}
	const stages = applicationForm ? applicationFormStages(applicationForm, validatePhoneNumber) : []

	const currentStageIndex = stages.indexOf(applicationFormStage)

	// Last stage is the "Thank you" page which isn't part of the total stages amount
	const totalStagesNum = stages.length - 1
	const isBackAllowed = currentStageIndex < totalStagesNum

	const handleStepClick = (stage: ApplicationFormStage, index: number) => {
		if (index > currentStageIndex) {
			return
		}
		if (!isBackAllowed) {
			return
		}
		updateApplicationForm("applicationFormUpdateStage", {stage})
	}

	const stageElements = stages.map((stage, index) => {
		const additionalClasses = []

		if (
			stage == ApplicationFormStage.ChooseBusinessOrIndividual &&
			applicationForm?.attributes.allowedApplicationTypes?.length == 1
		) {
			return null
		}

		if (!ApplicationFormStageDetails[stage].showInStagesIndicator) {
			return null
		}

		if (index < currentStageIndex) {
			additionalClasses.push(classNames("completed", isBackAllowed && "clickable"))
		} else if (index === currentStageIndex) {
			additionalClasses.push("current")
		}

		const translationKey = `${camelCase(ApplicationFormStageDetails[stage].title)}.pageTitle`
		return (
			<div className={`stage ${additionalClasses.join(" ")}`} key={index} onClick={() => handleStepClick(stage, index)}>
				<div className={"stage-circle"} />
				<div className={"stage-text"}>{t(translationKey)}</div>
			</div>
		)
	})

	const stageIndex = allowedOneApplicationType(applicationForm) ? currentStageIndex : currentStageIndex + 1
	const totalStages = allowedOneApplicationType(applicationForm) ? totalStagesNum - 1 : totalStagesNum
	const currentStageNumberText =
		currentStageIndex < totalStagesNum ? `${t("step")} ${stageIndex}/${totalStages} - ` : null

	return (
		<div className="form-current-stage-indicator">
			<div className="stages">
				<div className="line"></div>
				{stageElements}
			</div>
			<div className="bottom-text">
				{currentStageNumberText}
				<strong>{t(`${camelCase(ApplicationFormStageDetails[applicationFormStage].title)}.pageTitle`)}</strong>
			</div>
		</div>
	)
}
