import React from "react"
import {focusFirstInvalidField} from "../../../utilities/dom"
import Dropdown from "../../../components/Dropdown"
import SingleSelection from "../../../components/SingleSelection"
import Button from "../../../components/Button"
import {Formik, FormikValues} from "formik"
import {
	CddEddBeneficialOwner,
	ApplicationForm,
	countries,
	getAnnualIncomes,
	getBusinessAnnualRevenues,
	getBusinessNumberOfEmployees,
	getBusinessVerticals,
	getCashFlowOptions,
	getOccupations,
	getSourcesOfIncome,
} from "../../../resources/applicationForm"
import * as Yup from "yup"
import {useTranslation} from "react-i18next"
import {urlRegex, yearRegex} from "../../../utilities/validation"
import Textfield from "../../../components/Textfield"
import MultiSelectionCombobox, {MultiSelectionComboboxOption} from "../../../components/MultiSelectionCombobox"
import {businessApplicationHasNonUsEntities} from "../ApplicationFormMain"
import {faUser} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {isUndefined} from "lodash"
import Checkbox from "../../../components/Checkbox"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
	updateApplicationForm: Function
	isRequestInProgress: boolean
}

export default function DataCollectionBusiness({
	applicationForm,
	updateApplicationForm,
	fetchApplicationForm,
	isRequestInProgress,
}: Props) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}
	const businessAnnualRevenues = getBusinessAnnualRevenues()
	const businessVerticals = getBusinessVerticals()
	const businessNumberOfEmployees = getBusinessNumberOfEmployees()
	const cashFlowOptions = getCashFlowOptions()
	const annualIncomes = getAnnualIncomes()
	const occupations = getOccupations()
	const sourcesOfIncome = getSourcesOfIncome()
	const businessHasNonUsEntities = businessApplicationHasNonUsEntities(applicationForm)
	const cddEddAttributes = applicationForm.attributes.cddEddAttributes
	const isHighRisk = applicationForm.attributes.customerRiskRate == "high"
	const isEddRequired = businessHasNonUsEntities || isHighRisk

	const validationSchema = Yup.object().shape(
		{
			businessVertical: Yup.string()
				.required(t("validationErrorMessages.businessVertical.required"))
				.oneOf(Object.keys(businessVerticals), t("validationErrorMessages.businessVertical.invalid")),
			annualRevenue: Yup.string()
				.oneOf(Object.keys(businessAnnualRevenues), t("validationErrorMessages.annualRevenue.invalid"))
				.when([], {
					is: () => isEddRequired,
					then: Yup.string().required(t("validationErrorMessages.annualRevenue.required")),
				}),
			numberOfEmployees: Yup.string()
				.oneOf(Object.keys(businessNumberOfEmployees), t("validationErrorMessages.numberOfEmployees.invalid"))
				.when([], {
					is: () => isEddRequired,
					then: Yup.string().required(t("validationErrorMessages.numberOfEmployees.required")),
				}),
			cashFlow: Yup.string().when([], {
				is: () => isEddRequired,
				then: Yup.string().required(t("validationErrorMessages.cashFlow.required")),
			}),
			yearOfIncorporation: Yup.string()
				.matches(yearRegex, t("validationErrorMessages.yearOfIncorporation.invalid"))
				.required(t("validationErrorMessages.yearOfIncorporation.required")),
			countriesOfOperation: Yup.array()
				.of(Yup.string())
				.when([], {
					is: () => isEddRequired,
					then: Yup.array()
						.required(t("validationErrorMessages.countriesOfOperation.required"))
						.min(1, t("validationErrorMessages.countriesOfOperation.required")),
				}),
			website: Yup.string().when("hasNoWebsite", {
				is: (hasNoWebsite?: boolean) => !hasNoWebsite,
				then: Yup.string().matches(urlRegex, t("validationErrorMessages.website.invalid")),
			}),
			hasNoWebsite: Yup.boolean().when("website", {
				is: (website?: string) => isUndefined(website),
				then: Yup.boolean().oneOf([true], t("validationErrorMessages.hasNoWebsite.required")),
			}),
			officer: Yup.object().shape({
				occupation: Yup.string()
					.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid"))
					.when([], {
						is: () => isEddRequired,
						then: Yup.string().required(t("validationErrorMessages.occupation.required")),
					}),
				annualIncome: Yup.string()
					.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
					.when([], {
						is: () => isEddRequired,
						then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
					}),
				sourceOfIncome: Yup.string()
					.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
					.when([], {
						is: () => isEddRequired,
						then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
					}),
			}),
			beneficialOwners: Yup.array().of(
				Yup.object().shape({
					occupation: Yup.string()
						.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid"))
						.when([], {
							is: () => isEddRequired,
							then: Yup.string().required(t("validationErrorMessages.occupation.required")),
						}),
					annualIncome: Yup.string()
						.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
						.when([], {
							is: () => isEddRequired,
							then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
						}),
					sourceOfIncome: Yup.string()
						.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
						.when([], {
							is: () => isEddRequired,
							then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
						}),
				})
			),
		},
		[["website", "hasNoWebsite"]]
	) // takes care of cyclic dependency

	const initialFormValues = {
		businessVertical: cddEddAttributes?.businessVertical ?? "",
		yearOfIncorporation: cddEddAttributes?.yearOfIncorporation ?? "",
		annualRevenue: cddEddAttributes?.annualRevenue ?? "",
		numberOfEmployees: cddEddAttributes?.numberOfEmployees ?? "",
		cashFlow: cddEddAttributes?.cashFlow ?? "",
		countriesOfOperation: cddEddAttributes?.countriesOfOperation ?? [],
		website: cddEddAttributes?.website ?? "",
		hasNoWebsite: !cddEddAttributes?.website,
		officer: {
			occupation: cddEddAttributes?.officer?.occupation ?? "",
			annualIncome: cddEddAttributes?.officer?.annualIncome ?? "",
			sourceOfIncome: cddEddAttributes?.officer?.sourceOfIncome ?? "",
		},
		beneficialOwners: cddEddAttributes?.beneficialOwners?.map((bo) => {
			return {
				id: bo.id,
				occupation: bo.occupation ?? "",
				annualIncome: bo.annualIncome ?? "",
				sourceOfIncome: bo.sourceOfIncome ?? "",
			}
		}),
	}

	const isInitialWebsiteValueValid = () => {
		return Yup.reach(validationSchema, "website").isValidSync(cddEddAttributes?.website)
	}

	const submitForm = async (values: FormikValues) => {
		await updateApplicationForm("applicationFormCddEddAttributes", {
			businessVertical: values.businessVertical || null,
			yearOfIncorporation: values.yearOfIncorporation || null,
			website: !values.hasNoWebsite && values.website ? values.website : null,
			...(isEddRequired
				? {
						annualRevenue: values.annualRevenue || null,
						numberOfEmployees: values.numberOfEmployees || null,
						countriesOfOperation: values.countriesOfOperation.length > 0 ? values.countriesOfOperation : null,
						cashFlow: values.cashFlow || null,
				  }
				: {}),
			officer: {
				occupation: values.officer.occupation || null,
				...(isEddRequired
					? {
							annualIncome: values.officer.annualIncome || null,
							sourceOfIncome: values.officer.sourceOfIncome || null,
					  }
					: {}),
			},
			beneficialOwners: values.beneficialOwners.map(
				(item: {id: string; occupation: string; annualIncome: string; sourceOfIncome: string}) => {
					return {
						id: item.id,
						occupation: item.occupation || null,
						annualIncome: item.annualIncome || null,
						sourceOfIncome: item.sourceOfIncome || null,
					}
				}
			),
		})
		fetchApplicationForm()
	}

	const needsAnyAdditionalBusinessInformationFields =
		isUndefined(cddEddAttributes?.businessVertical) ||
		isUndefined(cddEddAttributes?.yearOfIncorporation) ||
		(isEddRequired &&
			(isUndefined(cddEddAttributes?.cashFlow) ||
				isUndefined(cddEddAttributes?.annualRevenue) ||
				isUndefined(cddEddAttributes?.numberOfEmployees) ||
				isUndefined(cddEddAttributes?.countriesOfOperation) ||
				!cddEddAttributes?.website))

	const needsAnyOfficerInformation =
		isEddRequired &&
		(isUndefined(cddEddAttributes?.officer?.occupation) ||
			isUndefined(cddEddAttributes?.officer?.sourceOfIncome) ||
			isUndefined(cddEddAttributes?.officer?.annualIncome))

	const needsAnyBeneficialOwnerInformation = (bo: CddEddBeneficialOwner) =>
		isEddRequired && (isUndefined(bo.occupation) || isUndefined(bo.sourceOfIncome) || isUndefined(bo.annualIncome))

	return (
		<Formik
			initialValues={initialFormValues}
			validateOnMount={true}
			validationSchema={validationSchema}
			onSubmit={(values) => submitForm(values)}
		>
			{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
				<form
					noValidate
					onSubmit={(e) => {
						e.preventDefault()
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
				>
					{needsAnyAdditionalBusinessInformationFields && <h3>Additional Business Information</h3>}
					{isUndefined(cddEddAttributes?.businessVertical) && (
						<Dropdown
							name={"businessVertical"}
							label={t("soleProprietorshipBusinessInformation.form.businessVertical")}
							value={values.businessVertical}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							<option key="default" defaultValue="" />
							{Object.keys(businessVerticals).map((businessVertical) => (
								<option key={businessVertical} value={businessVertical}>
									{businessVerticals[businessVertical]}
								</option>
							))}
						</Dropdown>
					)}

					{isUndefined(cddEddAttributes?.yearOfIncorporation) && (
						<Textfield
							name="yearOfIncorporation"
							label={t("businessAdditionalInformation.form.yearOfIncorporation")}
							value={values.yearOfIncorporation}
							onChange={handleChange}
							maxLength={4}
							onBlur={handleBlur}
						/>
					)}

					{isEddRequired && (
						<>
							{isUndefined(cddEddAttributes?.cashFlow) && (
								<Dropdown
									name={"cashFlow"}
									label={t("businessAdditionalInformation.form.cashFlow")}
									value={values.cashFlow}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option value=""></option>
									{Object.keys(cashFlowOptions).map((cashFlow) => (
										<option key={cashFlow} value={cashFlow}>
											{cashFlowOptions[cashFlow]}
										</option>
									))}
								</Dropdown>
							)}

							{isUndefined(cddEddAttributes?.annualRevenue) && (
								<SingleSelection
									name={"annualRevenue"}
									label={t("businessAdditionalInformation.form.annualRevenue")}
									value={values.annualRevenue}
									setFieldValue={setFieldValue}
									options={businessAnnualRevenues}
								/>
							)}

							{isUndefined(cddEddAttributes?.numberOfEmployees) && (
								<SingleSelection
									name={"numberOfEmployees"}
									label={t("businessAdditionalInformation.form.numberOfEmployees")}
									value={values.numberOfEmployees}
									setFieldValue={setFieldValue}
									options={businessNumberOfEmployees}
								/>
							)}

							{isUndefined(cddEddAttributes?.countriesOfOperation) && (
								<MultiSelectionCombobox
									name={"countriesOfOperation"}
									label={t("businessAdditionalInformation.form.countriesOfOperation")}
									value={values.countriesOfOperation}
									options={Object.keys(countries).map((countryKey) => {
										return {value: countryKey, label: countries[countryKey], isFixed: false}
									})}
									setFieldValue={(selectedOptions: MultiSelectionComboboxOption[]) => {
										setFieldValue(
											"countriesOfOperation",
											selectedOptions.map((option) => option.value)
										)
									}}
								/>
							)}
						</>
					)}

					{(!cddEddAttributes?.website || !isInitialWebsiteValueValid()) && (
						<>
							<Textfield
								name="website"
								label={t("businessInformation.form.website")}
								value={values.website}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={values.hasNoWebsite}
							/>
							<Checkbox
								name="hasNoWebsite"
								checked={values.hasNoWebsite}
								onChange={handleChange}
								onBlur={handleBlur}
								text={t("businessInformation.form.hasNoWebsite")}
							/>
						</>
					)}

					{isEddRequired && needsAnyOfficerInformation && (
						<div className={"data-collection-person-section"}>
							<h3>Officer</h3>
							<div className={"data-collection-subheader"}>
								<FontAwesomeIcon className="icon" icon={faUser} /> {cddEddAttributes?.officer?.fullName.first}{" "}
								{cddEddAttributes?.officer?.fullName.last}
							</div>
							{isUndefined(cddEddAttributes?.officer?.occupation) && (
								<Dropdown
									name={"officer.occupation"}
									label={t("individualAdditionalInformation.form.occupation")}
									value={values.officer.occupation}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option key="default" defaultValue="" />
									{Object.keys(occupations).map((occupation) => (
										<option key={occupation} value={occupation}>
											{occupations[occupation]}
										</option>
									))}
								</Dropdown>
							)}

							{isUndefined(cddEddAttributes?.officer?.annualIncome) && (
								<SingleSelection
									name={"officer.annualIncome"}
									label={t("individualAdditionalInformation.form.annualIncome")}
									value={values.officer.annualIncome}
									setFieldValue={setFieldValue}
									options={annualIncomes}
								/>
							)}

							{isUndefined(cddEddAttributes?.officer?.sourceOfIncome) && (
								<Dropdown
									name={"officer.sourceOfIncome"}
									label={t("individualAdditionalInformation.form.sourceOfIncome")}
									value={values.officer.sourceOfIncome}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option key="default" defaultValue="" />
									{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
										<option key={sourceOfIncome} value={sourceOfIncome}>
											{sourcesOfIncome[sourceOfIncome]}
										</option>
									))}
								</Dropdown>
							)}
						</div>
					)}

					{isEddRequired &&
						cddEddAttributes?.beneficialOwners?.map((bo, i) => {
							if (!needsAnyBeneficialOwnerInformation(bo)) {
								return null
							}

							return (
								<div className={"data-collection-person-section"} key={`bo-${bo.id}`}>
									<h3>Beneficial Owner</h3>
									<div className={"data-collection-subheader"}>
										<FontAwesomeIcon className="icon" icon={faUser} /> {bo.fullName.first} {bo.fullName.last}
									</div>

									{isUndefined(bo.occupation) && (
										<Dropdown
											name={`beneficialOwners.${i}.occupation`}
											label={t("individualAdditionalInformation.form.occupation")}
											value={values.beneficialOwners?.[i].occupation ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
										>
											<option key="default" defaultValue="" />
											{Object.keys(occupations).map((occupation) => (
												<option key={occupation} value={occupation}>
													{occupations[occupation]}
												</option>
											))}
										</Dropdown>
									)}

									{isUndefined(cddEddAttributes?.annualIncome) && (
										<SingleSelection
											name={`beneficialOwners.${i}.annualIncome`}
											label={t("individualAdditionalInformation.form.annualIncome")}
											value={values.beneficialOwners?.[i].annualIncome ?? ""}
											setFieldValue={setFieldValue}
											options={annualIncomes}
										/>
									)}

									{isUndefined(cddEddAttributes?.sourceOfIncome) && (
										<Dropdown
											name={`beneficialOwners.${i}.sourceOfIncome`}
											label={t("individualAdditionalInformation.form.sourceOfIncome")}
											value={values.beneficialOwners?.[i].sourceOfIncome ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
										>
											<option key="default" defaultValue="" />
											{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
												<option key={sourceOfIncome} value={sourceOfIncome}>
													{sourcesOfIncome[sourceOfIncome]}
												</option>
											))}
										</Dropdown>
									)}
								</div>
							)
						})}

					<Button type="submit" isLoading={isRequestInProgress}>
						{t("dataCollection.submit")}
					</Button>
				</form>
			)}
		</Formik>
	)
}
