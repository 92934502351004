import React from "react"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout"
import ApplicationFormMain from "./containers/ApplicationForm/ApplicationFormMain"
import {ToastProvider} from "react-toast-notifications"

export default function App() {
	return (
		<Router>
			<Switch>
				<DefaultLayout>
					<ToastProvider autoDismiss={true} placement="bottom-right">
						<Route path="/">
							<ApplicationFormMain />
						</Route>
					</ToastProvider>
				</DefaultLayout>
			</Switch>
		</Router>
	)
}
