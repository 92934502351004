import React from "react"
import {
	ApplicantDetails,
	ApplicationForm,
	getBusinessVerticals,
	getSoleProprietorshipAnnualRevenues,
	getSoleProprietorshipNumberOfEmployees,
} from "../../resources/applicationForm"
import {useTranslation} from "react-i18next"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import Dropdown from "../../components/Dropdown"
import AgreementCheckbox from "../../components/ApplicationForm/AgreementCheckbox"
import Button from "../../components/Button"
import VisaNotice from "../../components/ApplicationForm/VisaNotice"
import Textfield from "../../components/Textfield"
import {einInputMask, einRegex, urlRegex} from "../../utilities/validation"
import {sanitizeNumberFormat} from "../../utilities/form"
import SingleSelection from "../../components/SingleSelection"
import {US_COUNTRY_CODE} from "../../resources/common"
import {isUndefined} from "lodash"

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	isRequestInProgress: boolean
	stageTitle: string
	stageDescription: string
	applicantDetails?: ApplicantDetails
}

export default function SoleProprietorshipBusinessInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	stageTitle,
	stageDescription,
	applicantDetails,
}: Props) {
	const {t} = useTranslation()
	const soleProprietorshipAnnualRevenues = getSoleProprietorshipAnnualRevenues()
	const businessVerticals = getBusinessVerticals()
	const soleProprietorshipNumberOfEmployees = getSoleProprietorshipNumberOfEmployees()

	const validationSchema = Yup.object().shape({
		businessVertical: Yup.string()
			.oneOf(Object.keys(businessVerticals), t("validationErrorMessages.businessVertical.invalid"))
			.when([], {
				is: () => !applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.businessVertical.required")),
			}),
		annualRevenue: Yup.string()
			.oneOf(Object.keys(soleProprietorshipAnnualRevenues), t("validationErrorMessages.annualRevenue.invalid"))
			.when([], {
				is: () =>
					stateEnterIndividualInformation?.nationality !== US_COUNTRY_CODE &&
					!applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.annualRevenue.required")),
			}),
		numberOfEmployees: Yup.string()
			.oneOf(Object.keys(soleProprietorshipNumberOfEmployees), t("validationErrorMessages.numberOfEmployees.invalid"))
			.when([], {
				is: () =>
					stateEnterIndividualInformation?.nationality !== US_COUNTRY_CODE &&
					!applicationForm.attributes.disableRegulatoryValidations,
				then: Yup.string().required(t("validationErrorMessages.numberOfEmployees.required")),
			}),
		// dba: Yup.string().required(),
		ein: Yup.string()
			.transform((s) => sanitizeNumberFormat(s))
			.matches(einRegex, {
				message: t("validationErrorMessages.ein.invalid"),
				excludeEmptyString: true,
			}),
		website: Yup.string().matches(urlRegex, t("validationErrorMessages.website.invalid")),
		agreement: Yup.boolean().oneOf([true], t("validationErrorMessages.agreement.required")),
	})

	const stateEnterIndividualInformation = applicationForm.attributes.state?.enterIndividualInformation
	const stateSoleProprietorshipBusinessInformation =
		applicationForm.attributes.state?.enterSoleProprietorshipBusinessInformation

	const annualRevenuePrefill =
		applicantDetails?.annualRevenue && applicantDetails.annualRevenue in soleProprietorshipAnnualRevenues
			? applicantDetails?.annualRevenue
			: undefined
	const numberOfEmployeesPrefill =
		applicantDetails?.numberOfEmployees && applicantDetails.numberOfEmployees in soleProprietorshipNumberOfEmployees
			? applicantDetails.numberOfEmployees
			: undefined

	const initialFormValues = {
		businessVertical:
			stateSoleProprietorshipBusinessInformation?.businessVertical ?? applicantDetails?.businessVertical ?? "",
		annualRevenue: stateSoleProprietorshipBusinessInformation?.annualRevenue ?? annualRevenuePrefill ?? "",
		numberOfEmployees: stateSoleProprietorshipBusinessInformation?.numberOfEmployees ?? numberOfEmployeesPrefill ?? "",
		dba: stateSoleProprietorshipBusinessInformation?.dba ?? applicantDetails?.dba ?? "",
		ein: stateSoleProprietorshipBusinessInformation?.ein ?? applicantDetails?.ein ?? "",
		website: stateSoleProprietorshipBusinessInformation?.website ?? applicantDetails?.website ?? "",
		agreement: false,
	}

	const prefillExistsForField = {
		businessVertical: !isUndefined(applicantDetails?.businessVertical),
		annualRevenue: !isUndefined(applicantDetails?.annualRevenue),
		numberOfEmployees: !isUndefined(applicantDetails?.numberOfEmployees),
		dba: !isUndefined(applicantDetails?.dba),
		ein: !isUndefined(applicantDetails?.ein),
		website: !isUndefined(applicantDetails?.website),
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormSoleProprietorshipBusinessInformation", {
			dba: values.dba,
			ein: values.ein && sanitizeNumberFormat(values.ein) ? sanitizeNumberFormat(values.ein) : null,
			businessVertical: values.businessVertical || null,
			website: values.website,
			...(stateEnterIndividualInformation?.nationality !== US_COUNTRY_CODE
				? {
						annualRevenue: values.annualRevenue,
						numberOfEmployees: values.numberOfEmployees,
				  }
				: {}),
		})
	}

	return (
		<div className="individual-additional-information">
			<h1>{stageTitle}</h1>
			<p>{stageDescription}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<Dropdown
							name={"businessVertical"}
							label={t("soleProprietorshipBusinessInformation.form.businessVertical")}
							value={values.businessVertical}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.businessVertical}
							disabled={isForcePrefill && prefillExistsForField.businessVertical}
						>
							<option key="default" defaultValue="" />
							{Object.keys(businessVerticals).map((businessVertical) => (
								<option key={businessVertical} value={businessVertical}>
									{businessVerticals[businessVertical]}
								</option>
							))}
						</Dropdown>

						{stateEnterIndividualInformation?.nationality !== US_COUNTRY_CODE && (
							<>
								<SingleSelection
									name={"annualRevenue"}
									label={t("soleProprietorshipBusinessInformation.form.annualRevenue")}
									value={values.annualRevenue}
									setFieldValue={setFieldValue}
									options={soleProprietorshipAnnualRevenues}
									hasPrefill={prefillExistsForField.annualRevenue}
									disabled={isForcePrefill && prefillExistsForField.annualRevenue}
								/>

								<SingleSelection
									name={"numberOfEmployees"}
									label={t("soleProprietorshipBusinessInformation.form.numberOfEmployees")}
									value={values.numberOfEmployees}
									setFieldValue={setFieldValue}
									options={soleProprietorshipNumberOfEmployees}
									hasPrefill={prefillExistsForField.numberOfEmployees}
									disabled={isForcePrefill && prefillExistsForField.numberOfEmployees}
								/>
							</>
						)}

						<Textfield
							name="dba"
							label={t("soleProprietorshipBusinessInformation.form.dba")}
							value={values.dba}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.dba}
							disabled={isForcePrefill && prefillExistsForField.dba}
						/>

						<Textfield
							name="ein"
							label={t("soleProprietorshipBusinessInformation.form.ein")}
							value={values.ein}
							onChange={handleChange}
							onBlur={handleBlur}
							inputMask={einInputMask}
							alwaysShowMask={false}
							hasPrefill={prefillExistsForField.ein}
							disabled={isForcePrefill && prefillExistsForField.ein}
						/>

						<Textfield
							name="website"
							label={t("soleProprietorshipBusinessInformation.form.website")}
							value={values.website}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.website}
							disabled={isForcePrefill && prefillExistsForField.website}
						/>

						<AgreementCheckbox
							setFieldValue={setFieldValue}
							applicationForm={applicationForm}
							agreement={values.agreement}
							onChange={handleChange}
							onBlur={handleBlur}
						/>
						<Button type="submit" isLoading={isRequestInProgress}>
							{t("soleProprietorshipBusinessInformation.form.submit")}
						</Button>

						{applicationForm.attributes.applicationFormSettings?.applicationFormDebitCardDisclosureUrl ? (
							<VisaNotice applicationForm={applicationForm} />
						) : null}
					</form>
				)}
			</Formik>
		</div>
	)
}
