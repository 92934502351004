import {
	ApplicationForm,
	ApplicationFormStage,
	ApplicationFormType,
	businessFormStages,
	individualFormStages,
	soleProprietorshipFormStages,
} from "./applicationForm"
import qs from "querystring"
import {get, patch, post, put} from "../utilities/http"
import {flatten} from "q-flat"
import {AsYouType} from "libphonenumber-js"

export const apiUrl = process.env.API_URL
export const US_COUNTRY_CODE = "US"
export const CA_COUNTRY_CODE = "CA"

export type Resource = ApplicationForm

export interface OkDocument<T> {
	data: T
	included?: Resource[]
}

export interface Error {
	title: string
	status: string
	source?: {
		pointer: string
	}
	detail?: string
}

export interface ErrorDocument {
	errors: Array<Error>
}

export async function createResource<T>(path: string, accessToken: string, data: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`

	return await post<OkDocument<T>, ErrorDocument>(url, {data}, {headers})
}

export async function getResource<T>(path: string, accessToken: string, query?: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = query ? `${apiUrl}/${path}?${qs.stringify(flatten(query))}` : `${apiUrl}/${path}`

	return await get<OkDocument<T>, ErrorDocument>(url, {headers})
}

export async function updateResource<T>(path: string, accessToken: string, data: any) {
	const headers = {
		"Content-Type": "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`

	return await patch<OkDocument<T>, ErrorDocument>(url, {data}, {headers})
}

export type FullName = {
	first: string
	last: string
}

export type Address = {
	street: string
	street2?: string
	city: string
	state?: string
	postalCode: string
	country: string
}

export type Phone = {
	countryCode: string
	number: string
}

export function toPhoneNumber(phone: Phone) {
	return ["+", phone.countryCode, phone.number].join("")
}

export function formatPhoneNumber(value?: string | number) {
	if (!value) return ""
	value = value.toString()
	if (value.includes("(") && !value.includes(")")) {
		return value.replace("(", "")
	}
	return new AsYouType(US_COUNTRY_CODE).input(value)
}

export const allowedOneApplicationType = (applicationForm: ApplicationForm | undefined) => {
	return applicationForm?.attributes?.allowedApplicationTypes?.length === 1
}

export async function uploadResource<T>(path: string, accessToken: string, data: any, type: string | null) {
	const headers = {
		"Content-Type": type ? type : "application/vnd.api+json",
		Authorization: `Bearer ${accessToken}`,
	}

	const url = `${apiUrl}/${path}`

	return await put<OkDocument<T>, ErrorDocument>(url, data, {headers})
}

export const applicationFormStages = (applicationForm: ApplicationForm, validatePhoneNumber: boolean) => {
	const applicationFormStage = applicationForm.attributes.stage
	const applicationFormType = applicationForm.attributes.state.chooseBusinessOrIndividual?.applicationFormType

	let stages: ApplicationFormStage[]
	if (applicationFormType) {
		switch (applicationFormType) {
			case ApplicationFormType.Individual: {
				stages = individualFormStages
				break
			}
			case ApplicationFormType.SoleProprietorship: {
				stages = soleProprietorshipFormStages
				break
			}
			case ApplicationFormType.Business: {
				stages = businessFormStages
				break
			}
			default: {
				stages = []
			}
		}
	} else {
		if (individualFormStages.includes(applicationFormStage)) {
			stages = individualFormStages
		} else if (soleProprietorshipFormStages.includes(applicationFormStage)) {
			stages = soleProprietorshipFormStages
		} else if (businessFormStages.includes(applicationFormStage)) {
			stages = businessFormStages
		} else {
			stages = []
		}
	}

	stages = validatePhoneNumber
		? stages
		: stages.filter(
				(stage) =>
					stage !== ApplicationFormStage.IndividualPhoneVerification &&
					stage !== ApplicationFormStage.SoleProprietorshipPhoneVerification &&
					stage !== ApplicationFormStage.BusinessPhoneVerification
		  )

	return stages
}
