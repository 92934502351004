import React from "react"
import Select, {ActionMeta, components, OnChangeValue, StylesConfig} from "react-select"

// Component for selecting multiple values, taken a lot of the example here: https://react-select.com/home#fixed-options

export interface MultiSelectionComboboxOption {
	readonly value: string
	readonly label: string
	readonly isFixed?: boolean
}

interface Props {
	name: string
	label: string
	value: string[]
	options: MultiSelectionComboboxOption[]
	setFieldValue: Function
	disabled?: boolean
	hasPrefill?: boolean
}

import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import {flattenObject} from "../utilities/object"

const styles: StylesConfig<MultiSelectionComboboxOption, true> = {
	multiValue: (base, state) => {
		return state.data.isFixed ? {...base, backgroundColor: "gray"} : base
	},
	multiValueLabel: (base, state) => {
		return state.data.isFixed ? {...base, fontWeight: "bold", color: "white", paddingRight: 6} : base
	},
	multiValueRemove: (base, state) => {
		return state.data.isFixed ? {...base, display: "none"} : base
	},
}

const orderOptions = (values: readonly MultiSelectionComboboxOption[]) => {
	return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed))
}

const CustomRemoveIcon = () => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.96206 6.00013L0.945313 10.0169L1.9824 11.054L5.99915 7.03722L10.0159 11.054L11.053 10.0169L7.03624 6.00013L11.053 1.98338L10.0159 0.946289L5.99915 4.96304L1.9824 0.946289L0.945313 1.98338L4.96206 6.00013Z"
				fill="currentcolor"
			/>
		</svg>
	)
}

const CustomDropdownIndicator = () => {
	return (
		<svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.87767 3.36615L2.13359 0.62207L0.955078 1.80058L4.28841 5.13392C4.44469 5.2902 4.65665 5.37799 4.87767 5.37799C5.09868 5.37799 5.31064 5.2902 5.46692 5.13392L8.80025 1.80058L7.62174 0.62207L4.87767 3.36615Z"
				fill="currentcolor"
			/>
		</svg>
	)
}

export default function MultiSelectionCombobox({
	name,
	label,
	value,
	options,
	setFieldValue,
	disabled = false,
	hasPrefill = false,
}: Props) {
	const {touched, errors} = useFormikContext()
	const onChange = (
		newValue: OnChangeValue<MultiSelectionComboboxOption, true>,
		actionMeta: ActionMeta<MultiSelectionComboboxOption>
	) => {
		switch (actionMeta.action) {
			case "remove-value":
			case "pop-value":
				if (actionMeta.removedValue.isFixed) {
					return
				}
				break
			case "clear":
				newValue = options.filter((v) => v.isFixed)
				break
		}

		setFieldValue(orderOptions(newValue))
	}

	const selectedValues = value.map((v) => {
		return {
			value: v,
			label: options.find((o) => o.value == v)?.label as string,
			isFixed: false,
		}
	})

	const className = classNames(
		"dropdown",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error",
		disabled && "disabled"
	)

	return (
		<div className={className} data-name={name} {...(hasPrefill && {"data-has-prefill": true})}>
			<label>
				<span className="label-text">{label}</span>
			</label>
			<Select
				value={selectedValues}
				isMulti
				styles={styles}
				isClearable={selectedValues.some((v) => !v.isFixed)}
				name={name}
				className={"multi-selection-combobox"}
				classNamePrefix="select"
				onChange={onChange}
				options={options}
				isDisabled={disabled}
				components={{
					MultiValueRemove: (props) => (
						<components.MultiValueRemove {...props}>
							<CustomRemoveIcon />
						</components.MultiValueRemove>
					),
					ClearIndicator: (props) => (
						<components.ClearIndicator {...props}>
							<CustomRemoveIcon />
						</components.ClearIndicator>
					),
					DropdownIndicator: (props) => (
						<components.DropdownIndicator {...props}>
							<CustomDropdownIndicator />
						</components.DropdownIndicator>
					),
				}}
			/>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
