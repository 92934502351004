import React, {Dispatch, PropsWithChildren, useEffect, useRef} from "react"
import Dropdown from "./Dropdown"
import Textfield from "./Textfield"
import {AddressType, caStates, countries, usStates} from "../resources/applicationForm"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import {CA_COUNTRY_CODE, US_COUNTRY_CODE} from "../resources/common"
import {useTranslation} from "react-i18next"

interface Props {
	addressType: AddressType
	street: string
	street2: string
	city: string
	country: string
	state: string
	postalCode: string
	handleChange: Dispatch<any>
	handleBlur: Dispatch<any>
	setFieldValue: Function
	isUsOnlyAddress?: boolean
	namePrefix?: string
	disabled?: boolean
	hasPrefill?: boolean
}

export default function Address({
	addressType,
	street,
	street2,
	city,
	country,
	state,
	postalCode,
	handleChange,
	handleBlur,
	setFieldValue,
	isUsOnlyAddress = false,
	namePrefix = "",
	disabled = false,
	hasPrefill = false,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const addressElement = useRef<HTMLInputElement>(null)
	let autocomplete: google.maps.places.Autocomplete
	let street1Field: HTMLInputElement

	const inputFieldNames = {
		street: `${namePrefix}street`,
		street2: `${namePrefix}street2`,
		city: `${namePrefix}city`,
		country: `${namePrefix}country`,
		state: `${namePrefix}state`,
		postalCode: `${namePrefix}postalCode`,
	}

	function handleEnterKey(e: KeyboardEvent) {
		if (e.keyCode === 13) {
			e.preventDefault()
		}
	}

	function initAutocomplete() {
		if (addressElement.current) {
			street1Field = addressElement.current.querySelector(
				`input[name="${inputFieldNames["street"]}"]`
			) as HTMLInputElement

			autocomplete = new google.maps.places.Autocomplete(street1Field, {
				componentRestrictions: isUsOnlyAddress ? {country: ["us"]} : {country: []},
				fields: ["address_components", "geometry"],
				types: ["address"],
			})
			autocomplete.addListener("place_changed", fillInAddress)
			google.maps.event.addDomListener(street1Field, "keydown", function (e) {
				handleEnterKey(e as KeyboardEvent)
			})
		}
	}

	function fillInAddress() {
		// Get the place details from the autocomplete object.
		const place = autocomplete.getPlace()
		let street = ""
		let postalCode = ""

		// Get each component of the address from the place details,
		// and then fill-in the corresponding field on the form.
		// place.address_components are google.maps.GeocoderAddressComponent objects
		// which are documented at http://goo.gle/3l5i5Mr
		for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
			const componentType = component.types[0]

			switch (componentType) {
				case "street_number": {
					street = `${component.long_name} ${street}`
					break
				}

				case "route": {
					street += component.short_name
					break
				}

				case "postal_code": {
					postalCode = `${component.long_name}${postalCode}`
					break
				}

				case "postal_code_suffix": {
					postalCode = `${postalCode}-${component.long_name}`
					break
				}

				case "locality":
					setFieldValue(inputFieldNames["city"], component.long_name)
					break

				case "administrative_area_level_1": {
					setFieldValue(inputFieldNames["state"], component.short_name)
					break
				}

				case "country":
					setFieldValue(inputFieldNames["country"], component.short_name)
					break
			}
		}

		setFieldValue(inputFieldNames["street"], street)
		setFieldValue(inputFieldNames["postalCode"], postalCode)
	}

	useEffect(() => {
		initAutocomplete()
	}, [])

	let title
	let titleTooltip

	if (addressType === "Individual") {
		title = t("individualInformation.form.physicalAddress")
		titleTooltip = t("individualInformation.form.physicalAddressTooltip")
	} else if (addressType === "Business") {
		title = t("businessInformation.form.physicalAddress")
		titleTooltip = t("businessInformation.form.physicalAddressTooltip")
	}

	let statesDropdown = null

	if (country === US_COUNTRY_CODE) {
		statesDropdown = (
			<Dropdown
				name={`${namePrefix}state`}
				label={t("addressForm.state")}
				value={state}
				onChange={handleChange}
				onBlur={handleBlur}
				disabled={disabled}
			>
				<option value=""></option>
				{Object.keys(usStates).map((state) => (
					<option key={state} value={state}>
						{usStates[state]}
					</option>
				))}
			</Dropdown>
		)
	} else if (country === CA_COUNTRY_CODE) {
		statesDropdown = (
			<Dropdown
				name={`${namePrefix}state`}
				label={t("addressForm.state")}
				value={state}
				onChange={handleChange}
				onBlur={handleBlur}
				disabled={disabled}
			>
				<option value=""></option>
				{Object.keys(caStates).map((state) => (
					<option key={state} value={state}>
						{caStates[state]}
					</option>
				))}
			</Dropdown>
		)
	}

	return (
		<div className="address" ref={addressElement}>
			<h4>
				{title} <FontAwesomeIcon className={"tooltip-icon"} data-tip={titleTooltip} icon={faQuestionCircle} />
			</h4>
			<Textfield
				label={t("addressForm.street")}
				name={`${namePrefix}street`}
				value={street}
				onChange={handleChange}
				onBlur={handleBlur}
				hasPrefill={hasPrefill}
				disabled={disabled}
			/>
			<Textfield
				label={t("addressForm.apartment")}
				name={`${namePrefix}street2`}
				value={street2}
				onChange={handleChange}
				onBlur={handleBlur}
				hasPrefill={hasPrefill}
				disabled={disabled}
			/>
			<Textfield
				label={t("addressForm.city")}
				name={`${namePrefix}city`}
				value={city}
				onChange={handleChange}
				onBlur={handleBlur}
				hasPrefill={hasPrefill}
				disabled={disabled}
			/>
			{!isUsOnlyAddress ? (
				<Dropdown
					name={`${namePrefix}country`}
					label={t("addressForm.country")}
					value={country}
					onChange={handleChange}
					onBlur={handleBlur}
					hasPrefill={hasPrefill}
					disabled={disabled}
				>
					{Object.keys(countries).map((countryCode) => (
						<option key={countryCode} value={countryCode}>
							{countries[countryCode]}
						</option>
					))}
				</Dropdown>
			) : null}
			<div className="form-row two">
				{statesDropdown}
				<Textfield
					label={t("addressForm.zip")}
					pattern={"[0-9]*"}
					name={`${namePrefix}postalCode`}
					value={postalCode}
					onChange={handleChange}
					onBlur={handleBlur}
					hasPrefill={hasPrefill}
					disabled={disabled}
				/>
			</div>
			<ReactTooltip effect={"solid"} className={"tooltip"} />
		</div>
	)
}
