import React, {useEffect} from "react"
import {ApplicationForm, ApplicationFormStage, markDataCollectionViewed} from "../../../resources/applicationForm"
import {useTranslation} from "react-i18next"
import Admonition from "../../../components/Admonition"
import DataCollectionIndividual from "./DataCollectionIndividual"
import DataCollectionSoleProp from "./DataCollectionSoleProp"
import DataCollectionBusiness from "./DataCollectionBusiness"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
	updateApplicationForm: Function
	isRequestInProgress: boolean
	stageTitle: string
	stageDescription: string
}

export default function DataCollection({
	applicationForm,
	fetchApplicationForm,
	updateApplicationForm,
	isRequestInProgress,
	stageTitle,
	stageDescription,
}: Props) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}

	useEffect(() => {
		if (applicationForm.attributes.dataCollectionStatus === "Created") {
			const token = window.location.pathname.substring(1)
			markDataCollectionViewed(token)
		}
	}, [])

	function getDataCollectionComponent() {
		if (applicationForm.attributes.stage === ApplicationFormStage.IndividualApplicationCreated) {
			return (
				<DataCollectionIndividual
					applicationForm={applicationForm}
					fetchApplicationForm={fetchApplicationForm}
					updateApplicationForm={updateApplicationForm}
					isRequestInProgress={isRequestInProgress}
				/>
			)
		} else if (applicationForm.attributes.stage === ApplicationFormStage.SoleProprietorshipApplicationCreated) {
			return (
				<DataCollectionSoleProp
					applicationForm={applicationForm}
					fetchApplicationForm={fetchApplicationForm}
					updateApplicationForm={updateApplicationForm}
					isRequestInProgress={isRequestInProgress}
				/>
			)
		} else if (applicationForm.attributes.stage === ApplicationFormStage.BusinessApplicationCreated) {
			return (
				<DataCollectionBusiness
					applicationForm={applicationForm}
					fetchApplicationForm={fetchApplicationForm}
					updateApplicationForm={updateApplicationForm}
					isRequestInProgress={isRequestInProgress}
				/>
			)
		}
	}

	return (
		<div className="data-collection">
			<h1>{stageTitle}</h1>
			<p>{stageDescription}</p>
			<br />
			<Admonition type={"is-warning"} title={t("dataCollection.warningText")} />
			<br />

			{getDataCollectionComponent()}
		</div>
	)
}
