import {ParseError, parsePhoneNumberFromString} from "libphonenumber-js"
import * as Yup from "yup"
import {US_COUNTRY_CODE} from "../resources/common"

export function addCustomValidationMethods() {
	Yup.addMethod(Yup.string, "phoneNumber", function (message) {
		return this.test("phoneNumberTest", message, function (value) {
			let isError = false
			let phoneNumber

			try {
				isError = false
				const phone = value ?? ""

				phoneNumber = parsePhoneNumberFromString(phone, {
					defaultCountry: US_COUNTRY_CODE,
				})
			} catch (error) {
				if (error instanceof ParseError) {
					isError = true
				}

				console.log(error)
			}

			if (typeof phoneNumber === "undefined") {
				return false
			}

			return !isError && phoneNumber.isValid()
		})
	})
}

export const ssnInputMask = "999-99-9999"
export const ssnRegex = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/
export const einInputMask = "99-9999999"
export const einRegex = /^[0-9]{2}\-?[0-9]{7}$/
export const usPostalCode = /^[0-9]{5}(\-?[0-9]{4})?$/
export const caPostalCode = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/
export const yearRegex = /^(17|18|19|20)\d{2}$/

export const urlRegex =
	/^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i

export function validateSVG(base64Image: string) {
	const svgRegex = /^data:image\/svg\+xml;base64,([\w+/=]+)$/i

	// Check if the input is a valid Base64-encoded string
	if (!svgRegex.test(base64Image)) {
		return false
	}

	// Decode the Base64 image
	const decodedImage = atob(base64Image.replace(svgRegex, "$1"))

	// Create a dummy DOM element to parse the SVG
	const parser = new DOMParser()
	const svgDoc = parser.parseFromString(decodedImage, "image/svg+xml")

	// Check if the parsed document is an SVG
	if (!(svgDoc.documentElement instanceof SVGElement)) {
		return false
	}

	// Check for any script elements in the SVG
	const scriptElements = svgDoc.getElementsByTagName("script")

	if (scriptElements.length > 0) {
		return false
	}

	return true
}
