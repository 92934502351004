import {FormikErrors, FormikValues} from "formik"
import {flattenObject} from "./object"

export function scrollToTop(scrollBehavior: ScrollBehavior = "smooth") {
	window.scrollTo({
		top: 0,
		behavior: scrollBehavior,
	})
}

export function focusFirstInvalidField(errors: FormikErrors<FormikValues>) {
	const fieldKeys = Array.from(Object.keys(flattenObject(errors)))
	const selectors = fieldKeys.map((key) => `input[name="${key}"], select[name="${key}"]`)
	const firstInvalidField = document.querySelector(selectors.join(", ")) as HTMLInputElement | HTMLSelectElement
	firstInvalidField?.focus()
}

export function isCameraUploadSupported() {
	const el: any = document.createElement("input")
	return el.capture != undefined
}

export function isDateInputSupported() {
	const input = document.createElement("input")
	input.setAttribute("type", "date")

	// Some browsers pretend that they have a "date" input type but don't offer a UI that supports it,
	// so we will attempt to put an invalid date value and see if the field rejects it
	const notADateValue = "not-a-date"
	input.setAttribute("value", notADateValue)

	return input.value !== notADateValue
}
