import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "react-i18next"

export default function DataCollectionCompleted() {
	const {t} = useTranslation()

	return (
		<div className="individual-application-created">
			<form className="application-status">
				<div className={"approved"}>
					<h1>
						<FontAwesomeIcon className={"icon"} icon={faCheckCircle} /> {t("dataCollection.completedTitle")}
					</h1>
					<p>{t("dataCollection.completedDescription")}</p>
				</div>
			</form>
		</div>
	)
}
