import React, {useEffect} from "react"
import {
	ApplicationDocument,
	ApplicationDocumentSide,
	ApplicationDocumentStatus,
	uploadDocument,
} from "../../resources/applicationForm"
import classNames from "classnames"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCamera, faCheckCircle, faCircleNotch, faTimesCircle, faUpload} from "@fortawesome/free-solid-svg-icons"
import useAsyncResultIdle from "../../hooks/useAsyncResultIdle"
import {isCameraUploadSupported} from "../../utilities/dom"
import {useTranslation} from "react-i18next"

interface Props {
	document: ApplicationDocument
	title: string
	isBackDocument: boolean
	updateDocument: Function
}

export default function UploadDocument({document, title, isBackDocument = false, updateDocument}: Props) {
	const {t} = useTranslation()
	const [uploadDocumentState, upload] = useAsyncResultIdle(uploadDocument)

	useEffect(() => {
		uploadDocumentState.match(
			() => null,
			() => null,
			(result) => updateDocument(result.data),
			() => null
		)
	}, [uploadDocumentState])

	const uploadButtons = (
		<>
			<label className="upload-button">
				<span>
					<FontAwesomeIcon icon={faUpload} /> {t("applicationDocuments.form.uploadButton")}{" "}
				</span>
				<input
					accept={".png,.jpg,.jpeg,.pdf"}
					type="file"
					onChange={(e) => onFileUploaded(e, document, isBackDocument)}
				/>
			</label>
			{isCameraUploadSupported() ? (
				<>
					<label className="upload-button">
						<span>
							<FontAwesomeIcon icon={faCamera} /> {t("applicationDocuments.form.cameraButton")}{" "}
						</span>
						<input
							accept={"image/*"}
							type="file"
							capture="user"
							onChange={(e) => onFileUploaded(e, document, isBackDocument)}
						/>
					</label>
				</>
			) : null}
		</>
	)

	const loadingComponent = (
		<div className="loading">
			<FontAwesomeIcon icon={faCircleNotch} spin />
		</div>
	)

	const documentReceivedComponent = (
		<div className="document-received">
			<span>
				<FontAwesomeIcon className={"icon"} icon={faCheckCircle} />
				{` Received ${isBackDocument ? "Back" : "Front"} Side`}
			</span>
		</div>
	)

	const errorComponent = (
		<div className="upload-error">
			<span>
				<FontAwesomeIcon className={"icon"} icon={faTimesCircle} />
				{" Upload failed"}
			</span>
			<span>{uploadButtons}</span>
		</div>
	)

	let component

	if (
		(document.attributes.status === ApplicationDocumentStatus.ReceivedBack && isBackDocument) ||
		(document.attributes.status === ApplicationDocumentStatus.ReceivedFront && !isBackDocument)
	) {
		component = documentReceivedComponent
	} else {
		component = uploadDocumentState.match(
			() => uploadButtons,
			() => loadingComponent,
			() => documentReceivedComponent,
			() => errorComponent
		)
	}

	function onFileUploaded(
		e: React.ChangeEvent<HTMLInputElement>,
		document: ApplicationDocument,
		isBackDocument = false
	) {
		const files = e.target.files
		const token = window.location.pathname.substring(1)

		if (files) {
			const file = files[0]

			if (file) {
				const reader = new FileReader()

				reader.addEventListener(
					"load",
					async function () {
						await upload(
							token,
							document.id,
							file,
							file.type,
							isBackDocument ? ApplicationDocumentSide.Back : ApplicationDocumentSide.Front
						)
					},
					false
				)

				if (file) {
					reader.readAsDataURL(file)
					reader.onerror = function (event) {
						console.log(event)
					}
				}
			}
		}
	}

	return (
		<div className={classNames("upload-row")}>
			<div className="upload-title">{title}</div>
			{component}
		</div>
	)
}
