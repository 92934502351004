import React, {Dispatch, InputHTMLAttributes, SetStateAction} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import InputMask from "react-input-mask"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import ReactTooltip from "react-tooltip"
import {flattenObject} from "../utilities/object"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	label: string
	value: string | number
	onChange: Dispatch<SetStateAction<any>>
	onBlur: Dispatch<SetStateAction<any>>
	inputMask?: string
	alwaysShowMask?: boolean
	showInfoTooltip?: boolean
	infoTooltipText?: string
	disabled?: boolean
	hasPrefill?: boolean
}

export default function Textfield({
	name,
	label,
	value,
	onChange,
	onBlur,
	inputMask,
	alwaysShowMask = true,
	showInfoTooltip = false,
	infoTooltipText,
	disabled = false,
	hasPrefill = false,
	...inputProps
}: Props) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"textfield",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className} data-name={name} {...(hasPrefill && {"data-has-prefill": true})}>
			<label>
				<span className="label-text">
					{label} {showInfoTooltip ? <FontAwesomeIcon data-tip={infoTooltipText} icon={faQuestionCircle} /> : null}
				</span>
				{inputMask ? (
					<InputMask
						name={name}
						value={value}
						onChange={onChange}
						onBlur={onBlur}
						{...inputProps}
						mask={inputMask}
						alwaysShowMask={alwaysShowMask}
						disabled={disabled}
					/>
				) : (
					<input name={name} value={value} onChange={onChange} onBlur={onBlur} {...inputProps} disabled={disabled} />
				)}
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
			<ReactTooltip effect={"solid"} className={"tooltip"} />
		</div>
	)
}
