import React from "react"
import {focusFirstInvalidField} from "../../../utilities/dom"
import Dropdown from "../../../components/Dropdown"
import {US_COUNTRY_CODE} from "../../../resources/common"
import SingleSelection from "../../../components/SingleSelection"
import Button from "../../../components/Button"
import {Formik, FormikValues} from "formik"
import {ApplicationForm, getAnnualIncomes, getOccupations, getSourcesOfIncome} from "../../../resources/applicationForm"
import * as Yup from "yup"
import {useTranslation} from "react-i18next"
import {isUndefined} from "lodash"

interface Props {
	applicationForm: ApplicationForm
	fetchApplicationForm: () => void
	updateApplicationForm: Function
	isRequestInProgress: boolean
}

export default function DataCollectionIndividual({
	applicationForm,
	updateApplicationForm,
	fetchApplicationForm,
	isRequestInProgress,
}: Props) {
	const {t} = useTranslation() as unknown as {t: (key: string) => string}
	const annualIncomes = getAnnualIncomes()
	const occupations = getOccupations()
	const sourcesOfIncome = getSourcesOfIncome()
	const cddEddAttributes = applicationForm.attributes.cddEddAttributes
	const individualNationality = cddEddAttributes?.nationality ?? "US"
	const isHighRisk = applicationForm.attributes.customerRiskRate == "high"
	const isEddRequired = individualNationality !== US_COUNTRY_CODE || isHighRisk

	const validationSchema = Yup.object().shape({
		occupation: Yup.string()
			.required(t("validationErrorMessages.occupation.required"))
			.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid")),
		annualIncome: Yup.string()
			.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
			.when([], {
				is: () => isEddRequired,
				then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
			}),
		sourceOfIncome: Yup.string()
			.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
			.when([], {
				is: () => isEddRequired,
				then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
			}),
	})

	const initialFormValues = {
		occupation: cddEddAttributes?.occupation ?? "",
		annualIncome: cddEddAttributes?.annualIncome ?? "",
		sourceOfIncome: cddEddAttributes?.sourceOfIncome ?? "",
	}

	const submitForm = async (values: FormikValues) => {
		await updateApplicationForm("applicationFormCddEddAttributes", {
			occupation: values.occupation || null,
			...(isEddRequired
				? {
						annualIncome: values.annualIncome || null,
						sourceOfIncome: values.sourceOfIncome || null,
				  }
				: {}),
		})
		fetchApplicationForm()
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validateOnMount={true}
			validationSchema={validationSchema}
			onSubmit={(values) => submitForm(values)}
		>
			{({values, handleSubmit, handleChange, setFieldValue, errors, isValid, handleBlur}) => (
				<form
					noValidate
					onSubmit={(e) => {
						e.preventDefault()
						handleSubmit(e)
						if (!isValid) {
							focusFirstInvalidField(errors)
						}
					}}
				>
					{isUndefined(cddEddAttributes?.occupation) && (
						<Dropdown
							name={"occupation"}
							label={t("individualAdditionalInformation.form.occupation")}
							value={values.occupation}
							onChange={handleChange}
							onBlur={handleBlur}
						>
							<option key="default" defaultValue="" />
							{Object.keys(occupations).map((occupation) => (
								<option key={occupation} value={occupation}>
									{occupations[occupation]}
								</option>
							))}
						</Dropdown>
					)}
					{isEddRequired && (
						<>
							{isUndefined(cddEddAttributes?.annualIncome) && (
								<SingleSelection
									name={"annualIncome"}
									label={t("individualAdditionalInformation.form.annualIncome")}
									value={values.annualIncome}
									setFieldValue={setFieldValue}
									options={annualIncomes}
								/>
							)}

							{isUndefined(cddEddAttributes?.sourceOfIncome) && (
								<Dropdown
									name={"sourceOfIncome"}
									label={t("individualAdditionalInformation.form.sourceOfIncome")}
									value={values.sourceOfIncome}
									onChange={handleChange}
									onBlur={handleBlur}
								>
									<option key="default" defaultValue="" />
									{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
										<option key={sourceOfIncome} value={sourceOfIncome}>
											{sourcesOfIncome[sourceOfIncome]}
										</option>
									))}
								</Dropdown>
							)}
						</>
					)}

					<Button type="submit" isLoading={isRequestInProgress}>
						{t("dataCollection.submit")}
					</Button>
				</form>
			)}
		</Formik>
	)
}
