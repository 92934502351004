import React, {PropsWithChildren} from "react"
import {ApplicationFormType} from "../../resources/applicationForm"
import {useTranslation} from "react-i18next"

interface Props {
	applicationFormType: ApplicationFormType
}

export default function ApplicationTypeButtonText({applicationFormType}: PropsWithChildren<Props>) {
	const {t} = useTranslation()

	switch (applicationFormType as ApplicationFormType) {
		case ApplicationFormType.Individual:
			return (
				<div>
					<span className="type-title">{t("applicationType.form.individual.title")}</span>
					{" - "}
					{t("applicationType.form.individual.description")}
				</div>
			)
		case ApplicationFormType.SoleProprietorship:
			return (
				<div>
					<span className="type-title">{t("applicationType.form.soleProprietorship.title")}</span>
					{" - "}
					{t("applicationType.form.soleProprietorship.description")}
				</div>
			)
		case ApplicationFormType.Business:
			return (
				<div>
					<span className="type-title">{t("applicationType.form.business.title")}</span>
					{" - "}
					{t("applicationType.form.business.description")}
				</div>
			)
		default:
			return null
	}
}
