import React, {PropsWithChildren} from "react"
import {Formik, FormikValues} from "formik"
import * as Yup from "yup"
import {focusFirstInvalidField} from "../../utilities/dom"
import Textfield from "../../components/Textfield"
import Address from "../../components/Address"
import {
	addCustomValidationMethods,
	caPostalCode,
	ssnInputMask,
	ssnRegex,
	usPostalCode,
} from "../../utilities/validation"
import {parsePhoneNumber} from "libphonenumber-js"
import Dropdown from "../../components/Dropdown"
import Checkbox from "../../components/Checkbox"
import {sanitizeNumberFormat} from "../../utilities/form"
import {
	ApplicantDetails,
	ApplicationForm,
	countries,
	getAnnualIncomes,
	getOccupations,
	getSourcesOfIncome,
} from "../../resources/applicationForm"
import {CA_COUNTRY_CODE, formatPhoneNumber, toPhoneNumber, US_COUNTRY_CODE} from "../../resources/common"
import Button from "../../components/Button"
import Datefield from "../../components/Datefield"
import {useTranslation} from "react-i18next"
import SingleSelection from "../../components/SingleSelection"
import {isUndefined} from "lodash"
import moment from "moment"

addCustomValidationMethods()

interface Props {
	updateApplicationForm: Function
	isRequestInProgress: boolean
	applicantDetails?: ApplicantDetails
	applicationForm: ApplicationForm
	validatePhoneNumber: boolean
}

export default function OfficerInformation({
	applicationForm,
	updateApplicationForm,
	isRequestInProgress,
	applicantDetails,
	validatePhoneNumber,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()
	const annualIncomes = getAnnualIncomes()
	const occupations = getOccupations()
	const sourcesOfIncome = getSourcesOfIncome()

	const stateBusinessAdditionalInformation = applicationForm.attributes.state?.enterBusinessAdditionalInformation || {}
	const stateOfficerInformation = applicationForm.attributes.state?.enterOfficerInformation

	const validationSchema = Yup.object().shape({
		officer: Yup.object().shape({
			firstName: Yup.string().required(t("validationErrorMessages.firstName.required")),
			lastName: Yup.string().required(t("validationErrorMessages.lastName.required")),
			ssn: Yup.string().when("nationality", {
				is: US_COUNTRY_CODE,
				then: Yup.string()
					.required(t("validationErrorMessages.ssn.required"))
					.matches(ssnRegex, t("validationErrorMessages.ssn.invalid")),
			}),
			nationality: Yup.string().required(t("validationErrorMessages.nationality.required")),
			passport: Yup.string().when("nationality", {
				is: (nationality: string) => nationality !== US_COUNTRY_CODE,
				then: Yup.string().required(t("validationErrorMessages.passport.required")),
			}),
			street: Yup.string().required(t("validationErrorMessages.street.required")),
			// street2:
			city: Yup.string().required(t("validationErrorMessages.city.required")),
			state: Yup.string().when("country", {
				is: (country: string) => [US_COUNTRY_CODE, CA_COUNTRY_CODE].includes(country),
				then: Yup.string().required(t("validationErrorMessages.state.required")),
			}),
			postalCode: Yup.string()
				.required(t("validationErrorMessages.postalCode.required"))
				.when("country", {
					is: US_COUNTRY_CODE,
					then: Yup.string().matches(usPostalCode, t("validationErrorMessages.postalCode.usInvalid")),
				})
				.when("country", {
					is: CA_COUNTRY_CODE,
					then: Yup.string().matches(caPostalCode, t("validationErrorMessages.postalCode.caInvalid")),
				}),
			country: Yup.string().required(t("validationErrorMessages.country.required")),
			dateOfBirth: Yup.date()
				.required(t("validationErrorMessages.dateOfBirth.required"))
				.max(moment().subtract(18, "years"), t("validationErrorMessages.dateOfBirth.max"))
				.test("is-valid-year", t("validationErrorMessages.dateOfBirth.invalid"), function (value) {
					const year = moment(value).year()
					return year >= 1900
				}),
			email: Yup.string()
				.email(t("validationErrorMessages.email.invalid"))
				.required(t("validationErrorMessages.email.required")),
			phone: Yup.string()
				.required(t("validationErrorMessages.phone.required"))
				.phoneNumber(t("validationErrorMessages.phone.invalid")),
			occupation: Yup.string()
				.oneOf(Object.keys(occupations), t("validationErrorMessages.occupation.invalid"))
				.when([], {
					is: () => !applicationForm.attributes.disableRegulatoryValidations,
					then: Yup.string().required(t("validationErrorMessages.occupation.required")),
				}),
			annualIncome: Yup.string()
				.oneOf(Object.keys(annualIncomes), t("validationErrorMessages.annualIncome.invalid"))
				.when([], {
					is: () =>
						stateBusinessAdditionalInformation.hasNonUsEntities &&
						!applicationForm.attributes.disableRegulatoryValidations,
					then: Yup.string().required(t("validationErrorMessages.annualIncome.required")),
				}),
			sourceOfIncome: Yup.string()
				.oneOf(Object.keys(sourcesOfIncome), t("validationErrorMessages.sourceOfIncome.invalid"))
				.when([], {
					is: () =>
						stateBusinessAdditionalInformation.hasNonUsEntities &&
						!applicationForm.attributes.disableRegulatoryValidations,
					then: Yup.string().required(t("validationErrorMessages.sourceOfIncome.required")),
				}),
		}),
		businessContact: Yup.object().when("primaryPointOfContact", {
			is: false,
			then: Yup.object().shape({
				firstName: Yup.string().required(t("validationErrorMessages.firstName.required")),
				lastName: Yup.string().required(t("validationErrorMessages.lastName.required")),
				email: Yup.string()
					.email(t("validationErrorMessages.email.invalid"))
					.required(t("validationErrorMessages.email.required")),
				phone: Yup.string()
					.required(t("validationErrorMessages.phone.required"))
					.phoneNumber(t("validationErrorMessages.phone.invalid")),
			}),
		}),
		// title:
	})

	const contactPhone = stateOfficerInformation?.businessContact.phone ?? applicantDetails?.contact?.phone
	const primaryPointOfContact = !stateOfficerInformation?.businessContact && !applicantDetails?.contact
	const phone =
		primaryPointOfContact && !validatePhoneNumber && contactPhone
			? contactPhone
			: stateOfficerInformation?.officer.phone ?? applicantDetails?.officer?.phone

	const initialFormValues = {
		officer: {
			firstName: stateOfficerInformation?.officer?.fullName?.first ?? applicantDetails?.officer?.fullName?.first ?? "",
			lastName: stateOfficerInformation?.officer?.fullName?.last ?? applicantDetails?.officer?.fullName?.last ?? "",
			phone: phone ? toPhoneNumber(phone) : "",
			email: stateOfficerInformation?.officer?.email ?? applicantDetails?.officer?.email ?? "",
			dateOfBirth: stateOfficerInformation?.officer?.dateOfBirth ?? applicantDetails?.officer?.dateOfBirth ?? "",
			ssn: stateOfficerInformation?.officer?.ssn ?? applicantDetails?.officer?.ssn ?? "",
			nationality:
				stateOfficerInformation?.officer?.nationality ?? applicantDetails?.officer?.nationality ?? US_COUNTRY_CODE,
			passport: stateOfficerInformation?.officer?.passport ?? applicantDetails?.officer?.passport ?? "",
			street: stateOfficerInformation?.officer?.address?.street ?? applicantDetails?.officer?.address?.street ?? "",
			street2: stateOfficerInformation?.officer?.address?.street2 ?? applicantDetails?.officer?.address?.street2 ?? "",
			city: stateOfficerInformation?.officer?.address.city ?? applicantDetails?.officer?.address?.city ?? "",
			country:
				stateOfficerInformation?.officer?.address?.country ??
				applicantDetails?.officer?.address?.country ??
				US_COUNTRY_CODE,
			state: stateOfficerInformation?.officer?.address.state ?? applicantDetails?.officer?.address?.state ?? "",
			postalCode:
				stateOfficerInformation?.officer?.address.postalCode ?? applicantDetails?.officer?.address?.postalCode ?? "",
			title: stateOfficerInformation?.officer?.title ?? applicantDetails?.officer?.title ?? "CEO",
			occupation: stateOfficerInformation?.officer?.occupation ?? applicantDetails?.officer?.occupation ?? "",
			annualIncome: stateOfficerInformation?.officer?.annualIncome ?? applicantDetails?.officer?.annualIncome ?? "",
			sourceOfIncome:
				stateOfficerInformation?.officer?.sourceOfIncome ?? applicantDetails?.officer?.sourceOfIncome ?? "",
		},
		primaryPointOfContact: primaryPointOfContact,
		businessContact: {
			firstName:
				stateOfficerInformation?.businessContact?.fullName?.first ?? applicantDetails?.contact?.fullName?.first ?? "",
			lastName:
				stateOfficerInformation?.businessContact?.fullName?.last ?? applicantDetails?.contact?.fullName?.last ?? "",
			email: stateOfficerInformation?.businessContact?.email ?? applicantDetails?.contact?.email ?? "",
			phone: contactPhone ? toPhoneNumber(contactPhone) : "",
		},
		officerIsBeneficialOwner:
			stateOfficerInformation?.officerIsBeneficialOwner ?? applicantDetails?.officerIsBeneficialOwner ?? false,
	}

	const prefillExistsForField = {
		officer: {
			fullName: !isUndefined(applicantDetails?.officer?.fullName),
			phone: !isUndefined(applicantDetails?.officer?.phone),
			email: !isUndefined(applicantDetails?.officer?.email),
			dateOfBirth: !isUndefined(applicantDetails?.officer?.dateOfBirth),
			ssn: !isUndefined(applicantDetails?.officer?.ssn),
			nationality: !isUndefined(applicantDetails?.officer?.nationality),
			passport: !isUndefined(applicantDetails?.officer?.passport),
			address: !isUndefined(applicantDetails?.officer?.address),
			title: !isUndefined(applicantDetails?.officer?.title),
			occupation: !isUndefined(applicantDetails?.officer?.occupation),
			annualIncome: !isUndefined(applicantDetails?.officer?.annualIncome),
			sourceOfIncome: !isUndefined(applicantDetails?.officer?.sourceOfIncome),
		},
		businessContact: {
			fullName: !isUndefined(applicantDetails?.contact?.fullName),
			email: !isUndefined(applicantDetails?.contact?.email),
			phone: !isUndefined(applicantDetails?.contact?.phone),
		},
	}

	const isForcePrefill = applicationForm.attributes.forcePrefill

	const submitForm = (values: FormikValues) => {
		updateApplicationForm("applicationFormOfficerInformation", {
			officer: {
				fullName: {
					first: values.officer.firstName,
					last: values.officer.lastName,
				},
				phone: {
					countryCode: parsePhoneNumber(values.officer.phone, US_COUNTRY_CODE).countryCallingCode,
					number: parsePhoneNumber(values.officer.phone, US_COUNTRY_CODE).nationalNumber,
				},
				address: {
					street: values.officer.street,
					street2: values.officer.street2,
					city: values.officer.city,
					country: values.officer.country,
					state: values.officer.state,
					postalCode: values.officer.postalCode,
				},
				title: values.officer.title,
				email: values.officer.email,
				dateOfBirth: values.officer.dateOfBirth,
				ssn: values.officer.nationality === US_COUNTRY_CODE ? sanitizeNumberFormat(values.officer.ssn) : null,
				nationality: values.officer.nationality !== US_COUNTRY_CODE ? values.officer.nationality : null,
				passport: values.officer.nationality !== US_COUNTRY_CODE ? values.officer.passport : null,
				occupation: values.officer.occupation || null,
				...(stateBusinessAdditionalInformation.hasNonUsEntities
					? {
							annualIncome: values.officer.annualIncome || null,
							sourceOfIncome: values.officer.sourceOfIncome || null,
					  }
					: {}),
			},
			businessContact: values.primaryPointOfContact
				? {
						fullName: {
							first: values.officer.firstName,
							last: values.officer.lastName,
						},
						phone: {
							countryCode: parsePhoneNumber(values.officer.phone, US_COUNTRY_CODE).countryCallingCode,
							number: parsePhoneNumber(values.officer.phone, US_COUNTRY_CODE).nationalNumber,
						},
						email: values.officer.email,
				  }
				: {
						fullName: {
							first: values.businessContact.firstName,
							last: values.businessContact.lastName,
						},
						phone: {
							countryCode: parsePhoneNumber(values.businessContact.phone, US_COUNTRY_CODE).countryCallingCode,
							number: parsePhoneNumber(values.businessContact.phone, US_COUNTRY_CODE).nationalNumber,
						},
						email: values.businessContact.email,
				  },
			officerIsBeneficialOwner: values.officerIsBeneficialOwner,
		})
	}

	return (
		<div className="officer-information">
			<h1>{t("officerInformation.pageTitle")}</h1>
			<p>{t("officerInformation.pageDescription")}</p>
			<br />
			<Formik
				initialValues={initialFormValues}
				validateOnMount={true}
				validationSchema={validationSchema}
				onSubmit={(values) => submitForm(values)}
			>
				{({values, handleSubmit, handleChange, handleBlur, setFieldValue, errors, isValid}) => (
					<form
						noValidate
						onSubmit={(e) => {
							e.preventDefault()
							handleSubmit(e)
							if (!isValid) {
								focusFirstInvalidField(errors)
							}
						}}
					>
						<div className="form-row two">
							<Textfield
								name="officer.firstName"
								label={t("officerInformation.form.firstName")}
								value={values.officer.firstName}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.officer.fullName}
								disabled={isForcePrefill && prefillExistsForField.officer.fullName}
							/>
							<Textfield
								name="officer.lastName"
								label={t("officerInformation.form.lastName")}
								value={values.officer.lastName}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.officer.fullName}
								disabled={isForcePrefill && prefillExistsForField.officer.fullName}
							/>
						</div>
						<Dropdown
							name="officer.title"
							label={t("officerInformation.form.title")}
							value={values.officer.title}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.officer.title}
							disabled={isForcePrefill && prefillExistsForField.officer.title}
						>
							<option value="CEO">{t("officerInformation.form.titlesList.ceo")}</option>
							<option value="COO">{t("officerInformation.form.titlesList.coo")}</option>
							<option value="CFO">{t("officerInformation.form.titlesList.cfo")}</option>
							<option value="President">{t("officerInformation.form.titlesList.president")}</option>
							<option value="BenefitsAdministrationOfficer">
								{t("officerInformation.form.titlesList.benefitsAdministrationOfficer")}
							</option>
							<option value="CIO">{t("officerInformation.form.titlesList.cio")}</option>
							<option value="VP">{t("officerInformation.form.titlesList.vp")}</option>
							<option value="AVP">{t("officerInformation.form.titlesList.avp")}</option>
							<option value="Treasurer">{t("officerInformation.form.titlesList.treasurer")}</option>
							<option value="Secretary">{t("officerInformation.form.titlesList.secretary")}</option>
							<option value="Controller">{t("officerInformation.form.titlesList.controller")}</option>
							<option value="Manager">{t("officerInformation.form.titlesList.manager")}</option>
							<option value="Partner">{t("officerInformation.form.titlesList.partner")}</option>
							<option value="Member">{t("officerInformation.form.titlesList.member")}</option>
						</Dropdown>
						<Textfield
							name="officer.phone"
							label={t("officerInformation.form.phone")}
							type={"tel"}
							value={
								!validatePhoneNumber && values.primaryPointOfContact
									? formatPhoneNumber(values.businessContact.phone)
									: formatPhoneNumber(values.officer.phone)
							}
							onChange={handleChange}
							onBlur={handleBlur}
							disabled={
								(!validatePhoneNumber && values.primaryPointOfContact) ||
								(isForcePrefill && prefillExistsForField.officer.phone)
							}
							hasPrefill={prefillExistsForField.officer.phone}
							infoTooltipText={
								"This phone number is the primary contact number, and will occasionally be used to verify your identity and protect your account"
							}
							showInfoTooltip={!validatePhoneNumber && values.primaryPointOfContact}
						/>
						<Textfield
							name="officer.email"
							label={t("officerInformation.form.email")}
							type={"email"}
							value={values.officer.email}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.officer.email}
							disabled={isForcePrefill && prefillExistsForField.officer.email}
						/>
						<div className="form-row two">
							<Datefield
								name="officer.dateOfBirth"
								label={t("officerInformation.form.dateOfBirth")}
								value={values.officer.dateOfBirth}
								setFieldValue={setFieldValue}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.officer.dateOfBirth}
								disabled={isForcePrefill && prefillExistsForField.officer.dateOfBirth}
							/>
							<Dropdown
								name="officer.nationality"
								label={t("officerInformation.form.nationality")}
								value={values.officer.nationality}
								onChange={handleChange}
								onBlur={handleBlur}
								hasPrefill={prefillExistsForField.officer.nationality}
								disabled={
									isForcePrefill && (prefillExistsForField.officer.ssn || prefillExistsForField.officer.nationality)
								}
							>
								{Object.keys(countries).map((countryCode) => (
									<option key={countryCode} value={countryCode}>
										{countries[countryCode]}
									</option>
								))}
							</Dropdown>
						</div>
						<div className="form-row two">
							{values.officer.nationality === US_COUNTRY_CODE ? (
								<Textfield
									name="officer.ssn"
									label={t("officerInformation.form.ssn")}
									value={values.officer.ssn}
									onChange={handleChange}
									onBlur={handleBlur}
									inputMask={ssnInputMask}
									showInfoTooltip={true}
									infoTooltipText={t("officerInformation.form.ssnTooltip")}
									hasPrefill={prefillExistsForField.officer.ssn}
									disabled={isForcePrefill && prefillExistsForField.officer.ssn}
								/>
							) : (
								<Textfield
									name="officer.passport"
									label={t("officerInformation.form.passport")}
									value={values.officer.passport}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.officer.passport}
									disabled={isForcePrefill && prefillExistsForField.officer.passport}
								/>
							)}
						</div>
						<Address
							addressType={"Individual"}
							street={values.officer.street}
							street2={values.officer.street2}
							city={values.officer.city}
							country={values.officer.country}
							state={values.officer.state}
							postalCode={values.officer.postalCode}
							isUsOnlyAddress={false}
							handleChange={handleChange}
							handleBlur={handleBlur}
							setFieldValue={setFieldValue}
							namePrefix={"officer."}
							hasPrefill={prefillExistsForField.officer.address}
							disabled={isForcePrefill && prefillExistsForField.officer.address}
						/>

						<Dropdown
							name={"officer.occupation"}
							label={t("individualAdditionalInformation.form.occupation")}
							value={values.officer.occupation}
							onChange={handleChange}
							onBlur={handleBlur}
							hasPrefill={prefillExistsForField.officer.occupation}
							disabled={isForcePrefill && prefillExistsForField.officer.occupation}
						>
							<option key="default" defaultValue="" />
							{Object.keys(occupations).map((occupation) => (
								<option key={occupation} value={occupation}>
									{occupations[occupation]}
								</option>
							))}
						</Dropdown>

						{stateBusinessAdditionalInformation.hasNonUsEntities && (
							<>
								<SingleSelection
									name={"officer.annualIncome"}
									label={t("individualAdditionalInformation.form.annualIncome")}
									value={values.officer.annualIncome}
									setFieldValue={setFieldValue}
									options={annualIncomes}
									hasPrefill={prefillExistsForField.officer.annualIncome}
									disabled={isForcePrefill && prefillExistsForField.officer.annualIncome}
								/>

								<Dropdown
									name={"officer.sourceOfIncome"}
									label={t("individualAdditionalInformation.form.sourceOfIncome")}
									value={values.officer.sourceOfIncome}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.officer.sourceOfIncome}
									disabled={isForcePrefill && prefillExistsForField.officer.sourceOfIncome}
								>
									<option key="default" defaultValue="" />
									{Object.keys(sourcesOfIncome).map((sourceOfIncome) => (
										<option key={sourceOfIncome} value={sourceOfIncome}>
											{sourcesOfIncome[sourceOfIncome]}
										</option>
									))}
								</Dropdown>
							</>
						)}
						<Checkbox
							name="primaryPointOfContact"
							text={t("officerInformation.form.primaryPointOfContact")}
							checked={values.primaryPointOfContact}
							onChange={(e: React.ChangeEvent<any>) => {
								// If the officer is set to be the primary contact && we don't validate phone number then
								// set officer phone to be the prefilled business contact
								setFieldValue(
									"officer.phone",
									!validatePhoneNumber && !values.primaryPointOfContact
										? formatPhoneNumber(values.businessContact.phone)
										: formatPhoneNumber(values.officer.phone)
								)
								handleChange(e)
							}}
							onBlur={handleBlur}
						/>
						{!applicantDetails?.beneficialOwners?.length && (
							<Checkbox
								name="officerIsBeneficialOwner"
								text={t("officerInformation.form.officerIsBeneficialOwner")}
								showInfoTooltip={true}
								infoTooltipText={t("officerInformation.form.officerIsBeneficialOwnerTooltip")}
								checked={values.officerIsBeneficialOwner}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						)}
						{values.primaryPointOfContact ? null : (
							<div className="business-contact">
								<h5>{t("officerInformation.form.businessContact")}</h5>
								<div className="form-row two">
									<Textfield
										name="businessContact.firstName"
										label={t("officerInformation.form.firstName")}
										value={values.businessContact.firstName}
										onChange={handleChange}
										onBlur={handleBlur}
										hasPrefill={prefillExistsForField.businessContact.fullName}
										disabled={isForcePrefill && prefillExistsForField.businessContact.fullName}
									/>
									<Textfield
										name="businessContact.lastName"
										label={t("officerInformation.form.lastName")}
										value={values.businessContact.lastName}
										onChange={handleChange}
										onBlur={handleBlur}
										hasPrefill={prefillExistsForField.businessContact.fullName}
										disabled={isForcePrefill && prefillExistsForField.businessContact.fullName}
									/>
								</div>
								<Textfield
									name="businessContact.email"
									label={t("officerInformation.form.email")}
									type={"email"}
									value={values.businessContact.email}
									onChange={handleChange}
									onBlur={handleBlur}
									hasPrefill={prefillExistsForField.businessContact.email}
									disabled={isForcePrefill && prefillExistsForField.businessContact.email}
								/>
								<Textfield
									name="businessContact.phone"
									label={t("officerInformation.form.phone")}
									type={"tel"}
									value={formatPhoneNumber(values.businessContact.phone)}
									disabled={!validatePhoneNumber || (isForcePrefill && prefillExistsForField.businessContact.phone)}
									onChange={handleChange}
									onBlur={handleBlur}
									infoTooltipText={
										"This phone number is the primary contact number, and will occasionally be used to verify your identity and protect your account"
									}
									showInfoTooltip={!validatePhoneNumber}
									hasPrefill={prefillExistsForField.businessContact.phone}
								/>
							</div>
						)}
						<Button type="submit" isLoading={isRequestInProgress}>
							{t("officerInformation.form.submit")}
						</Button>
					</form>
				)}
			</Formik>
		</div>
	)
}
