{
  "applicationForm": {
    "previousStep": "Previous Step",
    "step": "Step",
    "submit": "Continue",
    "applicationType": {
      "introHeadline": "Let's get started.",
      "intro": "To begin your application, please provide a few additional pieces of information below.",
      "pageTitle": "Application Type",
      "pageDescription": "Please choose your creator business entity. Not sure? If you’re working alone, you’re probably a sole proprietorship.",
      "contactUsLink": "Not sure? <url>Contact us</url> and we’ll be happy to help.",
      "form": {
        "individual": {
          "title": "Individual",
          "description": "Choose this if the account will be used for your personal finances"
        },
        "soleProprietorship": {
          "title": "Sole Proprietorship",
          "description": "Choose this if you are an individual in full ownership of a business"
        },
        "business": {
          "title": "Business",
          "description": "Choose this if you are applying on behalf of a corporation, LLC or limited liability partnership"
        }
      }
    },
    "individualInformation": {
      "pageTitle": "Individual Information",
      "soleProprietorInformation": {
        "pageTitle": "Individual Information",
        "form": {
          "dba": "Doing Business As (Optional)",
          "ein": "Employer Identification Number (Optional)"
        }
      },
      "form": {
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify your identity, we’ll need to know your full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "passportTooltip": "To verify your identity, we’ll need to know your full Passport.",
        "physicalAddress": "Physical Address",
        "physicalAddressTooltip": "PO boxes cannot be used as an address",
        "privacyNotice": "By filling out this application, you agree to our <url>Privacy Policy</url>.",
        "submit": "Submit"
      }
    },
    "businessInformation": {
      "pageTitle": "Business Information",
      "pageDescription": "",
      "form": {
        "entityType": "Entity Type",
        "industryType": "Industry",
        "entities": {
          "corporation": "Corporation",
          "llc": "LLC",
          "partnership": "Partnership"
        },
        "businessName": "Legal Business Name",
        "dba": "Doing Business As (Optional)",
        "ein": "Employer Identification Number",
        "phone": "Mobile Phone Number",
        "stateOfIncorporation": "State of Incorporation",
        "website": "Business Website (Optional)",
        "physicalAddress": "Physical Address",
        "physicalAddressTooltip": "PO boxes cannot be used as an address",
        "privacyNotice": "By filling out this application, you agree to our <url>Privacy Policy</url>.",
        "submit": "Continue"
      }
    },
    "officerInformation": {
      "pageTitle": "Officer Information",
      "pageDescription": "Please enter information about one officer at the business",
      "form": {
        "firstName": "First Name",
        "lastName": "Last Name",
        "title": "Title",
        "titlesList": {
          "ceo": "CEO",
          "coo": "COO",
          "cfo": "CFO",
          "president": "President",
          "benefitsAdministrationOfficer": "Benefits",
          "cio": "CIO",
          "vp": "VP",
          "avp": "AVP",
          "treasurer": "Treasurer",
          "secretary": "Secretary",
          "controller": "Controller",
          "manager": "Manager",
          "partner": "Partner",
          "member": "Member"
        },
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify the identity, we’ll need to know the officer's full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "primaryPointOfContact": "This officer is also the business's primary point of contact",
        "businessContact": "Business contact",
        "submit": "Continue",
        "officerIsBeneficialOwner": "This officer is also a beneficial owner",
        "officerIsBeneficialOwnerTooltip": "A beneficial owner is anyone who holds more than 25% of the company"
      }
    },
    "beneficialOwnersInformation": {
      "pageTitle": "Beneficial Owners Information",
      "pageDescription": "Please also fill out the following information pertaining to any owners who hold 25% or more of your organization",
      "form": {
        "numberOfBeneficialOwners": "How Many Owners Hold 25% or More?",
        "numberOfBeneficialOwnersList": {
          "zero": "Zero",
          "one": "One",
          "two": "Two",
          "three": "Three",
          "four": "Four"
        },
        "owner": "Owner",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Mobile Phone Number",
        "email": "Email",
        "dateOfBirth": "Date Of Birth",
        "nationality": "Nationality",
        "ssn": "Social Security Number",
        "ssnTooltip": "To verify your identity, we’ll need to know your full Social Security Number or Individual Taxpayer Identification Number.",
        "passport": "Passport",
        "percentage": "Percentage",
        "beneficialOwnersAgreement": "By providing the information above, I hereby certify, to the best of my knowledge, that the information provided above is complete and correct.",
        "submit": "Submit"
      }
    },
    "phoneVerification": {
      "pageTitle": "Phone Verification",
      "pageDescription": "We sent you an SMS verification code to: {{phoneNumber}}",
      "form": {
        "submit": "Verify",
        "resend": "Resend"
      },
      "tryToResendMessage": "Please try to resend or try again later"
    },
    "applicationCreated": {
      "pageTitle": "Application Created"
    },
    "addressForm": {
      "street": "Street",
      "apartment": "Apartment, unit, suite, or floor # (Optional)",
      "city": "City",
      "country": "Country",
      "state": "State",
      "zip": "Zip Code"
    },
    "legalAgreement": {
      "privacyPolicy": "Privacy Policy",
      "consentToElectronicDisclosures": "Consent to Electronic Disclosures",
      "depositTermsConditions": "Deposit Terms & Conditions",
      "clientTermsOfService": "Client Terms of Service",
      "cardholderTermsConditions": "Cardholder Terms and Conditions",
      "cashAdvanceTermsConditions": "Cash Advance Terms and Conditions",
      "agreement": "I agree to the",
      "bankNotice": "{{clientName}} is a financial technology company and is not a bank. Banking services provided by {{bankName}}; Member FDIC.",
      "visaNotice": "The {{clientName}} Visa® Debit Card is issued by the sponsor bank as named in the Debit Cardholder agreement pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted.",
      "debitCardDisclosure": "Debit Card Disclosure"
    },
    "applicationDocuments": {
      "documentTypes": {
        "idDocument": {
          "title": "ID",
          "description": "Please provide a copy of your unexpired government issued photo ID which would include Drivers License or State ID."
        },
        "passport": {
          "shortKey": "Passport",
          "title": "Passport",
          "description": "Please provide a copy of your unexpired passport."
        },
        "addressVerification": {
          "shortKey": "Address",
          "title": "Address Verification",
          "description": "Please provide a document to verify your address. Document may be a utility bill, bank statement, lease agreement or current pay stub."
        },
        "ssn": {
          "shortKey": "SSN",
          "title": "Social Security Card",
          "description": "Please provide a document verifying your Social Security Number (SSN) such as a tax return or social security card."
        },
        "socialSecurityCard": {
          "shortKey": "SSN",
          "title": "Social Security Card",
          "description": "Please provide a document verifying your Social Security Number (SSN) such as a tax return or social security card."
        },
        "certificateOfIncorporation": {
          "title": "Certificate Of Incorporation",
          "description": "For Corporation: Please provide a certified copy of the Articles of Incorporation or Certificate of Incorporation. For Partnership: Please provide a copy of the Partnership Agreement. For LLC: Please provide a certified copy of the Articles of Organization."
        },
        "employerIdentificationNumberConfirmation": {
          "shortKey": "EIN",
          "title": "Employer Identification Number Confirmation",
          "description": "Please provide a document verifying your Federal EIN such as a business tax return, IRS 147c letter or the IRS Form CP 575."
        }
      },
      "form": {
        "required": "required for {{clientName}}",
        "uploadDocument": "Upload Document:",
        "uploadFront": "Upload Document's Front Side:",
        "uploadBack": "Upload Document's Back Side",
        "uploadButton": "Upload File",
        "cameraButton": "Open Camera"
      },
      "documentStatuses": {
        "approved": "Document Approved",
        "pendingReview": "Pending review",
        "invalid": "Invalid document"
      },
      "formStatuses": {
        "approved": {
          "pageTitle": "Approved Application",
          "pageDescription": "The application has been approved"
        },
        "denied": {
          "pageTitle": "Denied Application",
          "pageDescription": "The application has been denied"
        },
        "canceled": {
          "pageTitle": "Canceled Application",
          "pageDescription": "The application has been canceled"
        },
        "pending": {
          "pageTitle": "Processing Application",
          "pageDescription": "Your application is currently being processed. This can takeup to a minute, please do not close the window while the application is in progress. Thank you for your patience.",
          "delayMessage": "Evaluating your application takes longer than expected, and may require offline processing. You may try again, or click “continue” to leave the application and come back at another time.",
          "loading": "Please wait",
          "reTry": "Try Again",
          "contactSupport": "If the problem persists, contact <url> support </url>"
        },
        "pendingReview": {
          "pageTitle": "Pending Review",
          "pageDescription": "The application has been submitted and is currently being evaluated. Once the evaluation is complete, you will receive the application result by e-mail."
        },
        "awaitingDocuments": {
          "pageTitle": "Upload Additional Documents",
          "pageDescription": "In order to verify your information and make a decision on your application, you would need to provide additional documentation."
        }
      }
    },
    "validationErrorMessages": {
      "error": "An error has occurred",
      "firstName": {
        "required": "Please enter your first name"
      },
      "lastName": {
        "required": "Please enter your last name"
      },
      "phone": {
        "required": "Please enter your mobile phone number",
        "invalid": "The phone number you entered is invalid"
      },
      "email": {
        "required": "Please enter your email address",
        "invalid": "The email address you entered is invalid"
      },
      "ssn": {
        "required": "Please enter your full, 9-digit Social Security Number",
        "invalid": "The Social Security Number you have provided is invalid (format is xxx-xx-xxxx)"
      },
      "nationality": {
        "required": "Please select the nationality"
      },
      "passport": {
        "required": "Please enter your full passport"
      },
      "street": {
        "required": "Please enter the street name"
      },
      "city": {
        "required": "Please enter your city of residence"
      },
      "state": {
        "required": "Please select the state of residence"
      },
      "postalCode": {
        "required": "Please enter your zip code (must be 5 or 9 digits long)",
        "usInvalid": "The zip code you have provided is invalid (US zip codes must be 5 or 9 digits long)",
        "caInvalid": "The zip code you have provided is invalid (CA zip codes are in a A1A 1A1 format, where A is a letter and 1 is a digit)"
      },
      "country": {
        "required": "Please select the country of residence"
      },
      "dateOfBirth": {
        "required": "Please enter the date of birth",
        "max": "You must be at least 18 years old to submit an application"
      },
      "businessName": {
        "required": "Please enter your legal business name"
      },
      "stateOfIncorporation": {
        "required": "Please select the Business’s state of incorporation"
      },
      "ein": {
        "required": "Please enter the 9-digit employer identification number",
        "invalid": "The EIN you have entered is invalid (must be 9 digits long)"
      },
      "website": {
        "invalid": "The website URL you have provided is invalid"
      },
      "agreement": {
        "required": "Checking this field is required"
      },
      "businessEntityType": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "industry": {
        "required": "This field is required",
        "invalid": "Invalid value"
      },
      "beneficialOwnershipPercentage": {
        "required": "Please enter the beneficial owner’s holding (%) in the business",
        "range": "Range must be between 0 and 100"
      },
      "phoneVerificationNumber": {
        "required": "Please enter the verification code",
        "invalid": "Please enter a digit"
      }
    }
  }
}