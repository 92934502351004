import React, {Dispatch, ReactElement, SetStateAction, useEffect} from "react"
import Checkbox from "../Checkbox"
import {ApplicationForm} from "../../resources/applicationForm"
import {useTranslation} from "react-i18next"
import {filterNotUrlValuesFromMap} from "../../utilities/object"

interface Props {
	applicationForm: ApplicationForm
	agreement: boolean
	onChange: Dispatch<SetStateAction<any>>
	onBlur: Dispatch<SetStateAction<any>>
	prefix?: string | ReactElement
	setFieldValue: Function
}

export default function AgreementCheckbox({
	applicationForm,
	agreement,
	onChange,
	onBlur,
	prefix,
	setFieldValue,
}: Props) {
	const {t} = useTranslation()
	const applicationFormSettings = applicationForm.attributes.applicationFormSettings
	const settingsOverride = applicationForm.attributes.settingsOverride
	const links = new Map([
		[
			t("legalAgreement.consentToElectronicDisclosures"),
			settingsOverride?.electronicDisclosuresUrl ?? applicationFormSettings.applicationFormElectronicDisclosuresUrl,
		],
		[
			t("legalAgreement.depositTermsConditions"),
			settingsOverride?.depositTermsUrl ?? applicationFormSettings.applicationFormDepositTermsUrl,
		],
		[
			t("legalAgreement.clientTermsOfService"),
			settingsOverride?.clientTermsUrl ?? applicationFormSettings.applicationFormClientTermsUrl,
		],
		[
			t("legalAgreement.cardholderTermsConditions"),
			settingsOverride?.cardholderTermsUrl ?? applicationFormSettings.applicationFormCardholderTermsUrl,
		],
		[
			t("legalAgreement.cashAdvanceTermsConditions"),
			settingsOverride?.cashAdvancedTermsUrl ?? applicationFormSettings.applicationFormCashAdvancedTermsUrl,
		],
		[
			t("legalAgreement.debitCardDisclosure"),
			settingsOverride?.debitCardDisclosureUrl ?? applicationFormSettings.applicationFormDebitCardDisclosureUrl,
		],
	])

	const additionalDisclosures = applicationFormSettings.applicationFormAdditionalDisclosures
	if (additionalDisclosures) {
		additionalDisclosures.forEach((item) => {
			links.set(item.title, item.url)
		})
	}

	const settingsOverrideAdditionalDisclosures = settingsOverride?.additionalDisclosures
	if (settingsOverrideAdditionalDisclosures) {
		settingsOverrideAdditionalDisclosures.forEach((item) => {
			links.set(item.title, item.url)
		})
	}

	const existingAgreementLinks = filterNotUrlValuesFromMap(links)

	useEffect(() => {
		if (existingAgreementLinks.size === 0) {
			setFieldValue("agreement", true)
		}
	}, [])

	const checkboxText = (
		<>
			{prefix}
			{`${t("legalAgreement.agreement")} `}
			{applicationForm.attributes.applicationFormSettings.applicationFormBrandName ??
				applicationForm.attributes.orgName}{" "}
			{Array.from(existingAgreementLinks.keys()).map((key, index) => {
				const link = existingAgreementLinks.get(key)
				return (
					<span key={key}>
						{existingAgreementLinks.size > 1 && index === existingAgreementLinks.size - 1 ? " and " : ""}
						<a className="link" href={link}>
							{key}
						</a>
						{existingAgreementLinks.size > 1 && index < existingAgreementLinks.size - 2 ? ", " : ""}

						{existingAgreementLinks.size === index + 1 && "."}
					</span>
				)
			})}
		</>
	)

	return existingAgreementLinks.size > 0 ? (
		<div className="agreement-checkbox">
			<Checkbox name="agreement" checked={agreement} onChange={onChange} onBlur={onBlur} text={checkboxText} />
		</div>
	) : null
}
