import React, {Dispatch, InputHTMLAttributes, PropsWithChildren, ReactElement} from "react"
import {ErrorMessage, useFormikContext} from "formik"
import classNames from "classnames"
import {flattenObject} from "../utilities/object"
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string
	text: string | ReactElement
	checked: boolean
	onChange: Dispatch<any>
	showInfoTooltip?: boolean
	infoTooltipText?: string
}

export default function Checkbox({
	name,
	text,
	checked,
	onChange,
	showInfoTooltip = false,
	infoTooltipText,
	children,
	...inputProps
}: PropsWithChildren<Props>) {
	const {touched, errors} = useFormikContext()
	const className = classNames(
		"checkbox",
		flattenObject(touched).hasOwnProperty(name) && flattenObject(errors).hasOwnProperty(name) && "error"
	)

	return (
		<div className={className} data-name={name}>
			<label>
				<input name={name} type="checkbox" checked={checked} onChange={onChange} {...inputProps}>
					{children}
				</input>
				<span className="text">
					{text} {showInfoTooltip ? <FontAwesomeIcon data-tip={infoTooltipText} icon={faQuestionCircle} /> : null}
				</span>
			</label>
			<ErrorMessage name={name} component="div" className="form-error" />
		</div>
	)
}
