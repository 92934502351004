import React from "react"
import {ApplicationForm} from "../../resources/applicationForm"
import {Trans, useTranslation} from "react-i18next"

interface Props {
	applicationForm: ApplicationForm
}

export default function VisaNotice({applicationForm}: Props) {
	const clientName =
		applicationForm.attributes.applicationFormSettings.applicationFormBrandName ?? applicationForm.attributes.orgName
	const {t} = useTranslation()

	return (
		<p className="bank-notice">
			<Trans i18nKey="legalAgreement.visaNotice">
				{t("legalAgreement.visaNotice")} {{clientName}}
			</Trans>
		</p>
	)
}
