import React, {PropsWithChildren} from "react"
import {ApplicationForm, ApplicationFormType} from "../../resources/applicationForm"
import {Trans, useTranslation} from "react-i18next"
import ApplicationTypeButton from "./ApplicationTypeButton"
import Button from "../../components/Button"

interface Props {
	applicationForm: ApplicationForm
	updateApplicationForm: Function
	isRequestInProgress: boolean
	selectedFormType?: ApplicationFormType
	setSelectedFormType: Function
}

export default function FormTypeSelection({
	applicationForm,
	updateApplicationForm,
	selectedFormType,
	setSelectedFormType,
	isRequestInProgress,
	children,
}: PropsWithChildren<Props>) {
	const {t} = useTranslation()

	const updateApplicationFormWithSelectedFormType = () => {
		updateApplicationForm("applicationFormChooseBusinessOrIndividual", {applicationFormType: selectedFormType})
	}

	return (
		<div className="form-type-selection">
			<h1>{t("applicationType.introHeadline")}</h1>
			<p>{t("applicationType.intro")}</p>
			<br />
			<h1>{t("applicationType.pageTitle")}</h1>
			<p>
				{t("applicationType.pageDescription")}{" "}
				{applicationForm.attributes.applicationFormSettings?.applicationFormContactUsEmail ? (
					<>
						<Trans
							i18nKey="applicationType.contactUsLink"
							components={{
								url: (
									<a
										className={"no-before-unload-warning"}
										href={`mailto:${applicationForm.attributes.applicationFormSettings.applicationFormContactUsEmail}`}
									/>
								),
							}}
						/>
					</>
				) : null}
			</p>
			<div className="form-type-buttons">
				<ApplicationTypeButton
					applicationForm={applicationForm}
					applicationFormType={ApplicationFormType.Individual}
					selectedFormType={selectedFormType}
					setSelectedFormType={setSelectedFormType}
				/>
				<ApplicationTypeButton
					applicationForm={applicationForm}
					applicationFormType={ApplicationFormType.SoleProprietorship}
					selectedFormType={selectedFormType}
					setSelectedFormType={setSelectedFormType}
				/>
				<ApplicationTypeButton
					applicationForm={applicationForm}
					applicationFormType={ApplicationFormType.Business}
					selectedFormType={selectedFormType}
					setSelectedFormType={setSelectedFormType}
				/>
			</div>
			<div className="submit-wrapper">
				<Button isLoading={isRequestInProgress} type="submit" onClick={updateApplicationFormWithSelectedFormType}>
					{t("submit")}
				</Button>
			</div>
			{children}
		</div>
	)
}
